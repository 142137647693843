export default function ServiceBtnList(props) {
    return (
        <li className={'ServiceBtnList list-btn-box'}>
            <input
                type="radio"
                name={props.name}
                className="list-btn-input"
                id={props.id}
                value={props.value}
            ></input>
            {props.box !== 'withAppknot' ? (
                <label className="list-btn-label" htmlFor={props.id}>
                    {props.text}
                    <strong>&nbsp;{props.serviceType}</strong>
                    <img src={props.src}></img>
                    {props.text02}
                </label>
            ) : (
                <label className="list-btn-label" htmlFor={props.id}>
                    {props.text}
                    <img src={props.src}></img>,
                    <strong>&nbsp; {props.text02}</strong>
                </label>
            )}
        </li>
    );
}
