import { workDetailPath } from '../../pages/WorkDetail';
import Fadeup from '../Fadeup';

export default function Toyota() {
    const wdp = workDetailPath();
    const wp = wdp + 'toyota/';

    return (
        <div className="work-detail-wrap toyota">
            <div className="project-cover dark">
                <div className={`cover-img-wrap`}>
                    <img src={wp + 'img_intro.png'} />
                </div>

                <div className="cover-text-wrap">
                    <div className="container">
                        <h2 className="project-type">TOYOTA Korea</h2>
                        <h4 className="project-name ko">
                            Showroom
                            <br />
                            &Workshop
                        </h4>
                        <ul className="project-team">
                            <li className="team-tag">design</li>
                            <li className="team-tag">Project Management</li>
                            <li className="team-tag">development</li>
                        </ul>
                        <Fadeup className="project-desc">
                            <p>
                                토요타와 렉서스에서 차량 판매나 수리를 하는 현장 직원들을 위한
                                애플리케이션 서비스입니다. 자동차 판매에 있어 고객 정보 수집과
                                계약에 도움을 주는 스마트 쇼룸과 서비스 센터 운영에 편리함을
                                제공하는 스마트 워크샵 그리고 차량 수리 시 정비사를 위한 스마트 테크
                                앱 이렇게 세 가지로 구성되어 있습니다.
                                <br />
                                <br />
                                움직임이 많은 사용자들의 업무 특성상, 기존의 불편한 데스크톱 PC
                                환경에서 벗어나 태블릿이나 스마트폰에서 업무를 처리할 수 있도록
                                전환하였습니다. 모바일 디바이스로 전환되는 과정에서 실사용자들이
                                빠르게 적용할 수 있도록 기존 업무 프로세스를 자연스러운 UI/UX로 녹여
                                편리함을 제공하였습니다.
                                <br />
                                <br />
                                초기 오픈 후 후속으로 진행된 고도화 프로젝트에서는 차량 시승부터
                                결제, 정비/점검, 중고차 판매, 재구매까지 Customer Journey의 모든
                                구간에서 적극적으로 서비스가 활용될 수 있도록 기능을 강화하였습니다.
                                모바일 기기를 활용한 텍스트, 이미지, 동영상 등의 디지털 데이터
                                수집으로 고객 정보 공유 및 전달이 간단해졌고 원활한 커뮤니케이션이
                                가능해졌습니다.
                                <br />
                                <br />
                                또한 고객과 대면이 어려운 환경 속에서도 비대면으로 고객의 정보를
                                수집하고 비대면 계약과 결제 그리고 차량 정기점검 및 수리 등 차량에
                                관한 라이프 사이클을 서비스에 담았습니다. 이처럼 실무자들의 모든
                                업무 과정이 유기적인 상호 관계를 이루어 보다 나은 서비스를 제공할 수
                                있도록 구성했습니다.
                            </p>
                        </Fadeup>
                    </div>
                </div>
            </div>

            <div className="project-contents">
                <section className="section-01">
                    <div className="txt">
                        TOYOTA ∙ LEXUS <br />
                        Showroom
                    </div>
                    <Fadeup className="vid-01">
                        <video src={wp + 'vid_01_01.mp4'} playsInline muted autoPlay loop></video>
                    </Fadeup>
                    <Fadeup className="img-01">
                        <img src={wp + 'img_01_01.png'} />
                    </Fadeup>

                    <Fadeup className="img-02">
                        <img src={wp + 'img_01_02.png'} />
                    </Fadeup>

                    <Fadeup className="img-03">
                        <img src={wp + 'img_01_03.png'} />
                    </Fadeup>

                    <Fadeup className="img-04">
                        <img src={wp + 'img_01_04.png'} />
                    </Fadeup>

                    <Fadeup className="img-05">
                        <img src={wp + 'img_01_05.png'} />
                    </Fadeup>

                    <Fadeup className="img-06">
                        <img src={wp + 'img_01_06.png'} />
                    </Fadeup>

                    <Fadeup className="img-07">
                        <img src={wp + 'img_01_07.png'} />
                    </Fadeup>
                </section>

                <section className="section-02">
                    <Fadeup className="txt">
                        TOYOTA ∙ LEXUS <br />
                        Workshop
                    </Fadeup>

                    <Fadeup className="vid-01">
                        <video src={wp + 'vid_02_01.mp4'} playsInline muted autoPlay loop></video>
                    </Fadeup>


                </section>

                <section className="section-03">
                    <Fadeup className="marquee-container">
                        <div className="marquee-wrap">
                            <ul>
                                <li>
                                    <img src={wp + 'img_03_slide_01.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_03_slide_02.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_03_slide_03.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_03_slide_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_03_slide_05.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_03_slide_06.png'} />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={wp + 'img_03_slide_01.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_03_slide_02.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_03_slide_03.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_03_slide_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_03_slide_05.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_03_slide_06.png'} />
                                </li>
                            </ul>
                        </div>
                    </Fadeup>
                </section>

                <section className="section-04">
                    <div className="img-01">
                        <img src={wp + "img_04_01.png"} />
                    </div>
                    <Fadeup className="img-02">
                        <img src={wp + "img_04_02.png"} />
                    </Fadeup>

                    <div className="img-wrap">
                        <Fadeup>
                            <img src={wp + "img_04_03.png"} />
                        </Fadeup>
                        <Fadeup>
                            <img src={wp + "img_04_04.png"} />
                        </Fadeup>
                        <Fadeup>
                            <img src={wp + "img_04_05.png"} />
                        </Fadeup>
                        <Fadeup>
                            <img src={wp + "img_04_06.png"} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-05">
                    <Fadeup className="img-01">
                        <img src={wp + "img_05_01.png"} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + "img_05_02.png"} />
                    </Fadeup>
                </section>
            </div>
        </div>
    );
}
