import LinkWithTransition from './LinkWithTransition';

export default function NavList(props) {
    return (
        <>
            <li>
                <LinkWithTransition to={'/solution'}>
                    SOLUTION
                </LinkWithTransition>
            </li>
            <li>
                <LinkWithTransition to={'/about'}>
                    ABOUT
                </LinkWithTransition>
            </li>
            <li>
                <LinkWithTransition to={'/work'}>
                    WORK
                </LinkWithTransition>
            </li>
            <li>
                <LinkWithTransition to={'/contact'}>CONTACT</LinkWithTransition>
            </li>
        </>
    );
}
