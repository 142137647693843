import { useContext, useEffect, useState } from 'react';
import { GnbContext, TransitionContext } from './Mobile';
import { motion, AnimatePresence, useMotionValueEvent, useScroll } from 'framer-motion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LinkWithTransition from './LinkWithTransition';

export default function Gnb(props) {

    const [showMenu, setShowMenu] = useState(true);
    const { scrollYProgress } = useScroll();
    const location = useLocation();

    // useMotionValueEvent(scrollYProgress, "change", (latest) => {
    //     if (latest > 0) {
    //         setShowMenu(true);
    //     } else {
    //         setShowMenu(false);
    //     }
    // })

    useEffect(() => {
        // if (scrollYProgress.current == 1) {
        //     setShowMenu(true);
        // }
    },[]);
    return (
        <GnbContext.Consumer>
            {
                (value) => {
                        return (
                            <AnimatePresence>
                                {showMenu && (
                                    <>
                                        <motion.header
                                            className={`Gnb`}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}>
                                            <div
                                                className="btn-menu"
                                                onClick={() => {
                                                    value.actions.setOpenMenu(
                                                        !value.state.openMenu
                                                    );
                                                }}>
                                                MENU
                                            </div>

                                            <AnimatePresence>
                                                {value.state.openMenu && (
                                                    <motion.div
                                                        className={`menu-wrap ${
                                                            value.state.openMenu == true
                                                                ? 'open'
                                                                : ''
                                                        }`}
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                        onClick={(e) => {
                                                            if (
                                                                !document
                                                                    .querySelector('#menu')
                                                                    .contains(e.target)
                                                            ) {
                                                                value.actions.setOpenMenu(false);
                                                            }
                                                        }}>
                                                        <div className="menu" id="menu">
                                                            <button
                                                                type="button"
                                                                className="btn-close"
                                                                onClick={() => {
                                                                    value.actions.setOpenMenu(
                                                                        false
                                                                    );
                                                                }}>
                                                                CLOSE
                                                            </button>

                                                            <address>
                                                                서울시 성동구 아차산로 126,
                                                                <br />
                                                                더리브세종타워 613호 appknot
                                                            </address>

                                                            <div className="tel">
                                                                T.02-702-5527 / F.02-702-5528
                                                            </div>

                                                            {/* logo */}
                                                            <svg
                                                                viewBox="0 0 1742 429"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_616_145)">
                                                                    <path
                                                                        d="M185.437 132.927C176.687 120.201 164.863 109.839 151.04 102.782C137.218 95.7253 121.833 92.1969 106.283 92.5173C44.59 92.5173 -0.18013 142.764 -0.18013 211.886C-0.18013 281.008 44.5899 331.254 106.731 331.254C122.128 331.595 137.37 328.136 151.079 321.19C164.789 314.243 176.535 304.028 185.257 291.465V326.38H234.773V97.214H185.257L185.437 132.927ZM116.849 139.219C156.783 139.219 185.795 169.704 185.795 211.62C185.795 253.536 156.783 284.021 116.849 284.021C76.9138 284.021 49.9626 254.245 49.9626 211.62C49.9626 168.995 77.4511 139.219 116.849 139.219Z"
                                                                        fill="white"
                                                                    />
                                                                    <path
                                                                        d="M417.883 92.5173C402.339 92.2558 386.974 95.8389 373.184 102.942C359.393 110.045 347.611 120.443 338.909 133.193V97.7457H289.393V429.266H339.088V291.11C347.823 303.667 359.57 313.882 373.274 320.842C386.979 327.801 402.214 331.288 417.615 330.988C479.576 330.988 524.525 280.831 524.525 211.62C524.525 142.409 479.576 92.5173 417.883 92.5173ZM474.383 211.886C474.383 254.511 446.894 284.287 407.497 284.287C368.099 284.287 338.461 253.802 338.461 211.886C338.461 169.969 367.472 139.485 407.497 139.485C447.521 139.485 474.383 168.995 474.383 211.62V211.886Z"
                                                                        fill="white"
                                                                    />
                                                                    <path
                                                                        d="M690.622 92.5171C675.09 92.2544 659.737 95.8378 645.961 102.942C632.184 110.045 620.42 120.444 611.737 133.193V97.7456H562.132V429.266H611.737V291.11C620.467 303.672 632.212 313.892 645.918 320.852C659.624 327.813 674.862 331.296 690.263 330.988C752.225 330.988 797.174 280.831 797.174 211.62C797.174 142.409 752.315 92.5171 690.622 92.5171ZM747.211 211.886C747.211 254.511 719.633 284.287 680.235 284.287C640.837 284.287 611.29 253.802 611.29 211.886C611.29 169.969 640.3 139.485 680.235 139.485C720.17 139.485 747.211 168.995 747.211 211.62V211.886Z"
                                                                        fill="white"
                                                                    />
                                                                    <path
                                                                        d="M1045.74 97.214H981.806L884.655 193.365V0H835.05V326.114H884.655V223.318L990.491 326.114H1054.87L932.559 205.948L1045.74 97.214Z"
                                                                        fill="white"
                                                                    />
                                                                    <path
                                                                        d="M1206.37 92.5172C1191.46 91.9908 1176.67 95.3379 1163.48 102.225C1150.28 109.113 1139.14 119.301 1131.16 131.775V97.4798H1081.64V326.38H1131.16V207.721C1131.16 163.412 1152.74 139.396 1191.87 139.396C1224.55 139.396 1240.4 156.499 1240.4 191.681V326.292H1290V182.021C1290 125.838 1258.75 92.5172 1206.37 92.5172Z"
                                                                        fill="white"
                                                                    />
                                                                    <path
                                                                        d="M1447.59 92.5173C1415.6 92.5173 1384.93 105.094 1362.31 127.479C1339.69 149.865 1326.98 180.227 1326.98 211.886C1326.98 243.544 1339.69 273.906 1362.31 296.292C1384.93 318.678 1415.6 331.254 1447.59 331.254C1479.58 331.254 1510.26 318.678 1532.88 296.292C1555.5 273.906 1568.2 243.544 1568.2 211.886C1568.2 180.227 1555.5 149.865 1532.88 127.479C1510.26 105.094 1479.58 92.5173 1447.59 92.5173ZM1517.34 211.886C1517.34 253.802 1487.97 284.287 1447.59 284.287C1407.21 284.287 1377.75 253.802 1377.75 211.886C1377.42 202.606 1378.97 193.355 1382.33 184.684C1385.68 176.013 1390.77 168.1 1397.29 161.417C1403.8 154.735 1411.61 149.42 1420.24 145.79C1428.88 142.16 1438.17 140.289 1447.55 140.289C1456.93 140.289 1466.22 142.16 1474.85 145.79C1483.49 149.42 1491.29 154.735 1497.81 161.417C1504.32 168.1 1509.41 176.013 1512.77 184.684C1516.12 193.355 1517.68 202.606 1517.34 211.886Z"
                                                                        fill="white"
                                                                    />
                                                                    <path
                                                                        d="M1742 143.384V97.214H1672.34V-0.265869H1622.82V97.214H1578.05V143.384H1622.82V237.94C1622.82 296.25 1654.52 330.988 1707.71 330.988C1718.94 330.909 1730.13 329.481 1741.02 326.735H1742V281.362L1740.39 281.805C1732.43 284.05 1724.22 285.271 1715.94 285.439C1687.47 285.439 1672.34 268.247 1672.34 235.635V143.384H1742Z"
                                                                        fill="white"
                                                                    />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_616_145">
                                                                        <rect
                                                                            width="1742"
                                                                            height="429"
                                                                            fill="white"
                                                                        />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>

                                                            {/* nav */}
                                                            <nav>
                                                                <ul>
                                                                    <li>
                                                                        <LinkWithTransition to="/solution">
                                                                            solution
                                                                        </LinkWithTransition>
                                                                    </li>
                                                                    <li>
                                                                        <LinkWithTransition to="/about">
                                                                            ABOUT
                                                                        </LinkWithTransition>
                                                                    </li>
                                                                    <li>
                                                                        <LinkWithTransition to="/work">
                                                                            WORK
                                                                        </LinkWithTransition>
                                                                    </li>
                                                                    <li>
                                                                        <LinkWithTransition to="/contact">
                                                                            CONTACT
                                                                        </LinkWithTransition>
                                                                    </li>
                                                                </ul>
                                                            </nav>

                                                            <div className="copy-right">
                                                                Copyright © 2023 appknot
                                                            </div>
                                                        </div>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </motion.header>
                                        {location.pathname != '/' && (
                                            <div className="logo">
                                                <LinkWithTransition to="/">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="130"
                                                        height="32"
                                                        viewBox="0 0 130 32"
                                                        fill="none">
                                                        <g clipPath="url(#clip0_2989_1417)">
                                                            <path
                                                                d="M13.8383 9.91531C13.1853 8.96603 12.303 8.19312 11.2714 7.66673C10.2399 7.14035 9.09179 6.87716 7.93134 6.90106C3.32738 6.90106 -0.0136719 10.649 -0.0136719 15.805C-0.0136719 20.961 3.32737 24.709 7.96474 24.709C9.11383 24.7344 10.2513 24.4764 11.2743 23.9582C12.2974 23.4401 13.174 22.6781 13.8249 21.741V24.3454H17.5201V7.2514H13.8249L13.8383 9.91531ZM8.71982 10.3846C11.7 10.3846 13.865 12.6585 13.865 15.7852C13.865 18.9118 11.7 21.1857 8.71982 21.1857C5.73961 21.1857 3.72832 18.9647 3.72832 15.7852C3.72832 12.6057 5.7797 10.3846 8.71982 10.3846Z"
                                                                fill="white"
                                                            />
                                                            <path
                                                                d="M31.1855 6.90105C30.0254 6.88155 28.8788 7.14883 27.8497 7.67865C26.8205 8.20848 25.9413 8.98409 25.2919 9.93514V7.29105H21.5967V32.0198H25.3052V21.7145C25.9571 22.6511 26.8337 23.4131 27.8564 23.9322C28.8792 24.4514 30.0161 24.7114 31.1654 24.6891C35.7894 24.6891 39.1438 20.9477 39.1438 15.7852C39.1438 10.6226 35.7894 6.90105 31.1855 6.90105ZM35.4019 15.805C35.4019 18.9845 33.3505 21.2055 30.4104 21.2055C27.4702 21.2055 25.2585 18.9316 25.2585 15.805C25.2585 12.6784 27.4235 10.4045 30.4104 10.4045C33.3972 10.4045 35.4019 12.6057 35.4019 15.7852V15.805Z"
                                                                fill="white"
                                                            />
                                                            <path
                                                                d="M51.539 6.90105C50.3799 6.88145 49.2342 7.14874 48.2061 7.67863C47.178 8.20851 46.3001 8.98418 45.6521 9.93513V7.29105H41.9502V32.0198H45.6521V21.7145C46.3036 22.6515 47.1801 23.4139 48.2029 23.9331C49.2257 24.4522 50.3629 24.712 51.5123 24.6891C56.1363 24.6891 59.4907 20.9477 59.4907 15.7852C59.4907 10.6226 56.143 6.90105 51.539 6.90105ZM55.7621 15.805C55.7621 18.9845 53.704 21.2055 50.7639 21.2055C47.8238 21.2055 45.6187 18.9316 45.6187 15.805C45.6187 12.6784 47.7837 10.4045 50.7639 10.4045C53.7441 10.4045 55.7621 12.6057 55.7621 15.7852V15.805Z"
                                                                fill="white"
                                                            />
                                                            <path
                                                                d="M78.0403 7.2514H73.2693L66.0193 14.4235V0H62.3174V24.3256H66.0193V16.6577L73.9175 24.3256H78.7219L69.5942 15.3621L78.0403 7.2514Z"
                                                                fill="white"
                                                            />
                                                            <path
                                                                d="M90.0279 6.90105C88.9152 6.86178 87.8116 7.11145 86.8269 7.62521C85.8421 8.13897 85.0106 8.89889 84.4149 9.82936V7.27122H80.7197V24.3454H84.4149V15.4943C84.4149 12.1892 86.0253 10.3978 88.9454 10.3978C91.3843 10.3978 92.567 11.6736 92.567 14.2979V24.3388H96.2689V13.5774C96.2689 9.38649 93.9369 6.90105 90.0279 6.90105Z"
                                                                fill="white"
                                                            />
                                                            <path
                                                                d="M108.029 6.90106C105.642 6.90106 103.353 7.83914 101.665 9.50896C99.9766 11.1788 99.0283 13.4435 99.0283 15.805C99.0283 18.1665 99.9766 20.4312 101.665 22.101C103.353 23.7709 105.642 24.7089 108.029 24.7089C110.416 24.7089 112.706 23.7709 114.394 22.101C116.082 20.4312 117.03 18.1665 117.03 15.805C117.03 13.4435 116.082 11.1788 114.394 9.50896C112.706 7.83914 110.416 6.90106 108.029 6.90106ZM113.234 15.805C113.234 18.9316 111.043 21.2055 108.029 21.2055C105.015 21.2055 102.817 18.9316 102.817 15.805C102.792 15.1128 102.908 14.4227 103.159 13.7759C103.409 13.1291 103.789 12.5389 104.275 12.0405C104.761 11.542 105.344 11.1456 105.988 10.8748C106.633 10.604 107.326 10.4645 108.026 10.4645C108.726 10.4645 109.419 10.604 110.063 10.8748C110.708 11.1456 111.29 11.542 111.777 12.0405C112.263 12.5389 112.642 13.1291 112.893 13.7759C113.143 14.4227 113.26 15.1128 113.234 15.805Z"
                                                                fill="white"
                                                            />
                                                            <path
                                                                d="M130 10.6953V7.25139H124.801V-0.0198317H121.106V7.25139H117.765V10.6953H121.106V17.7484C121.106 22.0979 123.472 24.6891 127.441 24.6891C128.279 24.6832 129.114 24.5767 129.927 24.3718H130V20.9874L129.88 21.0204C129.286 21.1879 128.673 21.279 128.056 21.2915C125.931 21.2915 124.801 20.0091 124.801 17.5765V10.6953H130Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_2989_1417">
                                                                <rect
                                                                    width="130"
                                                                    height="32"
                                                                    fill="white"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </LinkWithTransition>
                                            </div>
                                        )}
                                    </>
                                )}
                            </AnimatePresence>
                        );
                }
            }
        </GnbContext.Consumer>
    );
}
