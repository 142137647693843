import { useContext, useEffect } from 'react';
import { GnbContext } from '../components/Pc';
import ContactSectionEntry from '../components/Contact/ContactSectionEntry';
import ContactSectionSummary from '../components/Contact/ContactSectionSummary';
import ContactSectionReview from '../components/Contact/ContactSectionReview';
import ContactSectionFinish from '../components/Contact/ContactSectionFinish';
export default function ContactPage() {
    const gnbContext = useContext(GnbContext);
    useEffect(() => {
        gnbContext.actions.setGnbColor('white');
    }, []);
    return (
        <div className="Contactpage">
            <ContactSectionEntry />
            <ContactSectionSummary />
            <ContactSectionReview />
            <ContactSectionFinish />
        </div>
    );
}
