import "../styles/layout.scss";
// import "../styles/workDetail.scss";
import "../styles/workDetail.scss";
import { Route, Routes } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import MainPage from "../pages/MainPage";
import Gnb from "./Gnb";
import ContactPopup from "./ContactPopup";
import BlockPage from "./BlockPage";
import Layout from "./Layout";
import Transition from "./Transition";
import GaTracker from "../../GaTracker";
import Loading from "./Loading";
import AboutPage from "../pages/AboutPage";
import WorkPage from "../pages/WorkPage";
import ScrollToTop from "../../pc/components/ScrollToTop";
import ContactPage from "../pages/ContactPage";
import SolutionPage from "../pages/SolutionPage";
import Privacy from "./Privacy";
import NotFound from "../pages/NotFound";

export const GnbContext = createContext();
export const TransitionContext = createContext();
export const ContactContext = createContext();
export const PrivacyContext = createContext();

export default function Mobile() {
    GaTracker(); // GA

    const [openMenu, setOpenMenu] = useState(false);
    const [transitionComplt, setTransitionComplt] = useState(true);
    const [loading, setLoading] = useState(true);
    const [popupOn, setPopupOn] = useState(false);
    const [contactBtnRef, setContactBtnRef] = useState();
    const [privacyOn, setPrivacyOn] = useState(false);
    const [upZindex, setUpZindex] = useState(false);


    const gnbValue = {
        state: {
            openMenu: openMenu
        },
        actions: {
            setOpenMenu
        }
    };
    const transitionValue = {
        state: {
            complete: transitionComplt
        },
        actions: {
            setTransitionComplt
        }
    };

    const contactValue = {
        state: {
            popupState: popupOn,
            contactBtnRef: contactBtnRef
        },
        actions: {
            setPopupOn,
            setContactBtnRef
        }
    };
    const privacyValue = {
        state:{
            privacyState: privacyOn,
            zIndexState : upZindex
        },
        actions:{
            setPrivacyOn,
            setUpZindex
        }
    }

    useEffect(() => {
        if (openMenu) {
            document.querySelector("html").classList.add("lock");
        } else {
            document.querySelector("html").classList.remove("lock");
        }
    },[openMenu]);

    useEffect(() => {
        
        function setScreenSize() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setScreenSize();
        window.addEventListener("resize",setScreenSize)

        return () => {
            window.removeEventListener("resize",setScreenSize);
        }
    },[])


    return (
        <GnbContext.Provider value={gnbValue}>
            <TransitionContext.Provider value={transitionValue}>
                <ContactContext.Provider value={contactValue}>
                    <PrivacyContext.Provider value={privacyValue}>
                <div className="Mobile">
                    <Loading setLoading={setLoading} />
                    <Transition />

                    {!loading && (
                    <>
                        <Gnb />
                        <Routes>
                            <Route path="/" element={<Layout />}>
                                <Route path="/" element={<MainPage />}></Route>
                                <Route path="/about" element={<AboutPage />}></Route>
                                <Route path="/solution" element={<SolutionPage />}></Route>
                                <Route path="/contact" element={<ContactPage />}></Route>
                            </Route>
                            {/* footer 따로 적용 */}
                            <Route path="/work" element={<WorkPage />}></Route>
                                <Route path="*" element={<NotFound/>}></Route>

                            <Route path={'/work/:project'} element={<WorkPage />}></Route>
                            <Route path="/sub" element={<BlockPage />}></Route>
                        </Routes>

                        <ScrollToTop />
                        <ContactPopup />
                        <Privacy/>
                    </>
                    )}
                </div>
                    </PrivacyContext.Provider>
                </ContactContext.Provider>
            </TransitionContext.Provider>
        </GnbContext.Provider>
    );
}