import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const GaTracker = () => {
    const location = useLocation();
    const [init, setInit] = useState(false);

    useEffect(() => {
        // 운영서버에만 적용
        if (process.env.REACT_APP_GA_ID) {
            ReactGA.initialize(process.env.REACT_APP_GA_ID);
            setInit(true);
        }
    }, []);

    // location 변경 감지 시 pageview 이벤트 전송
    useEffect(() => {
        if(init) {
            ReactGA.set({ page: location.pathname });
            ReactGA.send('pageview');
        }
    }, [init, location]);
}

export default GaTracker