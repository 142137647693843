import { motion } from "framer-motion";
import { useRef } from "react";


/**
 * 타이포그래피 
 *
 * @param {string} txt
 * @param {string} className
 * @param {boolean} animate : true일 시 한 글자씩 올라오는 애니메이션, false일 시 한 줄씩 올라오는 애니메이션
 * @param {number} delay : 기본 타이포 애니메이션 transition delay
 */
export default function Typography(props) {
    const typoRef = useRef();
    return (
        <motion.div
            className={(props.className ? props.className : '') + ' Typography'}
            ref={typoRef}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={{
                visible: {
                    transition: {
                        when: 'beforeChildren',
                        staggerChildren: 0.1
                    }
                }
            }}>
            {props.animate ? (
                props.txt.split('').map((value, idx) => {
                    return (
                        <div className="alphabet-wrap" key={idx}>
                            <motion.span
                                variants={{
                                    hidden: {
                                        opacity: 0,
                                        y: '150%'
                                    },
                                    visible: (custom = 0) => {
                                        return {
                                            opacity: 1,
                                            y: '0%',
                                            transition: {
                                                duration: 0.8
                                            }
                                        };
                                    }
                                }}>
                                {value}
                            </motion.span>
                        </div>
                    );
                })
            ) : (
                <motion.div
                    variants={{
                        hidden: {
                            opacity: 0,
                            y: '100%'
                        },
                        visible: {
                            opacity: 1,
                            y: '0%',
                            transition: {
                                duration: 0.8,
                                delay: props.delay * 0.15
                            }
                        }
                    }}>
                    {props.txt}
                </motion.div>
            )}
        </motion.div>
    );
}