import { motion, useInView } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { GnbContext } from '../Pc';
import Typography from '../Typography';

export default function SolutionSectionEntry() {
    const gnbContext = useContext(GnbContext);
    const [transitionEnd, setTransitionEnd] = useState(false);
    const entryRef = useRef();
    const inInView = useInView(entryRef, { amount: 0.3 });
    useEffect(() => {
        if (inInView) {
            gnbContext.actions.setGnbColor('white');
        }
    }, [inInView]);

    return (
        <section className="SolutionSectionEntry" ref={entryRef}>
            <div className={`solution-entry-bg`}>
                <motion.img
                    animate={{
                        opacity: [0, 1]
                    }}
                    transition={{
                        duration: 1,
                        delay: 0.5
                    }}
                    onAnimationStart={() => {
                        setTimeout(() => {
                            setTransitionEnd(true);
                        }, 500);
                    }}
                    src={process.env.PUBLIC_URL + '/image/img_bg_solution_entry.webp'}
                />
            </div>
            {transitionEnd && (
                <>
                    <div className="small-msg solution-entry-text text-01">
                        <div className="flex-column">
                            <Typography
                                className="text-en"
                                txt="We are an amazing solution provider"
                            />
                            <Typography className="text-en" txt="of IT professionals." />
                        </div>
                        <div className="text-ko flex-column">
                            <Typography
                                custom={1}
                                className="right"
                                txt="여러분의 비즈니스가 한걸음 더 앞으로"
                            />
                            <Typography
                                custom={2}
                                txt="나아갈 수 있도록 효과적인 그린라이트를 제공해 드릴게요."
                            />
                            <Typography
                                custom={3}
                                txt="우리들의 솔루션을 통해 어느 때보다 안전하게"
                            />
                            <Typography
                                custom={4}
                                className="right"
                                txt="목적지에 도달할 수 있을 거예요!"
                            />
                        </div>
                    </div>

                    <div className="solution-entry-text text-02">
                        <Typography custom={5} className="we-have" txt="WE HAVE" />
                        <Typography custom={6} className="solutions" txt="SOLUTIONS" />
                    </div>
                </>
            )}
        </section>
    );
}
