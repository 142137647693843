import { useCallback, useEffect, useRef, useState,useContext } from 'react';
import { debounce } from 'lodash';
import axios from 'axios';
import QuestionWrap from '../components/QuestionWrap';
import ServiceBtnList from '../components/ServiceBtnList';
import BtnList from '../components/BtnList';
import TextInput from './TextInput';
import { serviceBtnList, budgetBtnList, openDayList, inputList } from '../../utils/contactData';
import Util from '../../utils/Util';
import {PrivacyContext} from './Pc';

const FILE_SIZE_MAX_LIMIT = 5 * 1024 * 1024; // 5MB
const ALLOWED_EXTENSION = ['pdf', 'zip', 'doxc', 'doc'];

const PopupForm = (props) => {
    const privacyContext = useContext(PrivacyContext);
    const emailRef = useRef();
    const phoneRef = useRef();
    const fileRef = useRef();
    const fileNameRef = useRef();
    const agreementRef = useRef();
    const messagesEndRef = useRef(null);
    const popupWrap = useRef(null);
    const [files, setFiles] = useState(null);
    const [isUpload, setIsUpload] = useState(false);
    const [isOverSize, setIsOverSize] = useState(false);
    const [textLength, setTextLength] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);

    const [openBudget, setOpenBudget] = useState(false);
    const [openDay, setOpenDay] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [box, setBox] = useState(''); //box type
    const [values, setValues] = useState({
        name: '',
        position: '',
        company: '',
        phone: '',
        email: '',
        comment: '',
        agreement: '',
        file: ''
    });

    const [errors, setErrors] = useState({
        name: 'valid',
        position: 'valid',
        company: 'valid',
        email: 'valid',
        agreement:'valid'//추가
    }) 
    const [isComplete, setIsComplete] = useState(false);
    const [result, setResult] = useState('');

    // input 체크
    const handleChange = debounce((e) => {
        let isValid = false;
        let value = e.target.value;

        const textCond = value !== '' && value !== 'undefined' && value.length <= 30;

        switch(e.target.name) {
            case 'name': case 'position': case 'company':
                if(textCond) isValid = true;
                break;
            case 'email':
                if(textCond && Util.validateEmail(e.target.value)) isValid = true;
                break;
            case 'phone':
                const fulllNumber = Util.autoHypen(e.target.value);
                if(Util.validatePhone(fulllNumber)) isValid = true;
                phoneRef.current.value = fulllNumber;
                value = fulllNumber;
                break;
            case 'file':
                if(checkFile(e.target.files)) isValid = true;
                break;
            case 'comment':
                if(value.length <= 100) isValid = true;
                break;
            case 'agreement':
                if(e.target.checked == true) isValid = true;
                break;
            default:
                isValid = false;
        }

        if(isValid) {
            e.target.classList.remove('error');
            value.length > 0 ? e.target.classList.add('on') : e.target.classList.remove('on');
        }else {
            e.target.classList.remove('on');
            e.target.classList.add('error');
        }

        setValues({
            ...values,
            [e.target.name]: value
        });
    }, 500);

    
    let today = new Date();
    let time =
        String(today.getHours()).padStart(2, '0') +
        ':' +
        String(today.getMinutes()).padStart(2, '0');


    const textAreaLength = (e) => {
        setTextLength(e.target.value.length);
    };


    const getRef = (inputName) => {
        if(inputName === 'email') {
            return emailRef;
        }else if(inputName === 'phone') {
            return phoneRef;
        }else {
            return null;
        }
    }

    // 파일 체크
    const checkFile = (files) => {
        const validation = Util.validateFile(files, FILE_SIZE_MAX_LIMIT, ALLOWED_EXTENSION);
        const isValidate = validation[0];
        const msg = validation[1];

        if(isValidate) {
            setFiles(files);
            setIsUpload(true);
            setIsOverSize(false);
            return true;
        }else {
            setFiles(null);
            setIsUpload(false);
            fileRef.current.value = null;
            //용량 초과 문구
            fileNameRef.current.innerHTML = msg;
            setIsOverSize(true);
            return false;
        }
    };

    const submitForm = (e) => {
        e.preventDefault();

        if(loading || isComplete) return false; // 중복제출 방지

        const invalidList = validate();
        setErrors({ ...invalidList });
        for(const key in invalidList) {
            if(invalidList[key] === "invalid") return false;
        }
        
        setLoading(true);

        // const url = `${window.location.origin}/server/sendEmail`;
        const url = `https://appknot.com:5000/sendEmail`;
        const formData = new FormData();
        for(let key in values) {
            formData.append(key, values[key]);
        }
        formData.append('attachment', fileRef.current.files[0]);

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            setLoading(false);
            setIsComplete(true);
            // res.status === 200 ? setResult('success') : setResult('fail');
            if(res.status === 200){
                setResult('success');
                //버튼 비활성화
                setIsDisabled(true);

            }else{
                setResult('fail');
                setIsDisabled(false);
            }

            setTimeout(() => {
                scrollToBottom();
            }, 10)
        }).catch(err => {
            setIsComplete(true);
            setLoading(false);
            setResult('fail');
            console.log(err);
        })
    };

    // input 검증
    const validate = useCallback(() => {
        const errorList = {...errors};

        for(const key in values) {
            let cond1 = key === 'name' || key === 'position' || key === 'company';
            let cond2 = values[key] === '' || values[key] === 'undefined' || values[key].length > 30;
            if(cond1 && cond2) {
                errorList[key] = 'invalid';
            }else {
                errorList[key] = 'valid';
            }
        }

        errorList['phone'] = Util.validatePhone(phoneRef.current.value) ? 'valid' : 'invalid';
        errorList['email'] = Util.validateEmail(emailRef.current.value) ? 'valid' : 'invalid';
        if(fileRef.current.files.length > 0) {
            errorList['file'] = checkFile(fileRef.current.files) ? 'valid' : 'invalid';
        }
        errorList['agreement'] = agreementRef.current.checked ? 'valid' : 'invalid';

        return errorList;
    }, [errors, values])

    const Service = (e) => {
        if (
            e.target.tagName !== 'INPUT' &&
            e.target.tagName !== 'IMG' &&
            e.target.tagName !== 'STRONG'
        )
        return false;
        setBox('1');
    };

    const Budget = (e) => {
        if (e.target.tagName !== 'INPUT') return false;
        setBox('2');
    };

    const OpenDay = (e) => {
        if (e.target.tagName !== 'INPUT') return false;
        setBox('3');
    };

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({
            behavior: 'smooth',
            inline: 'center'
        });
    };

    const scrollMove = () => {
        let top = popupWrap.current.offsetHeight + 200;
        popupWrap.current.scrollTo({ top: top, behavior: 'smooth' });
    };

    let [load, setLoad] = useState(['', '', '']); //box 보여주는 스테이트
    let [loading, setLoading] = useState(false); // 로딩 인터랙션
    let [nowTime, setNowTime] = useState([time, time, time, time]); //현재 시간 배열로 각각 현재 시간 반영
    let [defaultOn, setdefaultOn] = useState(false);

    useEffect(() => {
        let add = setTimeout(() => {
            setdefaultOn(true);
        }, 700);
        return () => {
            clearTimeout(add);
        };
    });

    useEffect(() => {
        if (box !== '' && openInfo == false) {
            setLoading(true);
        }
        let indicator = setTimeout(() => {
            setLoading(false);
        }, 700);
        time =
            String(today.getHours()).padStart(2, '0') +
            ':' +
            String(today.getMinutes()).padStart(2, '0');
        return () => {
            clearTimeout(indicator);
        };
    }, [box]);

    useEffect(() => {
        let a, b, c;

        if (loading == false) {
            if (box == '1') {
                let copy = [...load];
                copy[0] = 'on';
                setOpenBudget(true);
                let timeCopy = [...nowTime];
                timeCopy[1] = time;
                setNowTime(timeCopy);

                a = setTimeout(() => {
                    setLoad(copy);
                    scrollToBottom();
                }, 10);
            }
            if (box == '2') {
                let copy = [...load];
                copy[1] = 'on';
                setOpenDay(true);
                let timeCopy = [...nowTime];
                timeCopy[2] = time;
                setNowTime(timeCopy);
                b = setTimeout(() => {
                    scrollToBottom();
                    setLoad(copy);
                }, 10);
            }
            if (box == '3' && !isComplete) {
                let copy = [...load];
                copy[2] = 'on';
                setOpenInfo(true);
                let timeCopy = [...nowTime];
                timeCopy[3] = time;
                setNowTime(timeCopy);
                c = setTimeout(() => {
                    scrollMove();
                    setLoad(copy);
                }, 10);
            }
            return () => {
                clearTimeout(a);
                clearTimeout(b);
                clearTimeout(c);
            };
        }
    }, [loading, isComplete]);

    return (
        <div className="popup-main" ref={popupWrap}>
            <form
                id="contactForm"
                onSubmit={submitForm}
                onChange={(e) => handleChange(e)}>
                <div className={`default-wrap ${defaultOn == true ? 'on' : ''}`}>
                    <QuestionWrap
                        nowTime={nowTime}
                        idx="0"
                        question="필요하신 서비스를 선택해 주세요."
                    />
                    <div className="service-list-wrap" onClick={Service}>
                        <ul className="list-btn-wrap">
                            {
                                serviceBtnList.map((btn, _idx) => 
                                    <ServiceBtnList key={btn.id}
                                        name="service"
                                        id={btn.id}
                                        value={btn.value}
                                        text={btn.text}
                                        text02={btn.text02}
                                        serviceType={btn.serviceType}
                                        src={btn.src}
                                        box={btn.box ?? ''}
                                    />
                                )
                            }
                        </ul>
                    </div>
                </div>
                {openBudget == false ? null : (
                    <div className={`project-budget ${load[0]}`}>
                        <QuestionWrap
                            nowTime={nowTime}
                            idx="1"
                            question="생각하시는 프로젝트의 예산이 있으신가요?"
                        />
                        <div className="budget-list" onClick={Budget}>
                            <ul className="list-btn-wrap">
                                {
                                    budgetBtnList.map((el, idx) => 
                                        <BtnList key={`budget${idx+1}`} name="budget" id={`budget0${idx+1}`} text={el} value={el}/>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                )}
                {openDay == false ? null : (
                    <div className={`project-open-day ${load[1]}`}>
                        <QuestionWrap
                            nowTime={nowTime}
                            idx="2"
                            question="프로젝트의 예상 오픈 시기가 언제일까요?"
                        />
                        <div className="open-day-list" onClick={OpenDay}>
                            <ul className="list-btn-wrap">
                                {
                                    openDayList.map((el, idx) => 
                                        <BtnList key={`openDay${idx+1}`} name="open" id={`openDay0${idx+1}`} text={el} value={el} />
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                )}
                {openInfo == false ? null : (
                    <>
                        <div className={`info-box ${load[2]}`}>
                            <QuestionWrap
                                nowTime={nowTime}
                                idx="3"
                                question="추가 정보 기입만 남았어요!"
                            />
                            <div className="info-input-wrap">
                                {
                                    inputList.map((input, _idx) => 
                                        <TextInput 
                                            key={input.name}
                                            className={'info-input' + (errors[input.name] === 'invalid' ? ' error' : '')}
                                            name={input.name}
                                            placeholder={input.placeholder}
                                            // ref={()=>{returnRef(input.name)}}
                                            getRef={getRef}
                                        />
                                    )
                                }
                                <div className="input-box file-box">
                                    <input
                                        type="file"
                                        className="file-input"
                                        id="file"
                                        accept=".pdf,.zip,.doxc,.doc"
                                        name="file"
                                        ref={fileRef}
                                    />
                                    <label
                                        htmlFor="file"
                                        className={`file-label ${isUpload ? 'upload' : ''} ${isOverSize ? 'error' : ''}`}>
                                        <span className="file-name" ref={fileNameRef}>
                                            {files == null
                                                ? '참고자료가 있다면 첨부해 주세요.'
                                                : files[0].name}
                                        </span>
                                        <button
                                            type="button"
                                            className={`delete-btn`}
                                            onClick={(e) => {               
                                                setFiles(null);
                                                setIsUpload(false);
                                            }}>
                                            <svg
                                                width="13"
                                                height="14"
                                                viewBox="0 0 13 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <line
                                                    y1="-0.5"
                                                    x2="16.9115"
                                                    y2="-0.5"
                                                    transform="matrix(0.704663 -0.709542 0.704663 0.709542 1.08301 13.5)"
                                                    stroke="#6D6D6D"
                                                />
                                                <line
                                                    y1="-0.5"
                                                    x2="16.9115"
                                                    y2="-0.5"
                                                    transform="matrix(0.704663 0.709542 -0.704663 0.709542 0 1.5)"
                                                    stroke="#6D6D6D"
                                                />
                                            </svg>
                                        </button>
                                    </label>
                                    <span className="info-text">pdf,zip,docx,doc/최대 5MB</span>
                                </div>
                                <div className="input-box">
                                    <textarea
                                        className="textarea"
                                        placeholder="추가로 하실 말씀이 있으시면 적어주세요."
                                        maxLength={100}
                                        onInput={(e) => { textAreaLength(e); }}
                                        name="comment"></textarea>
                                    <div className="info-text">
                                        <span className="textarea-lenght">{textLength}</span>
                                        /100
                                    </div>
                                </div>
                                <div className="input-box check-input-box">
                                    <input
                                        type="checkbox"
                                        id="terms"
                                        name="agreement"
                                        ref={agreementRef}
                                        className={(errors['agreement'] === 'invalid' ? 'error' : '')}
                                    />
                                    <label htmlFor="terms">
                                        <div>
                                            <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect
                                                    x="0.4"
                                                    y="0.4"
                                                    width="17.2"
                                                    height="17.2"
                                                    rx="2.6"
                                                    stroke="#4B4B4B"
                                                    strokeWidth="0.8"
                                                />
                                                <path
                                                    d="M5.38938 8.27254C5.21614 8.0575 4.90138 8.0236 4.68634 8.19683C4.47129 8.37006 4.43739 8.68482 4.61062 8.89987L5.38938 8.27254ZM8.55556 13L8.16618 13.3137L8.63201 13.8919L8.99263 13.2428L8.55556 13ZM4.61062 8.89987L8.16618 13.3137L8.94493 12.6863L5.38938 8.27254L4.61062 8.89987ZM8.99263 13.2428L13.4371 5.24282L12.5629 4.75718L8.11848 12.7572L8.99263 13.2428Z"
                                                    fill="#4B4B4B"
                                                />
                                            </svg>
                                        </div>
                                        <span>
                                        <button type="button" className='policy-btn' onClick={() =>{
                                            privacyContext.actions.setPrivacyOn(true);
                                            privacyContext.actions.setUpZindex(true);
                                        }}>개인정보보호정책</button> 이용 동의
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="submit-box">
                            <button type="submit" className={`submit-btn ${(loading || isComplete) && ' active'}` } disabled={isDisabled}>
                                문의하기
                            </button>
                        </div>
                        <div className='last-box'>
                        {
                            isComplete && (
                                <QuestionWrap
                                    nowTime={nowTime}
                                    idx="3"
                                    question="감사합니다."
                                    box="submit"
                                    result={result}
                                />
                            )
                        }
                        </div>
                    </>
                )}
            </form>
            <div className={`loading-animation ${loading == true ? 'load' : ''}`}>
                <div className="indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className="messagesEnd" ref={messagesEndRef}></div>
        </div>
    );
};

export default PopupForm;
