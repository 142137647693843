import { motion } from 'framer-motion';
import { useState } from 'react';
export default function Fadeup({className, children, endClass = false, delay = 0, ...props}) {
    const variants = {
        onscreen: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 0.8,
                delay: 0.05 * (delay * 0.5),
                ease: 'linear'
            }
        },
        offscreen: { opacity: 0, y: '50%' }
    };
    
    const [end, setEnd] = useState("");
    const classNames = "Fadeup" + (className ? ` ${className}` : "") + (endClass ? ` ${end}` : "");

    return (
    <motion.div className={classNames}
    initial="offscreen"
    whileInView="onscreen"
    viewport={{once: true}}>
        <motion.div variants={variants}
        onAnimationComplete={() => {
            setEnd('end');
        }}
        >
            {children}
        </motion.div>
    </motion.div>
    )
}