import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { GnbContext } from '../Pc';
import { useEffect, useContext, useRef } from 'react';
import { motion, useInView } from 'framer-motion';

export default function Lexus() {
    const gnbContext = useContext(GnbContext);
    const variants = {
        visible: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 1.2,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, y: '50%' }
    };
    useEffect(() => {
        gnbContext.actions.setGnbColor('white');
    }, []);
    return (
        <div className="Lexus">
            <div className="work-detail-wrap work-detail-container" data-hash="lexus">
                <div className="project-cover">
                    <div className="cover-img-wrap"></div>
                    <div className="cover-text-wrap">
                        <div className="container">
                            <h2 className="project-type">LEXUS Korea</h2>
                            <h4 className="project-name">LEXUS LOUNGE</h4>
                            <ul className="project-team">
                                <li className="team-tag">design</li>
                                <li className="team-tag">Project Management</li>
                                <li className="team-tag">development</li>
                            </ul>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="project-desc">
                                <motion.p variants={variants}>
                                    렉서스 라운지는 고객 선호도 및 차량 관리 주기에 기반하여
                                    사용자에게 차별화된 차량 관리 경험과 프라이빗한 멤버십 혜택을
                                    제공하는 멤버십 서비스입니다.
                                    <br />
                                    <br />
                                    렉서스 라운지의 '스마트 차량 관리'는 차량 정비내역, 전자 문서
                                    등의 차량 관리 히스토리를 손쉽게 확인할 수 있으며, 전화로만
                                    제공하던 서비스 센터 예약을 앱에서 직접 진행할 수 있도록
                                    제공했고 보다 쉽게 서비스를 받을 수 있도록 하였습니다. 또한,
                                    중장년층이 선호하는 브랜드인 만큼 법인차량, 가족 차량으로
                                    사용하는 경우가 많습니다. 이런 특징을 파악하여 더욱 활발한
                                    서비스를 위해 차량의 실소유자 외에도 차량을 사용하는 '공동
                                    사용자'를 등록하여 멤버십 혜택을 함께 누리고 공동으로 차량
                                    관리를 할 수 있도록 구성하였습니다.
                                    <br />
                                    <br />
                                    이처럼 고객 맞춤형 편의 시스템을 통해 고객의 차량 생애 주기를
                                    파악하고 정기 점검 등의 차량 관리를 도와줄 푸시 메시지를
                                    전송하거나, 관심사를 파악하여 개인화 콘텐츠 등을 제공합니다.
                                    장기적으로 타깃 마케팅을 통해 고객에게 렉서스 브랜드에 대한
                                    신뢰도를 상승시킬 수 있을 것입니다.
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>
                <div className="work-detail-contents">
                    <section className="work-detail-information">
                        <div className="work-detail-information-container work-detail-information-container-01">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-video work-detail-information-video-01 reactive-rectangle fade-up">
                                <motion.iframe
                                    variants={variants}
                                    src="https://www.youtube.com/embed/gjth-YkVWBE"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></motion.iframe>
                            </motion.div>

                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img relative-rectangle work-detail-information-img-01 fade-up">
                                <motion.ul variants={variants}>
                                    <li>
                                        <video
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/video/aweb_llounge_01.mp4'
                                            }
                                            poster={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section01_00.webp'
                                            }
                                            autoPlay
                                            muted
                                            playsInline></video>

                                        {/* <img src={process.env.PUBLIC_URL +'/site/res/image/img_lexus_section01_00.webp'} alt="렉서스 이미지" />  */}
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section01_01.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </li>
                                </motion.ul>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-02">
                            <div className="work-detail-information-img work-detail-information-img-01 reactive-rectangle">
                                <ul>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section02_01.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="3">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section02_02.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="7">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section02_03.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="6">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section02_04.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </motion.li>
                                </ul>
                            </div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-03">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section03_01.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>

                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.ul variants={variants}>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section03_02.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section03_03.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </li>
                                </motion.ul>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-04">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section04_01.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.div variants={variants}>
                                    <Swiper
                                        loop={true}
                                        autoplay={{ delay: 1, disableOnInteraction: false }}
                                        speed={4000}
                                        modules={[Autoplay]}
                                        slidesPerView={'auto'}
                                        freeMode={true}
                                        centeredSlides={false}>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_lexus_section04_slide_01.webp'
                                                }
                                                alt="렉서스 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_lexus_section04_slide_02.webp'
                                                }
                                                alt="렉서스 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_lexus_section04_slide_03.webp'
                                                }
                                                alt="렉서스 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_lexus_section04_slide_04.webp'
                                                }
                                                alt="렉서스 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_lexus_section04_slide_05.webp'
                                                }
                                                alt="렉서스 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_lexus_section04_slide_06.webp'
                                                }
                                                alt="렉서스 이미지"
                                            />
                                        </SwiperSlide>
                                    </Swiper>
                                </motion.div>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-05">
                            <div className="work-detail-information-bg circle reactive-rectangle"></div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section05_01.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.ul variants={variants}>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section05_02.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section05_03.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section05_04.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </li>
                                </motion.ul>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-06">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section06_01.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up"
                                data-delay="9">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section06_02.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up"
                                data-delay="6">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section06_03.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-04 fade-up"
                                data-delay="8">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section06_04.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                        </div>
                        <div className="work-detail-information-container work-detail-information-container-06 for-mobile">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section06_02.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            <motion.div className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section06_01.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section06_03.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-04 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section06_04.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-07">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section07_01.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </motion.div>

                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up"
                                data-delay="3">
                                <motion.ul variants={variants}>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section07_02.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section07_03.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_lexus_section07_04.webp'
                                            }
                                            alt="렉서스 이미지"
                                        />
                                    </li>
                                </motion.ul>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-08">
                            <div className="work-detail-information-bg-01 reactive-rectangle"></div>
                            <div className="work-detail-information-bg-02 reactive-rectangle"></div>
                            <div className="work-detail-information-bg-03 reactive-rectangle"></div>
                            <div className="work-detail-information-img work-detail-information-img-01">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section08_02.webp'
                                    }
                                    alt="렉서스 이미지"
                                />
                            </div>
                            <div className="work-detail-information-img work-detail-information-img-02">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_lexus_section08_03.webp'
                                    }
                                    alt="차량 관리부터 다양한 혜택까지 LEXUS LOUNGE에서"
                                />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
