import React, { useEffect, useContext, useRef } from 'react';
import { GnbContext } from '../Pc';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { motion, useInView } from 'framer-motion';

export default function Daldabang() {
    const aniRef = useRef();
    const gnbContext = useContext(GnbContext);
    const isInView = useInView(aniRef, { once: true, amount: 0.3 });

    const variants = {
        visible: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 1.2,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, y: '50%' }
    };
    useEffect(() => {
        gnbContext.actions.setGnbColor('white');
    }, []);
    return (
        <div className="Daldabang">
            <div className="work-detail-wrap" data-hash="ddb">
                <div className="project-cover">
                    <div className="cover-img-wrap"></div>

                    <div className="cover-text-wrap">
                        <div className="container">
                            <h2 className="project-type">Yuhan Kimberly</h2>
                            <h4 className="project-name">Daldabang</h4>
                            <ul className="project-team">
                                <li className="team-tag">design</li>
                                <li className="team-tag">Project Management</li>
                                <li className="team-tag">development</li>
                            </ul>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="project-desc">
                                <motion.p variants={variants}>
                                    달다방은 생리 주기 관리를 위한 캘린더와 여성 생리 건강 정보를
                                    제공해 주는 애플리케이션입니다.
                                    <br />
                                    <br />
                                    기본적으로 생리 주기 관리 서비스이기 때문에 예상 가임기, 예상
                                    생리 주기 등 정확한 예측 정보를 고객에게 전달하는 것을 핵심으로
                                    서비스를 기획했습니다.
                                    <br />
                                    <br />
                                    정확한 예측 정보를 위해서는 주기 계산법과 데이터 확보가 핵심
                                    요소입니다. ‘대한 산부인과 의사회’에서 적용한 계산법을 이용한
                                    평균값을 도출하여 주기를 예상하도록 계산 로직을 기획했고, 피임약
                                    복용 등에 따른 변수도 로직에 적용했습니다. 또한 사용자가 꾸준히
                                    기록할 수 있도록 예상 생리 주기에 푸시 알림을 설정할 수도 있고,
                                    어떤 화면에서도 빠르게 입력하는 화면으로 이동할 수 있는
                                    셰이크(Shake) 기능을 제공합니다.
                                    <br />
                                    <br />이 외에도 다양한 여성 건강 소식과 여성 맞춤 트렌드 및
                                    이벤트 정보를 제공함으로써 앱의 사용률과 재방문율을 증가시키고자
                                    했습니다.
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>

                <div className="project-contents">
                    <section className="cont-sec-01">
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-01 fade-up">
                            <motion.div
                                variants={variants}
                                className="animation-container reactive"></motion.div>
                        </motion.div>

                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-02 floating fade-up">
                            <motion.img
                                variants={variants}
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_ddb_section_01_02.webp'
                                }
                            />
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-03 floating fade-up">
                            <motion.img
                                variants={variants}
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_ddb_section_01_03.webp'
                                }
                            />
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-04 floating fade-up">
                            <motion.img
                                variants={variants}
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_ddb_section_01_04.webp'
                                }
                            />
                        </motion.div>
                    </section>

                    <section className="cont-sec-02">
                        <div className="cont-wrap-01 d-flex">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-01 fade-up">
                                <motion.div variants={variants} className="animation-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_02_01.webp'
                                        }
                                    />
                                </motion.div>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-02 fade-up">
                                <motion.div variants={variants} className="animation-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_02_02.webp'
                                        }
                                    />
                                </motion.div>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-03 fade-up">
                                <motion.div variants={variants} className="animation-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_02_03.webp'
                                        }
                                    />
                                </motion.div>
                            </motion.div>
                        </div>

                        <div className="cont-wrap-02">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-04 fade-up"
                                data-delay="10">
                                <motion.img
                                    variants={{
                                        visible: {
                                            opacity: 1,
                                            y: '0%',
                                            transition: {
                                                opacity: {
                                                    duration: 1.8
                                                },
                                                y: {
                                                    duration: 1.2
                                                },
                                                duration: 1.2,
                                                delay: 1,
                                                ease: 'easeInOut'
                                            }
                                        },
                                        hidden: { opacity: 0, y: '50%' }
                                    }}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ddb_section_02_04.webp'
                                    }
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-05 fade-up"
                                data-delay="5">
                                <motion.img
                                    variants={{
                                        visible: {
                                            opacity: 1,
                                            y: '0%',
                                            transition: {
                                                opacity: {
                                                    duration: 1.8
                                                },
                                                y: {
                                                    duration: 1.2
                                                },
                                                duration: 1.2,
                                                delay: 0.5,
                                                ease: 'easeInOut'
                                            }
                                        },
                                        hidden: { opacity: 0, y: '50%' }
                                    }}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ddb_section_02_05.webp'
                                    }
                                />
                            </motion.div>
                        </div>
                    </section>

                    <section className="cont-sec-03" ref={aniRef}>
                        <div className={`fade-up ${isInView && 'show'}`}></div>
                        <div className="bg-chart-line reactive">
                            <div className="line">
                                <div className="bg"></div>
                            </div>
                            <div className="point"></div>
                        </div>
                        <div className="bg-chart-bar reactive">
                            <div className="bg"></div>
                        </div>

                        <div className="emoji emoji-01">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_ddb_section_03_bg_emoji_01.webp'
                                }
                            />
                        </div>
                        <div className="emoji emoji-02">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_ddb_section_03_bg_emoji_02.webp'
                                }
                            />
                        </div>
                        <div className="emoji emoji-03">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_ddb_section_03_bg_emoji_03.webp'
                                }
                            />
                        </div>
                        <div className="emoji emoji-04">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_ddb_section_03_bg_emoji_04.webp'
                                }
                            />
                        </div>
                        <div className="emoji emoji-05">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_ddb_section_03_bg_emoji_05.webp'
                                }
                            />
                        </div>
                        <div className="emoji emoji-06">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_ddb_section_03_bg_emoji_06.webp'
                                }
                            />
                        </div>

                        <div className="cont-img-01 fade-up">
                            <div className="animation-container reactive"></div>
                        </div>
                    </section>

                    <section className="cont-sec-04">
                        <div className="cont-wrap-01 d-flex">
                            <div className="cont-img-01">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ddb_section_04_01.webp'
                                    }
                                />
                            </div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-02 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ddb_section_04_02.webp'
                                    }
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-03 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ddb_section_04_03.webp'
                                    }
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-04 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ddb_section_04_04.webp'
                                    }
                                />
                            </motion.div>
                        </div>

                        <div className="cont-wrap-02">
                            <div className="cont-img-03 fade-up">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ddb_section_04_03.webp'
                                    }
                                />
                            </div>
                            <div className="cont-img-04 fade-up">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ddb_section_04_04.webp'
                                    }
                                />
                            </div>
                        </div>
                    </section>

                    <section className="cont-sec-05">
                        <div className="cont-wrap-01">
                            <div className="cont-img-01">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ddb_section_05_01.webp'
                                    }
                                />
                            </div>
                            <div className="cont-img-02">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ddb_section_05_01_frame.webp'
                                    }
                                />
                            </div>
                        </div>
                        <div className="swiper-container">
                            <Swiper
                                className="mySwiper"
                                slidesPerView={'auto'}
                                autoplay={{
                                    delay: 1000,
                                    disableOnInteraction: false
                                }}
                                modules={[Autoplay]}
                                loop={true}
                                centeredSlides={true}
                                speed={2000}
                                freeMode={false}>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_05_slide_01.webp'
                                        }
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_05_slide_02.webp'
                                        }
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_05_slide_03.webp'
                                        }
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_05_slide_04.webp'
                                        }
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_05_slide_05.webp'
                                        }
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_05_slide_06.webp'
                                        }
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_05_slide_07.webp'
                                        }
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_05_slide_08.webp'
                                        }
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </section>

                    <section className="cont-sec-06">
                        <div className="d-flex container">
                            <div className="cont-wrap-01">
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-01 fade-up">
                                    <motion.img
                                        variants={variants}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_06_01.webp'
                                        }
                                    />
                                </motion.div>
                            </div>
                            <div className="cont-wrap-02">
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-02 fade-up">
                                    <motion.img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_06_02.webp'
                                        }
                                    />
                                </motion.div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-03 fade-up">
                                    <motion.img
                                        variants={variants}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_06_03.webp'
                                        }
                                    />
                                </motion.div>
                            </div>
                            <div className="cont-wrap-03">
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-04 fade-up">
                                    <motion.img
                                        variants={variants}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ddb_section_06_04.webp'
                                        }
                                    />
                                </motion.div>
                            </div>
                        </div>
                    </section>

                    <section className="cont-sec-07">
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-01 fade-up">
                            <motion.img
                                variants={variants}
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_ddb_section_07_01.webp'
                                }
                                alt="달다방"
                            />
                        </motion.div>
                    </section>
                </div>
            </div>
        </div>
    );
}
