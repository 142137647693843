import Typography from '../Typography';
import { useInView } from 'framer-motion';
import { useRef } from 'react';
export default function ContactSectionSummary() {
    const textView = useRef();
    const isInViewText = useInView(textView, { once: false }, { amount: 0.8 });
    return (
        <section className={`ContactSectionSummary ${isInViewText && 'white'}`}>
            <div className="contact-summary-container">
                <div className="small-msg text01" ref={textView}>
                    <div className="flex-column">
                        <Typography className="right" txt="We're a" />
                        <Typography delay={1} txt="little bit of a freak," />
                        <Typography delay={2} txt="but our passion" />
                        <Typography delay={3} txt="and ability to work" />
                        <Typography delay={4} txt="leads you to great" />
                        <Typography delay={5} txt="results." />
                    </div>
                </div>
                <div className="small-msg text02">
                    <div className="text-ko flex-column">
                        <Typography
                            className="right"
                            txt="앱노트는 다양한 관점으로 아이디어를 제시합니다."
                        />
                        <Typography delay={1} txt="각각의 구성원은 독립적인 사고와 창의력을 지니고 있으며, 이러한 다양성이" />
                        <Typography delay={2} txt="우리 프로젝트를 독특하고 혁신적으로 만들어냅니다." />
                        <Typography delay={3} txt="어떠한 어려움이든, 우리는 항상 문제를 해결하고 이를 실현하기 위한 방법을" />
                        <Typography delay={4} txt="찾아냅니다." />
                    </div>
                </div>
            </div>
        </section>
    );
}
