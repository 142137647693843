import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import DelayHovering from '../components/DelayHovering';
import Typography from '../components/Typography';
import Tag from '../components/Tag';
import LinkWithTransition from '../components/LinkWithTransition';

function NotFound() {
    const cursorRef = useRef();

    const [pos, setPos] = useState({
        x: 0,
        y: 0
    });
    const [showSymbol, setShowSymbol] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowSymbol(true);
        },500);
    },[]);

    return (
        <div className={showSymbol ? 'NotFound symbol-show' : 'NotFound'}>
            <DelayHovering duration={4} delay={0} className="symbol symbol-01">
                <img src={process.env.PUBLIC_URL + '/image/img_symbol_01.webp'} />
            </DelayHovering>

            <DelayHovering duration={3} delay={0.1} className="symbol symbol-02">
                <img src={process.env.PUBLIC_URL + '/image/img_symbol_02.webp'} />
            </DelayHovering>

            <DelayHovering duration={5} delay={0.2} className="symbol symbol-03">
                <img src={process.env.PUBLIC_URL + '/image/img_symbol_03.webp'} />
            </DelayHovering>

            <DelayHovering duration={4} delay={0.3} className="symbol symbol-04">
                <img src={process.env.PUBLIC_URL + '/image/img_symbol_04.webp'} />
            </DelayHovering>

            {showSymbol && (
                <div
                    className="typo-container">
                    <div className="typo-wrap">
                        <Typography custom={5} txt="OOPS!" rotate="none" />
                    </div>
                    <div className="typo-wrap">
                        <Typography custom={8} txt="PAGE NOT" rotate="none" />
                    </div>
                    <div className="typo-wrap">
                        <Typography custom={11} txt="FOUND" rotate="none" />
                    </div>
                    <div
                        className="btn-back"
                        ref={cursorRef}>
                        <LinkWithTransition to="/">
                            <Tag name="이전 페이지로 돌아갈까요? 🚶" />
                        </LinkWithTransition>
                    </div>
                </div>
            )}

        </div>
    );
}

export default NotFound;