import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';

export default function MainSectionRecruit(props) {
    const bgChangeRef = useRef();

    // state
    const [bgChange, setBgChange] = useState();

    useEffect(() => {
        let observe = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setBgChange(true);
                } else {
                    setBgChange(false);
                }
            },
            { threshold: 0 }
        );
        observe.observe(bgChangeRef.current);

        return () => {
            if (observe) observe.disconnect();
        };
    }, []);

    return (
        <section className={`MainSectionRecruit ${bgChange == true && 'on'}`} ref={bgChangeRef}>
            <div className="recruit-top">
                <div className={`line horizontal ${bgChange == true ? 'on' : ''}`}></div>
                <div className={`line vertical  ${bgChange == true ? 'on' : ''}`}></div>
                <div className="recruit-msg">
                    Embrace
                    <br />
                    Your Inner
                    <div className="row">
                        Geek
                        <img
                            className="emoji-alien"
                            src={process.env.PUBLIC_URL + '/image/icn_alien.webp'}
                        />
                    </div>
                    Where Passion Fuels Greatnes!
                    <br />
                    we want
                    <br />
                    new member.
                    <br />
                    Be my company.
                    <span className="recruit-tag">
                        너 내 동료가 돼라
                        <img
                            className="emoji-point"
                            src={process.env.PUBLIC_URL + '/image/icn_pointing_viewer.webp'}
                        />
                    </span>
                </div>

                <div className="recruit-job">
                    <span>WE WANT</span>
                    <span>
                        Strategy Planner
                        <br />
                        Product Manager
                        <br />
                        ui/ux designer
                        <br />
                        ios developer
                        <br />
                        android developer
                        <br />
                        web developer
                    </span>
                </div>
            </div>
            <div className="slide-wrap">
                <Swiper
                    spaceBetween={25}
                    slidesPerView={1.5}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{ delay: 2000, disableOnInteraction: false }}
                    modules={[Autoplay]}>
                    <SwiperSlide>
                        <div className="slide-content" data-name="strategy">
                            <div className="slide-img">
                                <img
                                    src={
                                        process.env.PUBLIC_URL + '/image/img_recruit_slide_01.webp'
                                    }
                                />
                            </div>
                            <span className="slide-text">Strategy Planning team</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-content" data-name="project">
                            <div className="slide-img">
                                <img
                                    src={
                                        process.env.PUBLIC_URL + '/image/img_recruit_slide_02.webp'
                                    }
                                />
                            </div>
                            <span className="slide-text">Project Management Team</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-content" data-name="design">
                            <div className="slide-img">
                                <img
                                    src={
                                        process.env.PUBLIC_URL + '/image/img_recruit_slide_03.webp'
                                    }
                                />
                            </div>
                            <span className="slide-text">design team</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-content" data-name="ios">
                            <div className="slide-img">
                                <img
                                    src={
                                        process.env.PUBLIC_URL + '/image/img_recruit_slide_04.webp'
                                    }
                                />
                            </div>
                            <span className="slide-text">ios development team</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-content" data-name="android">
                            <div className="slide-img">
                                <img
                                    src={
                                        process.env.PUBLIC_URL + '/image/img_recruit_slide_05.webp'
                                    }
                                />
                            </div>
                            <span className="slide-text">android development team</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-content" data-name="web">
                            <div className="slide-img">
                                <img
                                    src={
                                        process.env.PUBLIC_URL + '/image/img_recruit_slide_06.webp'
                                    }
                                />
                            </div>
                            <span className="slide-text">web development team</span>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    );
}
