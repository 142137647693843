import {
    useInView,
    motion,
    useScroll,
    useMotionValueEvent,
    useTransform,
} from 'framer-motion';
import { useRef, useState, useContext } from 'react';

export default function ContactSectionReview() {
    const [isScroll, setScroll] = useState(false);
    const [isEnd, setEnd] = useState(false);
    const viewRef = useRef();
    const aniRef = useRef();
    const stickRef = useRef();
    const isInView = useInView(viewRef, { margin: '0px 0px -200px 0px' });
    const aniText = 'COME'.split('');
    const { scrollYProgress } = useScroll({
        target: viewRef,
    });

    const textAniTransformVal = useTransform(scrollYProgress, [0, 1], [0, 72]);

    useMotionValueEvent(textAniTransformVal, 'change', (latest) => {
        if (isEnd) {
            setScroll(true);
            aniRef.current.style.transform = `translateX(-${latest}%)`;
        }
    });
    return (
        <section className={`ContactSectionReview ${isInView ? 'black' : ''}`} ref={viewRef}>
            <div className="contact-review-stick" ref={stickRef}>
                <div className="text-container">
                    <motion.div className={`text-slide-box ${isScroll && 'action'}`}>
                        <div className="text-wrap" ref={aniRef}>
                            {aniText.map((el, idx) => {
                                return (
                                    <motion.span
                                        key={idx}
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        variants={{
                                            hidden: { opacity: 0 },
                                            visible: { opacity: 1 }
                                        }}
                                        transition={{
                                            duration: 0.2,
                                            delay: idx * 0.3,
                                            ease: 'easeInOut'
                                        }}
                                        onAnimationComplete={() => {
                                            if (idx == aniText.length - 1) {
                                                setEnd(true);
                                            }
                                        }}>
                                        {el}
                                    </motion.span>
                                );
                            })}
                            <div className="hidden-text">
                                <span>&nbsp;wander with us</span>
                            </div>
                        </div>
                    </motion.div>
                    <div className="small-text-box">
                        <p>앱노트와 함께라면 만족스러운 결과물을 얻을 수 있습니다.</p>
                        <p>우리와 함께하고 싶다면, 언제든지 연락주세요.</p>
                        <p>기쁘게 협력하겠습니다.</p>
                    </div>
                </div>
            </div>
            <ul className="work-thumbnail-wrap">
                <li className="work-thumbnail thumbnail01">
                    <img
                        src={process.env.PUBLIC_URL + '/image/img_contact_review_ahrchiveat.webp'}
                    />
                </li>
                <li className="work-thumbnail thumbnail02">
                    <img src={process.env.PUBLIC_URL + '/image/img_contact_review_lexus.webp'} />
                </li>
                <li className="work-thumbnail thumbnail03">
                    <img src={process.env.PUBLIC_URL + '/image/img_contact_review_ekolon.webp'} />
                </li>
                <li className="work-thumbnail thumbnail04">
                    <img src={process.env.PUBLIC_URL + '/image/img_contact_review_bentley.webp'} />
                </li>
                <li className="work-thumbnail thumbnail05">
                    <img src={process.env.PUBLIC_URL + '/image/img_contact_review_ddb.webp'} />
                </li>
            </ul>
        </section>
    );
}
