//work detail pages
import Archiveat from '../components/WorkDetail/Archiveat';
import Beautyselfpick from '../components/WorkDetail/Beautyselfpick';
import Bentley from '../components/WorkDetail/Bentley';
import Daldabang from '../components/WorkDetail/Daldabang';
import Ekolon from '../components/WorkDetail/Ekolon';
import Kiarental from '../components/WorkDetail/KiaRental';
import Lexus from '../components/WorkDetail/Lexus';
import MetaPassport from '../components/WorkDetail/MetaPassport';
import Picks from '../components/WorkDetail/Picks';
import Styler from '../components/WorkDetail/Styler';
import Toyota from '../components/WorkDetail/Toyota';
import Wego from '../components/WorkDetail/Wego';
import Shinsegae from '../components/WorkDetail/Shinsegae';

export default function WorkDetail({ project }) {
    switch (project.toLowerCase()) {
        case 'archiveat':
            return <Archiveat />;
        case 'beautyselfpick':
            return <Beautyselfpick />;
        case 'bentley':
            return <Bentley />;
        case 'ddb':
            return <Daldabang />;
        case 'ekolon':
            return <Ekolon />;
        case 'kiarental':
            return <Kiarental />;
        case 'lexus':
            return <Lexus />;
        case 'metapassport':
            return <MetaPassport />;
        case 'picks':
            return <Picks />;
        case 'stylerpick':
            return <Styler />;
        case 'toyota':
            return <Toyota />;
        case 'wego':
            return <Wego />;
        case 'shinsegae':
            return <Shinsegae/>
    }
}
