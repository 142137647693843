import { GnbContext } from '../Pc';
import { useEffect, useContext } from 'react';
import { motion } from 'framer-motion';

export default function Bentley() {
    const gnbContext = useContext(GnbContext);

    const variants = {
        visible: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 1.2,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, y: '50%' }
    };
    useEffect(() => {
        gnbContext.actions.setGnbColor('white');
    }, []);
    return (
        <div className="Bentley">
            <div className="work-detail-wrap work-detail-container" data-hash="bentley">
                <div className="project-cover">
                    <div className="cover-img-wrap"></div>
                    <div className="cover-text-wrap">
                        <div className="container">
                            <h2 className="project-type">Bentley Seoul</h2>
                            <h4 className="project-name">Bentley Membership</h4>
                            <ul className="project-team">
                                <li className="team-tag">design</li>
                                <li className="team-tag">Project Management</li>
                                <li className="team-tag">development</li>
                            </ul>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="project-desc">
                                <motion.p variants={variants}>
                                    벤틀리 서울 멤버십은 벤틀리 공식 오너만을 위한 온오프라인 통합
                                    멤버십으로, 기존 오프라인에서 운영되던 시승 및 정비 예약, 긴급
                                    출동 지원 서비스 등 차량 관리 컨시어지 서비스를 모두 앱으로
                                    이용할 수 있습니다.
                                    <br />
                                    <br />
                                    또한, 단순한 혜택 제공 수준의 여타 멤버십 프로그램과는 달리
                                    벤틀리 오너들에게 어울리는 라이프스타일 경험을 제안하고
                                    제공합니다.
                                    <br />
                                    <br />
                                    오너들은 멤버십 가입 시 자동차를 비롯한 패션/쇼핑, 여행, 레저,
                                    미식, 인테리어 등 럭셔리 라이프스타일 전반에 걸쳐 자신이 관심
                                    있거나 항유하는 분야를 선택할 수 있으며, 이에 따라 맞춤형 제휴
                                    프로그램들을 앱을 통해 편리하게 확인할 수 있습니다. 뿐만 아니라,
                                    영업사원도 멤버십 이용 개별 고객의 성향과 활동 데이터를
                                    지속적으로 수집하고 분석해 고객 관리를 더욱 체계적으로 할 수
                                    있도록 지원합니다.
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>
                <div className="work-detail-contents">
                    <section className="work-detail-information">
                        <div className="work-detail-information-container work-detail-information-container-01">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-video work-detail-information-video-01 fade-up">
                                <motion.div
                                    variants={variants}
                                    className="work-detail-information-video-wrap reactive-rectangle">
                                    <video
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/video/aweb_bentley_01.mp4'
                                        }
                                        autoPlay
                                        muted
                                        playsInline
                                        poster={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/aweb_bentley_01.webp'
                                        }></video>

                                    {/* <img src={process.env.PUBLIC_URL + '/site/res/image/aweb_bentley_01.webp'} alt="벤틀리 이미지" /> */}
                                </motion.div>
                            </motion.div>

                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img relative-rectangle work-detail-information-img-01">
                                <motion.ul variants={variants} className="fade-up" data-delay="2">
                                    <li>
                                        <h4>손쉬운 차량관리</h4>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_bentley_section01_01.webp'
                                            }
                                            alt="벤틀리 이미지"
                                        />
                                    </li>
                                    <li>
                                        <h4>비대면 서비스 예약</h4>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_bentley_section01_02.webp'
                                            }
                                            alt="벤틀리 이미지"
                                        />
                                    </li>
                                </motion.ul>
                            </motion.div>
                        </div>

                        <motion.div
                            className="work-detail-information-container work-detail-information-container-02 fade-up"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}>
                            <motion.div
                                variants={variants}
                                className="work-detail-information-img work-detail-information-img-01">
                                <div className="swiper-container">
                                    <ul className="swiper-wrapper top">
                                        <li className="swiper-slide slide01">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_01.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide02">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_02.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide03">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_03.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide01">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_01.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide02">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_02.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide03">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_03.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide01">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_01.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide02">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_02.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide03">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_03.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide01">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_01.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide02">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_02.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide03">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_03.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className="swiper-container" dir="rtl">
                                    <ul className="swiper-wrapper bottom">
                                        <li className="swiper-slide slide01">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_04.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide02">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_05.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide03">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_06.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide01">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_04.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide02">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_05.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide03">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_06.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide01">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_04.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide02">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_05.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide03">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_06.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide01">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_04.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide02">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_05.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li className="swiper-slide slide03">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section02_06.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className="bentley-sticker">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_bentley_card_sticker_01.webp'
                                        }
                                        alt="벤틀리 카드 이미지"
                                    />
                                </div>
                            </motion.div>
                        </motion.div>

                        <div className="work-detail-information-container work-detail-information-container-03">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.div
                                    variants={variants}
                                    className="work-detail-information-img-01-bg">
                                    <img
                                        className="img-icon-pc"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_bentley_icon.webp'
                                        }
                                        alt="bentley icon 이미지"
                                    />
                                    <img
                                        className="img-icon-mb"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_bentley_icon_mobile.svg'
                                        }
                                        alt="bentley icon 이미지"
                                    />
                                </motion.div>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02">
                                <motion.ul variants={variants} className="fade-up">
                                    <li>
                                        <div className="work-detail-information-img-bg"></div>
                                    </li>
                                    <li>
                                        <div className="work-detail-information-img-wrap"></div>
                                    </li>
                                </motion.ul>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-04">
                            <div className="work-detail-information-img work-detail-information-img-01">
                                <div>
                                    <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="work-detail-information-img-container mySwiper">
                                        <motion.ul
                                            variants={variants}
                                            className="fade-up swiper-wrapper">
                                            <li className="swiper-slide">
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/site/res/image/img_bentley_section04_01.webp'
                                                    }
                                                    alt="벤틀리 이미지"
                                                />
                                            </li>
                                            <li className="swiper-slide">
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/site/res/image/img_bentley_section04_02.webp'
                                                    }
                                                    alt="벤틀리 이미지"
                                                />
                                            </li>
                                            <li className="swiper-slide">
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/site/res/image/img_bentley_section04_03.webp'
                                                    }
                                                    alt="벤틀리 이미지"
                                                />
                                            </li>
                                        </motion.ul>
                                    </motion.div>
                                </div>
                            </div>
                        </div>

                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="work-detail-information-container work-detail-information-container-05 fade-up">
                            <motion.div
                                variants={variants}
                                className="work-detail-information-img work-detail-information-img-01">
                                <div className="information-img-wrap">
                                    <ul>
                                        <li>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section05_01.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section05_02.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className="information-img-wrap">
                                    <ul>
                                        <li>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section05_03.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section05_04.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className="information-img-wrap">
                                    <ul>
                                        <li>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section05_05.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section05_06.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                        <li>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bentley_section05_07.webp'
                                                }
                                                alt="벤틀리 이미지"
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </motion.div>
                            <div className="work-detail-information-img work-detail-information-img-02 for-mobile">
                                <ul>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_bentley_section05_01.webp'
                                            }
                                            alt="벤틀리 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_bentley_section05_03x3.webp'
                                            }
                                            alt="벤틀리 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_bentley_section05_05x3.webp'
                                            }
                                            alt="벤틀리 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_bentley_section05_06x3.webp'
                                            }
                                            alt="벤틀리 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_bentley_section05_02x3.webp'
                                            }
                                            alt="벤틀리 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_bentley_section05_04x3.webp'
                                            }
                                            alt="벤틀리 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_bentley_section05_07.webp'
                                            }
                                            alt="벤틀리 이미지"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </motion.div>

                        <div className="work-detail-information-container work-detail-information-container-06">
                            <div className="work-detail-information-img work-detail-information-img-01">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_bentley_title.svg'
                                    }
                                    alt="bentley membership"
                                />
                            </div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_bentley_section06_01.webp'
                                    }
                                    alt="벤틀리 이미지"
                                />
                            </motion.div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
