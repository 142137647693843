import { useState } from 'react';
import { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import Typography from '../Typography';

export default function MainSectionInsight(props) {
    const mentorRef = useRef();
    const handlyRef = useRef();
    const insightSectionRef = useRef();

    const [handlyInit] = useState({
        min: 7,
        max: 22,
        move: window.innerWidth * (-530 / 1920)
    });

    const [mentorInit] = useState({
        min: -5,
        max: 18,
        move: window.innerWidth * (530 / 1920)
    });

    const { scrollYProgress } = useScroll({
        target: insightSectionRef
    });

    const handlyRotate = useTransform(scrollYProgress, [0, 1], [handlyInit.min, handlyInit.max]);
    const mentorRotate = useTransform(scrollYProgress, [0, 1], [mentorInit.min, mentorInit.max]);
    const handlyX = useTransform(scrollYProgress, [0, 1], [0, handlyInit.move]);
    const mentorX = useTransform(scrollYProgress, [0, 1], [0, mentorInit.move]);

    return (
        <section className="MainSectionInsight" ref={insightSectionRef}>
            <div className="sticky">
                <h3>
                    <small>we have</small>
                    <Typography className="unleashing" txt="Unleashing" />
                    <Typography className="unleashing" txt="Solutions" />
                    {/* <span>Unleashing</span>
                    <span>Solutions</span> */}
                </h3>

                <div className="card-container">
                    <motion.div
                        className="card handly"
                        ref={handlyRef}
                        style={{
                            rotate: handlyRotate,
                            x: handlyX
                        }}>
                        <img src={process.env.PUBLIC_URL + '/image/img_insight_card_handly.webp'} />
                    </motion.div>
                    <motion.div className="card popee">
                        <img src={process.env.PUBLIC_URL + '/image/img_insight_card_popee.webp'} />
                    </motion.div>
                    <motion.div
                        className="card mentor"
                        ref={mentorRef}
                        style={{
                            rotate: mentorRotate,
                            x: mentorX
                        }}>
                        <img src={process.env.PUBLIC_URL + '/image/img_insight_card_mentor.webp'} />
                    </motion.div>
                </div>
            </div>
        </section>
    );
}
