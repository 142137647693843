import React from 'react';
import { motion } from 'framer-motion';

const DelayHovering = React.memo(({children, delay, className, ...props}) => {
    function randNum(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const rand = randNum(0, 1);
    const randRotate = randNum(2, 3);
    const y = [-15, 15][rand];
    const rotate = [randRotate, randRotate * -1][rand];
    return (
        <motion.div className={className} animate={['initial']} variants={{
            initial: {
                y: [y, y*-1],
                rotate: [rotate, rotate*-1],
                transition: {
                    delay: delay,
                    duration: randNum(4, 8),
                    repeat: Infinity,
                    repeatType: "reverse"
                }
            }
        }} {...props}>
            {children}
        </motion.div>
    )
})

export default React.memo(DelayHovering, (props, nextProps) => props !== nextProps);