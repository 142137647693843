import { GnbContext } from '../Pc';
import { useEffect, useContext, useState,useRef } from 'react';
import { easeInOut, motion,useScroll,useTransform,useMotionValueEvent } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';

export default function Shinsegae(){
    const gnbContext = useContext(GnbContext);
    const sec03Ref = useRef();
    const sec07Ref = useRef();
    const [isHover,setIsHover] = useState(false);
    const [isFashion,setIsFashion] = useState(false);
    const handleInHover = (e) => {
        setIsHover(true);
    }
    const handleOutHover = () => {
        setIsHover(false);
    }

    const showFashion = (e) => {
        setIsFashion(true)
    }

    const showShopping = (e) => {
        setIsFashion(false)
    }

    const { scrollYProgress } = useScroll({
        target: sec03Ref,
        offset:['start', '100vh']
    });
    const section07ScrollY = useScroll({
        target: sec07Ref
    });

    const y = useTransform(scrollYProgress, [0, 1], ['0','-30%'] ,{ease:easeInOut});
    const y02 = useTransform(section07ScrollY.scrollYProgress, 
        [0.1, 1], 
        ['-20%','0%']
      );

    const variants = {
            visible: {
                opacity: 1,
                y: '0%',
                transition: {
                    opacity: {
                        duration: 1.8
                    },
                    y: {
                        duration: 1.2
                    },
                    duration: 1.2,
                    delay: 0.05,
                    ease: 'linear'
                }
            },
            hidden: { opacity: 0, y: '50%' }
        };
            
    useEffect(() => {
        gnbContext.actions.setGnbColor('white');
    }, []);

    return(
        <div className='Shinsagae'>
            <div className='work-detail-wrap' data-hash="shinsegae">
                <div className='project-cover'>
                    <div className='cover-img-wrap'>
                        <div className='cover-content-wrap'>
                            <div className='cover-bg-text'>
                                <span>shinsegae</span>
                                <span>SHOPPING app</span>
                            </div>
                            <div className='cover-mockup'>
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/shinsegae/img_shin_main_mockup.webp'
                                    }
                                />
                            </div>
                            <div className="swiper-container">
                            <Swiper
                                className="mySwiper"
                                slidesPerView={'auto'}
                                autoplay={{
                                    delay: 1000,
                                    disableOnInteraction: false
                                }}
                                modules={[Autoplay]}
                                loop={true}
                                centeredSlides={true}
                                speed={2000}
                                freeMode={false}>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/img_shin_main_slide_01.webp'
                                        }
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/img_shin_main_slide_02.webp'
                                        }
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/img_shin_main_slide_03.webp'
                                        }
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/img_shin_main_slide_04.webp'
                                        }
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/img_shin_main_slide_05.webp'
                                        }
                                    />
                                </SwiperSlide>
                            </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className='cover-text-wrap'>
                        <div className="container">
                            <h2 className="project-type">shinsegae LIVE SHOPPING</h2>
                            <h4 className="project-name">shinsegae SHOPPING app</h4>
                            <ul className="project-team">
                                <li className="team-tag">design</li>
                                <li className="team-tag">Project Management</li>
                                <li className="team-tag">development</li>
                            </ul>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="project-desc">
                                <motion.p variants={variants}>
                                    신세계쇼핑은 2013년 설립된 종합 쇼핑 플랫폼으로, 백화점 브랜드를 비롯한 다수의  브랜드와 전문관이 입점되어 있으며 다양한 쇼핑콘텐츠가 제공되고 있습니다. 
                                    <br />
                                    <br />
                                    기존 신세계쇼핑의 에셋을 컴포넌트화 및 재정립하여 운영이 용이하게 될 수 있도록 하고, 전문관을 신설하는 등 쇼핑 관련 콘텐츠를 강화하여 더욱 전문성이 강화된 종합 쇼핑 플랫폼으로 거듭나기 위한 UI/UX 리뉴얼을 진행하였습니다.
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>
                <div className='project-contents dark'>
                    <section className='cont-sec-01'>
                        <div className='container'>
                            <div className='cont-wrap-01'>
                                <h2>analysis</h2>
                                <p>
                                리뉴얼에 앞서 신세계쇼핑이 현재 제공하고 있는 다양한 쇼핑 콘텐츠를 분석해보았습니다.
                                <br/>
                                TV 쇼핑 및 라이브 쇼핑을 비롯한 실시간 방송 서비스와 함께 온라인 상품, 
                                <br/>
                                그리고 백화점에서 판매하는 프리미엄 상품을 판매하고 있습니다.
                                <br/>
                                또한 고객의 쇼핑 관심사와 선호도에 맞춰 상품을 추천하는 큐레이션 서비스를 제공하여 쇼핑 경험을 개인화하고 있습니다.
                                <br/>
                                이외에도 득템마켓과 같은 합리적인 쇼핑 콘텐츠를 제공하여 고객들에게 다양한 혜택을 제공하고 있습니다.
                                <br/>
                                <br/>
                                이러한 다채로운 쇼핑 콘텐츠가 신세계쇼핑을 이용하는 소비자에게 
                                <br/>
                                더 나은 쇼핑 경험을 제공하는 중요한 요소로 작용할 것으로 판단하였습니다.
                                </p>
                                <div className='gradient-bg'></div>
                            </div>
                            <div className='cont-wrap-02'>
                                <ul>
                                    <li className='shopping'>
                                        <h3>shopping contents</h3>
                                        <div className='circle-list-wrap'>
                                            <div className='circle-list'>
                                                <div className='circle-text-wrap'>
                                                <h4>실시간 방송</h4>
                                                <span>TV쇼핑, 라이브</span>
                                                </div>
                                            </div>
                                            <div className='circle-list'>
                                                <div className='circle-text-wrap'>
                                                <h4>프리미엄</h4>
                                                <span>백화점상품</span>
                                                </div>
                                                
                                            </div>
                                            <div className='circle-list'>
                                                <div className='circle-text-wrap'>
                                                <h4>큐레이션</h4>
                                                <span>제품 추천</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='valuable'>
                                    <h3>valuable contents</h3>
                                        <div className='circle-list-wrap'>
                                            <div className='circle-list'>
                                                <div className='circle-text-wrap'>
                                                <h4>다양한 혜택</h4>
                                                <span>쿠폰 및 이벤트</span>
                                                </div>
                                            </div>
                                            <div className='circle-list'>
                                                <div className='circle-text-wrap'>
                                                <h4>현명한 소비습관</h4>
                                                <span>득템마켓, 공동구매</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className='line'>
                                <img src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_line.png'
                            }></img>
                                </div>
                            </div>
                            <div className='cont-wrap-03'>
                                <strong>
                                Better
                                <br/>
                                shopping experience
                                </strong>
                                <p>더 나은 쇼핑경험 제공</p>
                            </div>
                        </div>
                    </section>
                    <section className='cont-sec-02'>
                        <div className='sec-text-box'>
                            <h4 className='h4-title'>STRATEGY</h4>
                            <p className='p-sec-desc'>
                                우리는 이 시점에서 더 나은 쇼핑 경험을 겪게 되는 주체인 소비자를 다른 시각에서 바라보고자 했습니다.
                                <br/>
                                단순히 상품을 구매하는 소비자가 아닌, 쇼핑이라는 콘텐츠를 즐기는 유저이자
                                <br/>
                                새로운 라이프 스타일을 추구하는 주체로 치환하고
                                <br/>
                                그러한 관점에서 신세계쇼핑 앱에 접속할 수 있도록 하려고 합니다.
                            </p>
                        </div>
                        <div className='sec-detail-box'>
                            <ul>
                                <li className={`for-consumer ${isHover ? 'show-dark' : ''}`}>
                                    <div className='detail-img'>
                                        <img src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec02_01.webp'
                            }></img>
                                    </div>
                                    <strong>소비자</strong>
                                    <div className='hover-tag consumer-01'>플랫폼마다 비슷한 경험 반복</div>
                                    <div className='hover-tag consumer-02'>수동적 상품 수요자</div>
                                    <div className='hover-tag consumer-03'>색다르고 가치 있는 소비 경험의 니즈</div>
                                </li>
                                <li className='for-contents' onMouseEnter={handleInHover} onMouseOut={handleOutHover}>
                                <div className='detail-img'>
                                        <img src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec02_02.webp'
                            }></img>
                                    </div>
                                    <strong>콘텐츠 유저</strong>
                                    <div className='hover-tag  contents-01'>새로운 라이프 스타일을 추구</div>
                                    <div className='hover-tag contents-02'>쇼핑 콘텐츠를 즐기는</div>
                                </li>
                            </ul>
                            
                        </div>
                    </section>
                    <section className='cont-sec-03' ref={sec03Ref}>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            style={{y}}
                            className='bg-img-01'>
                            <motion.img 
                               
                                variants={variants}
                                src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec03_01.webp'
                            }></motion.img>
                        </motion.div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            style={{y}}
                            className='bg-img-02'>
                            <motion.img 
                                variants={variants}
                                src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec03_02.webp'
                            }></motion.img>
                            <motion.div 
                            variants={variants}
                            className='bg-text'>
                                더 나은 쇼핑 콘텐츠와
                                <br/>
                                현명한 라이프 스타일을 제공하는
                                <br/>
                                역할을 하는 플랫폼입니다.
                            </motion.div>
                        </motion.div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            style={{y}}
                            className='bg-img-03'>
                            <motion.img 
                                variants={variants}
                                src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec03_03.webp'
                            }></motion.img>
                        </motion.div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            style={{y}}
                            className='bg-img-04'>
                            <motion.img 
                                variants={variants}
                                src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec03_04.webp'
                            }></motion.img>
                            <motion.div 
                            variants={variants}
                            className='bg-text'>
                            새롭게 정의된 신세계쇼핑은 단순히
                            쇼핑 커머스가 아닌,
                            </motion.div>
                        </motion.div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            style={{y}}
                            className='bg-img-05'>
                            <motion.img 
                                variants={variants}
                                src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec03_05.webp'
                            }></motion.img>
                        </motion.div>
                        <div className='cont-text-box'>
                            <p>
                                new<br/>
                                shinsegae<br/>
                                live SHOPPING<br/>
                                app
                            </p>
                        </div>
                    </section>
                    <section className='cont-sec-04'>
                        <div className='cont-wrap'>
                            <h4 className='h4-title'>concept</h4>
                            <p>
                                상품을 구매하는 것을 넘어
                                <br/>
                                쇼핑을 즐겁고 편안한 경험으로 바꿔줄 수 있도록 돕는
                                <br/>
                                퍼스널 쇼퍼같은 서비스
                            </p>
                            <motion.strong className='big-text-wrap' viewport={{ once: true, amount: "all" }} whileInView="visible" initial="hidden" animate="rest">
                                {String('personal').split("").map((el, idx) => {
                                    return(<motion.div key={idx}>
                                        <motion.span
                                            variants={{
                                                hidden: { 
                                                    opacity: 0,
                                                    y: "50%",
                                                    transition: {
                                                        duration: 0.4,
                                                        delay: 0
                                                    }
                                                },
                                                visible: { 
                                                    opacity: 1,
                                                    y: "0%",
                                                    transition: {
                                                        duration: 0.5, 
                                                        delay: idx * 0.05,
                                                        ease: "easeInOut"
                                                    },
                                                }
                                            }}>
                                            {el}
                                        </motion.span>
                                    </motion.div>)
                                })}
                            </motion.strong>
                            <motion.strong className='big-text-wrap' viewport={{ once: true, amount: "all" }} whileInView="visible" initial="hidden" animate="rest">
                                {String('shopper').split("").map((el, idx) => {
                                    return(<motion.div key={idx}>
                                        <motion.span
                                            variants={{
                                                hidden: { 
                                                    opacity: 0,
                                                    y: "50%",
                                                    transition: {
                                                        duration: 0.4,
                                                        delay: 0
                                                    }
                                                },
                                                visible: { 
                                                    opacity: 1,
                                                    y: "0%",
                                                    transition: {
                                                        duration: 0.5, 
                                                        delay: idx * 0.05,
                                                        ease: "easeInOut"
                                                    },
                                                }
                                            }}>
                                            {el}
                                        </motion.span>
                                    </motion.div>)
                                })}
                            </motion.strong>
                            <div className='item-img-wrap'>
                            </div>
                        </div>
                    </section>
                    <section className='cont-sec-05'>
                        <div className='container-01'>
                            <h4 className='h4-title'>keyword</h4>
                            <p className='p-sec-desc'>
                                고객에게 퍼스널쇼퍼로 다가가는 신세계쇼핑은 쉽고, 친절하며, 즐거워야합니다.
                                <br/>
                                고객이 쇼핑을 구매하는 행위가 아닌 경험 그 자체로 즐길 수 있도록 다음 4가지를 디자인 키워드로 설정하였습니다.
                            </p>
                            <div className='keyword-list-box'>
                                <ul className='keyword-list-wrap'>
                                    <li className='keyword-list'>
                                        <h5>
                                            Quick
                                            <br/>
                                            Access
                                        </h5>
                                        <p>
                                            스크롤 과정 없이 콘텐츠와<br/>
                                            정보를 확인할 수 있게 하여,<br/>
                                            쉽고 빠르게 접근할 수 있도록 합니다.
                                        </p>
                                    </li>
                                    <li className='keyword-list'>
                                        <h5>
                                        Familiar
                                        <br/>
                                        Message
                                        </h5>
                                        <p>
                                            개인화된 메시지와<br/>
                                            감성적인 UX 라이팅으로<br/>
                                            친근하게 다가갑니다.
                                        </p>
                                    </li>
                                    <li className='keyword-list'>
                                        <h5>
                                            Come
                                            <br/>
                                            Close
                                        </h5>
                                        <p>
                                            실시간 현황을 잘 보이는 곳에<br/>
                                            배치하여 원하는 혜택을<br/>
                                            놓치지 않게끔 돕습니다.
                                        </p>
                                    </li>
                                    <li className='keyword-list'>
                                        <h5>
                                            Eye
                                            <br/>
                                            Shopping
                                        </h5>
                                        <p>
                                            생동감 있는 인터랙션과<br/>
                                            에셋 활용으로 둘러보는 것으로도<br/>
                                            즐거운 경험이 될 수 있도록 합니다.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div className='img-cont'>
                                <img src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/shinsegae/img_shin_sec05_frame.webp'
                                }></img>
                                <video
                                    
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/shinsegae/video/video_shin_sec05.mp4'
                                    }
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    poster="">
                                    <source
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/video/video_shin_sec05.mp4'
                                        }
                                        type="video/mp4"></source>
                                </video>
                            </div>
                        </div>
                        <div className='container-02'>
                            <ul className='icon-box-wrap'>
                                <li className='icon-box'>
                                    <h5>
                                    app icon
                                    </h5>
                                    <div className='app-wrap'>
                                    <img src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/shinsegae/icn_shin_app.svg'
                                }></img>
                                    </div>
                                </li>
                                <li className='icon-box'>
                                    <h5>
                                    UI color
                                    </h5>
                                    <ul className='color-wrap'>
                                        <li className='ui-color FF329B'></li>
                                        <li className='ui-color FF58B2'></li>
                                        <li className='ui-color FFEBF5'></li>
                                        <li className='ui-color gradient'></li>
                                        <li className='ui-color black'></li>
                                        <li className='ui-color white'></li>
                                        <li className='ui-color D0D0D0'></li>
                                        <li className='ui-color gray'></li>
                                    </ul>
                                </li>
                                <li className='icon-box'>
                                    <h5>
                                    icongraphy
                                    </h5>
                                    <ul className='icon-wrap'>
                                        <li className='icongraphy'>
                                            <img src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/shinsegae/icn_shin_sec05_01.svg'
                                            }></img>
                                        </li>
                                        <li className='icongraphy'>
                                        <img src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/icn_shin_sec05_02.svg'
                                        }></img>
                                        </li>
                                        <li className='icongraphy'>
                                             <img src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/icn_shin_sec05_03.svg'
                                        }></img>
                                        </li>
                                        <li className='icongraphy'>
                                             <img src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/icn_shin_sec05_04.svg'
                                        }></img>
                                        </li>
                                        <li className='icongraphy'>
                                             <img src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/icn_shin_sec05_05.svg'
                                        }></img>
                                        </li>
                                        <li className='icongraphy'>
                                             <img src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/icn_shin_sec05_06.svg'
                                        }></img>
                                        </li>
                                        <li className='icongraphy'>
                                             <img src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/icn_shin_sec05_07.svg'
                                        }></img>
                                        </li>
                                        <li className='icongraphy'>
                                             <img src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/icn_shin_sec05_08.svg'
                                        }></img>
                                        </li>
                                        <li className='icongraphy'>
                                             <img src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/icn_shin_sec05_09.svg'
                                        }></img>
                                        </li>
                                        <li className='icongraphy'>
                                             <img src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/icn_shin_sec05_10.svg'
                                        }></img>
                                        </li>
                                    </ul>
                                    
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className={`cont-sec-06 ${isFashion ? 'show-fashion':''}`}>
                        <div className='text-cont-wrap'>
                            <div className='text-wrap shopping'>
                                <p>shopping</p>
                                <span className='tag'>YOUR CHOICE</span>
                            </div>
                            <div className='text-wrap fashion' 
                            onMouseEnter={showFashion}
                            onMouseOut={showShopping}>
                                <p>fashion</p>
                                <span className='tag'>YOUR CHOICE</span>
                            </div>
                        </div>
                        <div className='img-cont-wrap'>
                                        
                        </div>
                    </section>
                    <section className='cont-sec-07' ref={sec07Ref}>
                        <div className='text-cont-wrap'>
                            <div className='text-left-box'>
                                <p className='p-text-left'>
                                    함께하는
                                    <br/>
                                    쇼핑의 즐거움
                                </p>
                                <p className='p-text-left'>
                                    봄이 오고 있어요.
                                    <br/>
                                    산뜻한 하루 되세요!
                                </p>
                                <p className='p-text-left'>
                                    나른한 오후
                                    <br/>
                                    조금만 더 힘내요
                                </p>
                                <p className='p-text-left'>
                                    아직은 쌀쌀하네요.
                                    <br/>
                                    외투 꼭 챙기세요.
                                </p>
                                <p className='p-text-left'>
                                    봄을 맞아 따스한
                                    <br/>
                                    시작을 함께 해요!
                                </p>
                            </div>
                            <div className='text-right-box'>
                                <h5 className='h5-text-title'>
                                warm
                                <br/>
                                welcome
                                </h5>
                                <p className='p-text-feature'>
                                    쇼핑에서는 개인화된 환영 메시지를 통해
                                    <br/>
                                    사용자를 따뜻하게 맞이합니다.
                                    <br/>
                                    날씨 혹은 시간에 맞추어 각 사용자에게 맞춤형으로 
                                    <br/>
                                    구성된 환영 메시지는 고객의 쇼핑 경험을
                                    <br/>
                                    더욱 특별하고 소중하게 만들어줍니다.
                                </p>
                            </div>
                        </div>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            style={{y:y02}}
                            className='img-cont-wrap'>
                            <motion.img 
                            variants={variants}
                            src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec07.webp'
                            }></motion.img>
                        </motion.div>
                    </section>
                    <section className='cont-sec-08'>
                        <div className='top-container'>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='cont-wrap top'>
                            <div className='cont-text-box'>
                                <h5 className='h5-text-title'>
                                easy & Familiar
                                <br/>
                                structure
                                </h5>
                                <p className='p-text-feature'>
                                소셜미디어를 연상시키는 익숙한 UI 구조는
                                <br/>
                                실제로 커뮤니케이션을 하는 듯한 사용자 경험을 제공합니다.
                                <br/>
                                또한 썸네일을 통해 현재 라이브 중인 상품과
                                <br/>
                                예정된 방송을 손쉽게 파악하고
                                <br/>
                                관심 상품에 빠르게 접근할 수 있도록 하였습니다.
                                </p>
                            </div>
                            <motion.div 
                            variants={variants}
                            className='cont-img-box'>
                            <img src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_iphone_frame.webp'
                            }></img>
                            <video
                                    
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/shinsegae/video/video_shin_sec08_01.mp4'
                                    }
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    poster="">
                                    <source
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/video/video_shin_sec08_01.mp4'
                                        }
                                        type="video/mp4"></source>
                                </video>
                            </motion.div>
                        </motion.div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='cont-wrap bottom'>
                            <motion.div 
                            variants={variants}
                            className='cont-img-box'>
                                <img src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/shinsegae/img_shin_iphone_frame.webp'
                                }></img>
                                <video

                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/video/video_shin_sec08_02.mp4'
                                        }
                                        autoPlay
                                        muted
                                        loop
                                        playsInline
                                        poster="">
                                        <source
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/shinsegae/video/video_shin_sec08_02.mp4'
                                            }
                                            type="video/mp4"></source>
                                </video>
                            </motion.div>
                            <div className='cont-text-box'>
                                <h5 className='h5-text-title'>
                                fun access
                                </h5>
                                <p className='p-text-feature'>
                                    내비게이션에서는 실시간 방송 중인 상품의 
                                    <br/>
                                    썸네일을  즉각적으로 확인할 수 있습니다.
                                    <br/>
                                    또한 썸네일을 통해 현재 라이브 중인 상품과
                                    <br/>
                                    어떤 페이지에 있든 방송 중인 상품을 바로 만날 수 있고
                                    <br/>
                                    생동감 있는 인터렉션은 쇼핑에 재미를 더합니다.
                                </p>
                            </div>
                        </motion.div>
                        </div>
                        <div className='bottom-container'></div>
                    </section>
                    <section className='cont-sec-09'>
                        <div className='cont-text-wrap'>
                            <h5 className='h5-text-title'>
                                thoughtful
                                <br/>
                                consideration
                            </h5>
                            <p className='p-text-feature'>
                                고객이 신세계쇼핑이 제공하는 혜택을 
                                <br/>
                                잊지 않고 확인할 수 있도록 하는 요소들을 서비스 곳곳에 
                                <br/>
                                배치하여 친절하고 사려 깊은 진심을 전달합니다.
                            </p>
                        </div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='cont-img-wrap'>
                            <motion.div 
                            variants={variants}
                            className='cont-img-list cont-wrap'>
                                <img className='cont-frame' src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/shinsegae/img_shin_sec09_frame.webp'
                                }></img>
                                <video
                                    className='cont-video'
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/shinsegae/video/video_shin_sec09_02.mp4'
                                    }
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    poster="">
                                    <source
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/video/video_shin_sec09_02.mp4'
                                        }
                                        type="video/mp4"></source>
                                </video>
                            </motion.div>
                            <motion.div 
                            variants={variants}
                            className='cont-img-list cont-wrap'>
                                <img className='cont-frame' src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/shinsegae/img_shin_sec09_frame.webp'
                                }></img>
                                <video
                                    className='cont-video'
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/shinsegae/video/video_shin_sec09_03.mp4'
                                    }
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    poster="">
                                    <source
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/video/video_shin_sec09_03.mp4'
                                        }
                                        type="video/mp4"></source>
                                </video>
                            </motion.div>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='cont-img'>
                        <motion.div 
                        variants={variants}
                        className='cont-wrap'>
                            <img className='cont-frame' src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/shinsegae/img_shin_sec09_frame.webp'
                                }></img>
                                <video
                                    className='cont-video'
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/shinsegae/video/video_shin_sec09_01.mp4'
                                    }
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    poster="">
                                    <source
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/video/video_shin_sec09_01.mp4'
                                        }
                                        type="video/mp4"></source>
                                </video>
                        </motion.div>
                        </motion.div>

                        
                        <div className='sec-bg'>
                            <img src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec09_bg.webp'
                            }></img>
                        </div>
                    </section>
                    <section className='cont-sec-10'>
                        <div className='text-cont-wrap-bg'>
                            <span className='fashion'>fashion</span>
                            <span className='trend'>trend</span>
                            <span className='style'>style</span>
                        </div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='cont-img-01'>
                            <motion.img 
                                variants={variants}
                                src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec10_06.webp'
                            }></motion.img>
                        </motion.div>
                        <motion.div 
                        initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                        className='cont-wrap'>
                            <motion.div className='cont-img-02'
                            variants={variants}>
                                <img src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/shinsegae/img_shin_iphone_frame.webp'
                                }></img>
                                <video
                                    className='cont-video'
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/shinsegae/video/video_shin_sec10.mp4'
                                    }
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    poster="">
                                    <source
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/video/video_shin_sec10.mp4'
                                        }
                                        type="video/mp4"></source>
                                </video>
                            </motion.div>
                            <div className='cont-text-wrap'>
                                <h5 className='h5-text-title'>
                                    trend
                                    <br/>
                                    LOOKBOOK
                                </h5>
                                <p className='p-text-feature'>
                                    이미지를 단순히 상품의 썸네일이 아닌,
                                    <br/>
                                    패션 화보나 룩북 같은 비주얼 콘텐츠로
                                    <br/>
                                    인식할 수 있도록 배치하였습니다.
                                    <br/>
                                    고객은 이미지를 하나씩 스와이프하며
                                    <br/>
                                    상품을 감상할 수 있으며 트렌드를 둘러보는
                                    <br/>
                                    느낌을 통해 더욱 몰입적인 경험을 제공합니다.
                                </p>
                            </div>
                        </motion.div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='bg-img-01'>
                            <motion.img 
                                variants={variants}
                                src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec10_01.webp'
                            }></motion.img>
                        </motion.div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='bg-img-02'>
                            <motion.img 
                                variants={variants}
                                src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec10_02.webp'
                            }></motion.img>
                        </motion.div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='bg-img-03'>
                            <motion.img 
                                variants={variants}
                                src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec10_03.webp'
                            }></motion.img>
                        </motion.div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='bg-img-04'>
                            <motion.img 
                                variants={variants}
                                src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec10_04.webp'
                            }></motion.img>
                        </motion.div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='bg-img-05'>
                            <motion.img 
                                variants={variants}
                                src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec10_05.webp'
                            }></motion.img>
                        </motion.div>
                        <div className='bg-gradient'>
                            <img src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/shinsegae/img_shin_sec10_gradient.png'
                                }></img>
                        </div>
                        <div className='text-cont-wrap-bg end'>
                            <span>lookbook</span>
                        </div>
                    </section>
                    <section className='cont-sec-11'>
                        <div className='cont-wrap-01'>
                            <img src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec11_01.webp'
                            }></img>
                        </div>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='cont-wrap-02'>
                            <motion.div 
                                variants={{
                                    visible:{
                                        opacity:1,
                                        x:'0%',
                                        transition:{
                                            opacity:{
                                                duration:1.8
                                            },
                                            x:{
                                                duration:0.7
                                            },
                                            duration: 0.7,
                                            delay: 0.5,
                                            ease: 'linear'
                                        }
                                    },
                                    hidden: { opacity: 0, x: '50%' }
                                }}
                            className='cont-wrap'>
                            <img src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec11_frame.webp'
                            }></img>
                            <video
                                    className='cont-video'
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/shinsegae/video/video_shin_sec11.mp4'
                                    }
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    poster="">
                                    <source
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/shinsegae/video/video_shin_sec11.mp4'
                                        }
                                        type="video/mp4"></source>
                                </video>
                            </motion.div>
                        </motion.div>
                    </section>
                    <section className='cont-sec-12'>
                        <div className='cont-text-wrap'>
                            <h5 className='h5-text-title'>
                                Efficient
                                <br/>
                                components
                            </h5>
                            <p className='p-text-feature'>
                                UI를 컴포넌트화하여 더욱 효율적인 
                                <br/>
                                운영이 가능하도록 했습니다.
                                <br/>
                                작업을 모듈화하고 중복을 최소화하여 
                                <br/>
                                개발 및 유지보수를 간편하게 향상시켰습니다.
                            </p>
                        </div>
                        <div className='img-cont-wrap'>
                            <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='img-cont-list'>
                                <motion.div
                                variants={variants}>
                                <div className='img-cont-01'>
                                <img src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec12_01.webp'
                            }></img>
                                </div>
                                <div className='img-cont-02'>
                                <img src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec12_02.webp'
                            }></img>
                                </div>
                                </motion.div>
                            </motion.div>
                            <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className='img-cont-list'>
                                <motion.div
                                 variants={variants}>
                                <div className='img-cont-03'>
                                    <img src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec12_03.webp'
                            }></img>
                                </div>
                                <div className='img-cont-04'>
                                    <img src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/img_shin_sec12_04.webp'
                            }></img>
                                </div>
                                </motion.div>
                            </motion.div>
                            <motion.div 
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className='img-cont-list'>
                                <motion.div 
                                    variants={variants}
                                    className='img-cont-05'>
                                    <img src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/shinsegae/img_shin_sec12_05.webp'
                                    }></img>
                                </motion.div>
                            </motion.div>
                        </div>
                    </section>
                    <section className='cont-sec-13'>
                        <video
                            className='cont-video'
                            src={
                                process.env.PUBLIC_URL +
                                '/site/res/image/shinsegae/video/video_shin_sec13.mp4'
                            }
                            autoPlay
                            muted
                            loop
                            playsInline
                            poster="">
                            <source
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/shinsegae/vide/video_shin_sec13.mp4'
                                }
                                type="video/mp4"></source>
                        </video>
                    </section>
                    
                </div>
            </div>
        </div>
    )
}