import { GnbContext } from '../Pc';
import { useEffect, useContext } from 'react';
import { motion, useInView, useAnimate } from 'framer-motion';

export default function Wego() {
    const [scope, animate] = useAnimate();
    const gnbContext = useContext(GnbContext);
    const isInView = useInView(scope);
    const variants = {
        visible: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 1.2,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, y: '50%' }
    };
    // animate('li', { opacity: 1 });
    useEffect(() => {
        gnbContext.actions.setGnbColor('white');
        // animate(scope.current, { opacity: 1, y: 0 });
    }, []);
    useEffect(() => {
        if (isInView) {
            animate(
                scope.current,

                { opacity: [0, 1], y: ['100%', '0%'] },
                {
                    transition: {
                        opacity: {
                            duration: 1.8
                        },
                        y: {
                            duration: 1.2
                        }
                    }
                },
                { delay: 0.5 }
            );
        }
    }, [isInView]);
    return (
        <div className="Wego">
            <div className="work-detail-wrap work-detail-container" data-hash="wego">
                <div className="project-cover">
                    <div className="cover-img-wrap"></div>
                    <div className="cover-text-wrap">
                        <div className="container">
                            <h2 className="project-type">WE GO</h2>
                            <h4 className="project-name">Tango&go</h4>
                            <ul className="project-team">
                                <li className="team-tag">design</li>
                                <li className="team-tag">Project Management</li>
                            </ul>
                            <motion.div
                                className="project-desc"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <motion.p variants={variants}>
                                    Tango&go는 누구나 손쉽게 택배, 퀵서비스 등 물류배송 시장에
                                    참여할 수 있는 오픈 운송 O2O 플랫폼입니다.
                                    <br />
                                    <br />
                                    스타트업의 특성에 맞게 필수적인 기능만을 MVP(Minimum Viable
                                    Product) 형식으로 빠르게 구현하는 것을 목표로 두었습니다. 펀딩과
                                    시범사업이 목적이었던 프로젝트였기 때문에 빠르게 파일럿을 통해
                                    적용시킬 기능과 정식 론칭 후에 필요한 기능을 구분 짓는 것이
                                    중요하다고 생각했습니다. 필수적인 기능을 빠르게 구현하면서 타
                                    서비스와 구분되는 Tango&go의 특징을 살리기 위해 많은 고민을
                                    했습니다.
                                    <br />
                                    <br />
                                    배송 주문을 넣는 고객 서비스에서는 ‘빠른 배송 기사 매칭‘을
                                    강조하며 언제, 어디서나 ‘요청’ 버튼 하나로 물류 작업을 시작할 수
                                    있도록 구성하였고, 배송 업무를 하는 라이더 서비스에서는 송
                                    수단에 맞춰 빠르고 편리한 작업을 할 수 있도록 상태 값, 아이콘 등
                                    선택요소의 직관성을 가장 많이 고려하여 물류창고-허브-고객 사이를
                                    오갈 때 손쉽게 노선을 설정하고 정정할 수 있도록 기능을
                                    구축했습니다.
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>
                <div className="work-detail-contents">
                    <section className="work-detail-information">
                        <div className="work-detail-information-container work-detail-information-container-01">
                            <div className="work-detail-informatio n-img work-detail-information-img-01 reactive-rectangle">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_wego_section01_bg.webp'
                                    }
                                    alt="tango&go 이미지"
                                />
                            </div>
                            <div className="work-detail-information-img work-detail-information-img-02">
                                <ul>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section01_01.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="3">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section01_02.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                </ul>
                                <ul className="for-mobile">
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section01_01.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section01_02.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        nitial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section01_03.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                </ul>
                            </div>
                            <div className="work-detail-information-img work-detail-information-img-03">
                                <ul>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="5">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section01_03.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="4">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section01_04.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                </ul>
                                <ul className="for-mobile">
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section01_04.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section01_05.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section01_06.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                </ul>
                            </div>
                            <div className="work-detail-information-img work-detail-information-img-04">
                                <ul>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="3">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section01_05.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="3">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section01_06.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                </ul>
                            </div>
                        </div>
                        <div className="work-detail-information-container work-detail-information-container-02">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_wego_section02_01.webp'
                                    }
                                    alt="tango&go 이미지"
                                />
                            </motion.div>
                            <div className="work-detail-information-img work-detail-information-img-02">
                                <ul>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="6">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section02_02.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="7">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section02_03.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="8">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section02_04.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </motion.li>
                                </ul>
                            </div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-03">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_wego_section03_01.webp'
                                    }
                                    alt="tango&go 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_wego_section03_02.webp'
                                    }
                                    alt="tango&go 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up">
                                <motion.ul variants={variants}>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section03_03.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section03_04.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </li>
                                </motion.ul>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-04">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.ul variants={variants}>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section04_01.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section04_02.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </li>
                                </motion.ul>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up"
                                data-delay="3">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_wego_section04_03.webp'
                                    }
                                    alt="tango&go 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up">
                                <motion.ul variants={variants}>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section04_04.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_wego_section04_05.webp'
                                            }
                                            alt="tango&go 이미지"
                                        />
                                    </li>
                                </motion.ul>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-04 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL + '/site/res/image/img_wego_logo.svg'
                                    }
                                    alt="tango&go 이미지"
                                />
                            </motion.div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
