import { motion, useScroll, useTransform, useMotionValueEvent, useInView } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import Typography from '../Typography';
export default function AboutSectionIntro() {
    // const aboutRef = useRef();
    // const { scrollYProgress } = useScroll({
    //     target: aboutRef
    // });
    // useEffect(() => {
    //     console.log(scrollYProgress);
    //     console.log(aboutRef);
    // }, [scrollYProgress]);
    // useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    //     console.log('Page scroll: ', latest);
    // });

    // const leftX = useTransform(scrollYProgress, [0, 0.9], ['-50%', '0%']);
    // const rightX = useTransform(scrollYProgress, [0, 0.9], ['50%', '0%']);
    
    
    const contentsRef = useRef();
    const textContRef = useRef();
    const textContInView = useInView(textContRef, { amount: 0.9, margin: "900px 0px 0px 0px" });
    const ipadContRef = useRef();
    const ipadContInView = useInView(textContRef, { amount: 0.5 });

    const [slideImg, setSlideImg] = useState(0);
    const [slideMsg, setSlideMsg] = useState(<>최초의 아이패드가 출시되고 1년 남짓, <br/>그 네모난 디바이스의 가능성을 보고 모인 초기 멤버들은 <br/>특별할 것 없는 공간에서 특별한 무엇인가를 만들어내기 위해 골몰하고 있었어요.</>);

    const {scrollY, scrollYProgress} = useScroll({
        target: contentsRef,
        offset: ["100vh", "end end"]
    });
    
    useMotionValueEvent(scrollYProgress, "change", (v) => {
        if (v > 0.5) {
            setSlideImg(1);
            setSlideMsg(<>서울, 용산에서 시작한 앱노트의 최초의 아이패드를 위한 <br/>디지털 퍼블리싱 솔루션 &lt;MENTOR&gt;가 탄생하는 순간이었죠.</>)
        } else {
            setSlideImg(0);
            setSlideMsg(<>최초의 아이패드가 출시되고 1년 남짓, <br/>그 네모난 디바이스의 가능성을 보고 모인 초기 멤버들은 <br/>특별할 것 없는 공간에서 특별한 무엇인가를 만들어내기 위해 골몰하고 있었어요.</>)
        }
    })
    return (
        <div className="AboutSectionIntro">
            <div className="bg">
                <img src={process.env.PUBLIC_URL + "/image/img_bg_about_intro.webp"} alt="" />
            </div>
            <div className="intro-text-container">
                <div className="small-msg solution-entry-text text-01">
                    <div className="flex-column">
                        <Typography
                            className="text-en right"
                            txt="excuse me. so, Are you ready?"
                        />
                        <Typography className="text-en" txt="let's start talking &nbsp;&nbsp;&nbsp;&nbsp; about us now!" />
                    </div>
                    <div className="text-ko flex-column">
                        <Typography
                            custom={1}
                            className="right"
                            txt="여기 모인 사람들은 당신이 원하는 그 무엇인가를 만들어내기 위해"
                        />
                        <Typography
                            custom={2}
                            txt="치열하게 고민하고, 논의하고 있습니다. 때때로 목적지와 반대로 걷기도 하지만, 저희는"
                        />
                        <Typography
                            custom={3}
                            txt="그 과정에서 특별한 당신만의 것을 선보일 수 있죠."
                        />
                        <Typography
                            custom={4}
                            txt="당신이 원하는 무엇을 만들어내는 디지털 전환 전문가 그룹 앱노트,"
                        />
                        <Typography
                            custom={5}
                            className="right"
                            txt="우리의 이야기를 들려드릴게요."
                        />
                    </div>
                </div>

                <div className="solution-entry-text text-02">
                    <Typography custom={6} className="about" txt="about" />
                </div>
            </div>

            <div className="history-container" ref={contentsRef}>
                <div className="year">
                    2011
                </div>

                <div className="contents" ref={textContRef}>
                    <div className={`img-ipad${ipadContInView ? " on" : ""}`} ref={ipadContRef}>
                        <img src={process.env.PUBLIC_URL + "/image/img_ipad_off.webp"} alt="" />
                        <div className="screen">
                            <div className="slide-wrap" style={{transform: "translateX(" + slideImg * -100 + "%)"}}>
                                <img src={process.env.PUBLIC_URL + "/image/img_ipad_screen_01.webp"} alt="" />
                                <img src={process.env.PUBLIC_URL + "/image/img_ipad_screen.webp"} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className={`text-container${textContInView ? " on" : ""}`}>
                        <p>
                            {slideMsg}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        // <section className="AboutSectionIntro" ref={aboutRef}>
        //     <div className="rotate-img">
        //         <img src={process.env.PUBLIC_URL + '/image/img_about_intro.webp'} alt="" />
        //     </div>
        //     <div className="intro-text-wrap">
        //         <motion.div style={{ x: leftX }} className="intro-text">
        //             Unleashing
        //         </motion.div>
        //         <motion.div style={{ x: rightX }} className="intro-text right">
        //             innovation with a
        //         </motion.div>
        //         <motion.div style={{ x: leftX }} className="intro-text">
        //             Touch of Geekiness!
        //         </motion.div>
        //     </div>
        // </section>
    );
}
