import '../styles/layout.scss';
import MainSectionIntro from '../components/Main/MainSectionIntro';
import MainIntroText from '../components/Main/MainIntroText';
import MainSectionAbout from '../components/Main/MainSectionAbout';
import MainSectionWork from '../components/Main/MainSectionWork';
import MainSectionInsight from '../components/Main/MainSectionInsight';
import MainSectionRecruit from '../components/Main/MainSectionRecruit';
import React from 'react';
import { Outlet } from 'react-router-dom';

export default function MainPage() {

    return (
        <div className="MainPage">
            <MainSectionIntro />
            <MainIntroText />
            <MainSectionAbout />
            <MainSectionWork />
            <MainSectionInsight />
            <MainSectionRecruit />
            <Outlet/>
        </div>    
    );
}
