import Typography from '../Typography';
import { motion } from 'framer-motion';
import { useState } from 'react';

export default function ContactSectionEntry() {
    const [transitionEnd, setTransitionEnd] = useState(false);
    return (
        <section className="ContactSectionEntry">
            <div className="contact-entry-bg">
                <motion.img
                    animate={{
                        opacity: [0, 1]
                    }}
                    transition={{
                        duration: 1,
                        delay: 0.5
                    }}
                    onAnimationStart={() => {
                        setTimeout(() => {
                            setTransitionEnd(true);
                        }, 500);
                    }}
                    src={process.env.PUBLIC_URL + '/image/mobile/img_contact_entry.png'}></motion.img>
            </div>
            {transitionEnd && (
                <div className="contact-entry-text">
                    <Typography className="contact" custom={1} txt="contact" />
                </div>
            )}
        </section>
    );
}
