import { useContext, useRef, useEffect } from 'react';
import {PrivacyContext,ContactContext} from './Mobile';

export default function Privacy(){
    const contactContext = useContext(ContactContext);
    const privacyContext = useContext(PrivacyContext);
    const privacyRef = useRef();

    const closeModal = (e) => {
        if(e.target.contains(privacyRef.current)){
            if(privacyContext.state.privacyState) {
                privacyContext.actions.setPrivacyOn(false);
                privacyContext.actions.setUpZindex(false);                
            }
        }
    }
    
    useEffect(() => {
        if(privacyContext.state.privacyState){
            privacyRef.current.style.display = 'block';

            privacyRef.current.animate([
                {
                    transform:'translate3d(0,100%,0)'
                },
                {
                    transform:'translate3d(0,0%,0)'
                },
            ],{
                duration: 400,
                fill: 'forwards',
                easing: 'ease-in-out'
            })
        }else{
            privacyRef.current.animate([
                {
                    transform:'translate3d(0,0%,0)'
                },
                {
                    transform:'translate3d(0,100%,0)'
                },
            ],{
                duration: 400,
                fill: 'forwards',
                easing: 'ease-in-out'
            });
            setTimeout(() => {
                privacyRef.current.style.display = 'none';
            },450)
        }
        window.addEventListener('click' ,closeModal);
        return() => {
            window.removeEventListener('click' ,closeModal);
        }
    },[privacyContext.state.privacyState])

    useEffect(() => {
        if (privacyContext.state.privacyState) {
            document.querySelector("html").classList.add("lock");
        } else {
            document.querySelector("html").classList.remove("lock");
        }
    },[privacyContext.state.privacyState]);
    return(
        <div className={`Privacy ${privacyContext.state.zIndexState ? 'up-index' : ''}`} ref={privacyRef}>
            <div className='privacy-container'>
                <header className="popup-header">
                    <button className="close-btn"
                    onClick={() => {
                        privacyContext.actions.setPrivacyOn(false);
                        privacyContext.actions.setUpZindex(false);
                    }}
                    ></button>
                    <p>PRIVACY POLICY</p>
                </header>
                <div className="privacy-main">
                <p className='mb-5'>
                앱노트(이하 “회사"라 함)는 이용자(이하 “정보주체”)의 개인정보를 중요시하며, 개인정보보호법을 준수하고 있습니다. 관련 법규 상의 개인 정보보호규정을 준수하고 관련 법률에 의거한 개인정보처리방침을 회사 홈페이지(<a className='link-text' href='https://appknot.com' target='_blank'>https://appknot.com</a>)에 수립 및 공개합니다.
                </p>
                <p>본 개인정보처리방침은 앱노트 홈페이지에서 제공하는 서비스를 포함한 수집된 모든 개인정보에 적용됩니다.</p>
                <div className="list">
                    <h4>1. 개인정보의 처리 목적</h4>
                    <p>[홈페이지 내 프로젝트 문의]</p>
                    <p>
                        - 문의한 프로젝트 내용 관련 연락 및 답변
                    </p>
                </div>
                <div className="list">
                    <h4>2. 수집하는 개인정보의 항목 및 수집 방법</h4>
                    <p>[홈페이지 내 프로젝트 문의]</p>
                    <ul>
                        <li>- 필수 항목 : 이름, 소속된 기업명 및 직책, 전화번호, 이메일 주소</li>
                        <li>- 선택 항목 : 없음</li>
                        <li>- 수집 방법: <button type='button' id="contactBtn" className='link-text'
                        onClick={() => {
                            contactContext.actions.setPopupOn(true);
                        }}
                        >문의 버튼</button>에서 프로젝트 문의글 작성</li>
                    </ul>
                </div>
                <div className="list">
                    <h4>3. 개인정보의 처리 및 보유 기간</h4>
                    <ul>
                        <li>1) 입사지원 등에 관한 기록 : 5 년</li>
                        <li>2) 프로젝트 문의에 관한 기록 : 1 년</li>
                        <li>3) 그 외의 기록 : 3 년</li>
                    </ul>
                </div>
                <div className="list">
                    <h4>4. 개인정보의 파기절차 및 파기방법</h4>
                    <p className='mb-5'>
                    회사는 개인정보의 처리 목적이 달성되었거나 보유기간이 만료되었을 경우, 아래와 같이 복원이 불가능한 방법으로 개인정보를 파기합니다.
                    </p>
                    <ul>
                        <li>1) 전자적 파일 형태인 경우: 복원이 불가능한 방법으로 영구 삭제</li>
                        <li>2) 전자적 파일 외의 기록물, 인쇄물, 서면, 그 밖의 기록매체인 경우: 파쇄 또는 소각</li>
                    </ul>
                </div>
                <div className="list">
                    <h4>5. 개인정보의 안전성 확보 조치에 관한 사항</h4>
                    <p className='mb-5'>
                    회사는 정보주체의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 조치를 취하고 있습니다.
                    </p>
                    <p>[관리적 조치]</p>
                    <ul>
                        <li>- 개인정보에 대한 접근 및 관리에 필요한 절차 등을 마련하여, 정기적인 직원 교육을 통해 본 정책의 준수를 강조하고 있으며, 이와 관련된 문제가 발견될 경우 바로 수정하고 바로 잡을 수 있도록 노력하고 있습니다.</li>
                        <li className='mb-5'>- 개인정보 관리업무를 수행하는 자 및 업무상 개인정보의 처리가 불가피한 자를 제한하고 별도의 교육을 시행하고 있습니다.</li>
                    </ul>
                    <p>[기술적 조치]</p>
                    <ul>
                        <li>
                        - 백신프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다.
                        </li>
                        <li className='mb-5'>- 해킹 등 외부 침입에 대비하여 침입차단시스템을 운영하고 있습니다.</li>
                    </ul>
                    <p>[물리적 조치]</p>
                    <ul>
                        <li>
                        - 지문 인식, 사원증 인증을 통해 제한된 내부 임직원만 본사에 출입할 수 있도록 하고, 외부 인원 방문 시 반드시 인증된 임직원과 동행하고 있습니다.
                        </li>
                        <li> 
                        - 본사에 임직원이 없는 경우 외부에서 침입할 수 없도록 시스템 경비 서비스를 사용하고 있습니다.
                        </li>
                    </ul>
                </div>
                <div className="list">
                    <h4>6. 개인정보의 제3자 제공에 관한 사항</h4>
                    <p className='mb-5'>
                    회사는 수집된 개인정보를 ‘1.개인정보의 처리 목적’에서 고지한 범위 내에서만 사용하며, 동 범위를 초과하여 이용하거나 제3자에게 공개/제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
                    </p>
                    <ul>
                        <li>
                        1) 제3자에게 제공하거나 공유하는 것에 대해 정보주체가 사전에 동의한 경우2) 법령의 규정에 의거하거나, 수사목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
                        </li>
                    </ul>
                </div>
                <div className="list">
                    <h4>7. 정보주체의 권리·의무 및 그 행사방법</h4>
                    <p className='mb-5'>
                    정보주체 및 정보주체의 법정 대리인은 언제든지 등록되어 있는 본인 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다. 정보주체 본인 및 만 14세 미만 아동의 법정 대리인은 회사가 보유하고 있는 개인정보 처리와 관련하여 아래와 같은 권리 및 의무를 가지며 행사 방법은 하기와 같습니다.
                    </p>
                    <ul>
                        <li>
                        1) 정보주체는 회사에 대해 서면, 전화, 전자우편, 팩스를 통해 언제든지 개인정보의 열람·정정·삭제 및 처리 정지를 요구할 수 있으며, 회사는 이에 대해 지체없이 조치할 것입니다.
                        </li>
                        <li>2) 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 해당 개인정보를 이용하거나 제공하지 않습니다.</li>
                        <li>3) 개인정보 열람·정정 등 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우에는 개인정보보호법 시행규칙 별지 제 11호 서식에 따른 위임장을 제출해야 합니다.</li>
                        <li>4) 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해 해서는 안됩니다.</li>
                        <li>5) 행사 방법
                            <ul>
                                <li>- 전자우편 : <a href='mailto:contact@appknot.com' target='_blank' className='link-text'>contact@appknot.com</a></li>
                                <li>- 전화 : <a href='tel:027025527' className='link-text'>02-702-5527</a></li>
                                <li>- FAX : 02-702-5528</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="list">
                    <h4>8. 개인정보 보호책임자</h4>
                    <p className='mb-5'>
                    회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호 책임자/관리자를 지정하고 있습니다.
                    </p>
                    <ul>
                        <li>개인정보 보호 책임자 : 장우용 대표 (<a href='mailto:david@appknot.com' target='_blank' className='link-text'>david@appknot.com</a>)</li>
                        <li>개인정보 보호 관리자 : 오정엽 팀장 (<a href='mailto:armand@appknot.com' target='_blank' className='link-text'>armand@appknot.com</a>)</li>
                    </ul>
                    <p>정보주체는 회사의 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 민원을 개인정보 보호 책임자 및 관리자에게 신고하실 수 있습니다. 회사는 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</p>
                </div>
                <div className="list">
                    <h4>9. 영상정보처리기기 운영·관리 방침</h4>
                    <ul>
                        <li>
                        1) 설치 근거 및 목적 : 본사 내부 보안, 범죄 예방, 시설물 안전, 화재 예방 등을 위한 감시 및 녹화
                        </li>
                        <li>2) 설치 대수 : 총 3대</li>
                        <li>3) 설치 위치 : 본사 외부 출입구, 사무실 내부, 기업부설연구소 내부</li>
                        <li>4) 촬영 범위 : 본사 출입구 및 주요 시설물</li>
                        <li>5) 촬영시간 : 24시간</li>
                        <li>6) 보관기간 : 30일까지 보관</li>
                        <li>7) 관리 책임자 : 박경필 팀장 (<a href='mailto:sky@appknot.com' target='_blank' className='link-text'>sky@appknot.com</a>)</li>
                        <li>8) 영상정보에 대한 접근 권한이 있는 사람 : 개인정보 보호 책임자 및 관리자, 영상정보 관리 책임자</li>
                        <li>9) 영상 정보 보관장소 : 서버실</li>
                        <li>10) 처리방법 : 보관기간 만료 시, 자동 삭제</li>
                        <li>11) 영상정보 확인 방법 및 장소 : 관리책임자에게 사전 요청 후 확인 가능</li>
                        <li>12) 정보주체의 영상정보 열람 등 요구에 대한 조치정보주체는 개인 영상정보에 관하여 열람 또는 존재 확인 및 삭제를 원하는 경우 언제든지 영상정보처리기기 관리책임자에게 요구하실 수 있습니다. 단, 귀하가 촬영된 개인영상정보 및 명백히 정보주체의 급박한 생명, 신체, 재산의 이익을 위하여 필요한 개인영상정보에 한합니다.</li>
                        <li>13) 영상정보 보호를 위한 안전성 확보 조치
                            <ul>
                                <li>- 내부관리계획 수립</li>
                                <li>- 접근통제 및 권한제한</li>
                                <li>- 영상정보의 안전한 저장</li>
                                <li>- 처리기록 보관</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="list">
                    <h4>10. 개인정보 처리방침 변경 사항</h4>
                    <p>
                    본 『개인정보 처리방침』은 2023년 1월 10일 개정 및 시행되었습니다.
                    </p>
                    <ul>
                        <li>- 개인정보 처리방침</li>
                        <li> 버전번호: v1.1</li>
                    </ul>
                </div>
            </div>
            </div>
        </div>
    )
}
