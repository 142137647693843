import { useState, useEffect, useRef, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Typography from '../Typography';
import { AnimatePresence, motion } from 'framer-motion';
import WorkDetail from '../../pages/WorkDetail';
import { GnbContext } from '../Pc';

export default function WorkEntry() {
    const transitionRef = useRef();
    const [isEnded, setEnded] = useState(false);
    const [isOn, setOn] = useState(false);
    const [readyShowDetail, setReadyShowDetail] = useState(false);
    const { project } = useParams();
    const title = 'OUR WORK'.split('');
    const projectListLen = document.querySelectorAll('.thumb-list').length;
    useEffect(() => {
        if (project) {
            // 1. display none -> block
            transitionRef.current.style.display = 'block';
            // 2. opacity 0 -> 1
            setTimeout(() => {
                transitionRef.current.style.opacity = 1;
            }, 100);
            // 3. scroll to top
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 300);
            setTimeout(() => {
                setReadyShowDetail(true);
            }, 350);
        } else {
            setReadyShowDetail(false);
            transitionRef.current.style.display = 'none';
            transitionRef.current.style.opacity = 0;
        }
    }, [project]);
    const gnbContext = useContext(GnbContext);
    useEffect(() => {
        if (!readyShowDetail) {
            gnbContext.actions.setGnbColor('black');
        }
    }, [readyShowDetail]);

    return (
        <>
            <section className={`WorkEntry ${isOn ? 'on' : ''}`}>
                <div className="transition" ref={transitionRef}></div>

                <div className={`msg-wrap`}>
                    {isEnded && (
                        <div className={`small-msg`}>
                            <div className="flex-column text-en">
                                <Typography custom={1} txt="let me show you our work " />
                                <Typography custom={2} txt="from now on!" />
                            </div>
                            <div className="text-ko flex-column">
                                <Typography custom={3} className="right" txt="준비됐나요?" />
                                <Typography custom={4} txt="우리의 특별하고 혁신적인 작업물을" />
                                <Typography custom={5} className="last" txt=" 보여드릴게요!" />
                            </div>
                        </div>
                    )}
                </div>

                <div className={`title-box`}>
                    <h2 className="title">
                        <motion.div
                            className="word"
                            viewport={{ once: true }}
                            whileInView="visible"
                            initial="hidden">
                            {title.map((el, idx) => {
                                return (
                                    <motion.span
                                        key={idx}
                                        variants={{
                                            hidden: {
                                                opacity: 0,
                                                y: '-100%',
                                                transition: {
                                                    duration: 0.4,
                                                    delay: 1
                                                }
                                            },
                                            visible: {
                                                opacity: 1,
                                                y: '0%',
                                                transition: {
                                                    duration: 0.6,
                                                    delay: idx * 0.05,
                                                    ease: 'linear'
                                                }
                                            }
                                        }}
                                        onAnimationComplete={() => {
                                            if (idx == title.length - 1) {
                                                setTimeout(() => {
                                                    setOn(true);
                                                    setEnded(true);
                                                }, 100);
                                            }
                                        }}>
                                        {el == ' ' ? <>&nbsp;</> : el}
                                    </motion.span>
                                );
                            })}
                        </motion.div>
                        <div className={`project-lenght ${isEnded && 'show'}`}>
                            <span>{projectListLen}</span>
                        </div>
                    </h2>
                </div>

                <div className="thumb-box">
                    <div className="thumb-box-container">
                        <ul className={`thumb-wrap top-wrap`}>
                            <ThumbList
                                transitionRef={transitionRef}
                                class={'top-box first'}
                                animation={false}
                                img={
                                    process.env.PUBLIC_URL + '/image/img_work_thumb_shinsegae.webp'
                                }
                                name={'SHINSEGAE SHOPPING APP'}
                                nameEn={'Shinsegae live shopping'}
                                project={'shinsegae'}
                            />
                            <ThumbList
                                transitionRef={transitionRef}
                                class={'top-box first'}
                                animation={false}
                                img={
                                    process.env.PUBLIC_URL + '/image/img_work_thumb_archiveat.webp'
                                }
                                name={'ARCHIVEAT'}
                                nameEn={'Public Kitchen'}
                                project={'archiveat'}
                            />
                            <ThumbList
                                transitionRef={transitionRef}
                                animation={false}
                                img={
                                    process.env.PUBLIC_URL +
                                    '/image/img_work_thumb_metapassport.webp'
                                }
                                name={'META PASSPORT'}
                                nameEn={'SM Brand Marketing'}
                                project={'metaPassport'}
                            />
                            <ThumbList
                                transitionRef={transitionRef}
                                img={
                                    process.env.PUBLIC_URL + '/image/img_work_thumb_kiarental.webp'
                                }
                                name={'KIA Rental'}
                                nameEn={'KIA Motors Corporation'}
                                project={'kiaRental'}
                            />
                        </ul>
                        <ul className={`thumb-wrap bottom-wrap`}>
                            <ThumbList
                                transitionRef={transitionRef}
                                custom={1}
                                img={process.env.PUBLIC_URL + '/image/img_work_thumb_ddb.webp'}
                                name={'Daldabang'}
                                nameEn={'Yuhan Kimberly'}
                                project={'ddb'}
                            />
                            <ThumbList
                                transitionRef={transitionRef}
                                img={process.env.PUBLIC_URL + '/image/img_work_thumb_bentley.webp'}
                                name={'Bentley Membership'}
                                nameEn={'Bentley Seoul'}
                                project={'bentley'}
                            />
                            <ThumbList
                                transitionRef={transitionRef}
                                custom={1}
                                img={process.env.PUBLIC_URL + '/image/img_work_thumb_ekolon.webp'}
                                name={'e-kolon membership'}
                                nameEn={'Kolon Industries'}
                                project={'ekolon'}
                            />

                            <ThumbList
                                transitionRef={transitionRef}
                                img={process.env.PUBLIC_URL + '/image/img_work_thumb_lexus.webp'}
                                name={'LEXUS LOUNGE'}
                                nameEn={'LEXUS Korea'}
                                project={'lexus'}
                            />
                            <ThumbList
                                transitionRef={transitionRef}
                                custom={1}
                                img={process.env.PUBLIC_URL + '/image/img_work_thumb_picks.webp'}
                                name={'picks'}
                                nameEn={'Weathernews'}
                                project={'picks'}
                            />
                            <ThumbList
                                transitionRef={transitionRef}
                                img={
                                    process.env.PUBLIC_URL + '/image/img_work_thumb_stylerpick.webp'
                                }
                                name={'주부생활'}
                                nameEn={'THE BOOK COMPANY'}
                                project={'stylerpick'}
                            />
                            <ThumbList
                                transitionRef={transitionRef}
                                img={process.env.PUBLIC_URL + '/image/img_work_thumb_toyota.webp'}
                                name={'Showroom & Workshop'}
                                nameEn={'toyota'}
                                project={'toyota'}
                            />
                            <ThumbList
                                transitionRef={transitionRef}
                                custom={1}
                                img={process.env.PUBLIC_URL + '/image/img_work_thumb_wego.webp'}
                                name={'Tango&go'}
                                nameEn={'wego'}
                                project={'wego'}
                            />
                        </ul>
                    </div>
                </div>
            </section>
            {project && readyShowDetail && (
                <>
                    <AnimatePresence>
                        <motion.div
                            className="WorkDetail"
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    duration: 0.2
                                }
                            }}
                            exit={{ opacity: 0 }}>
                            <WorkDetail project={project} />
                        </motion.div>
                    </AnimatePresence>
                </>
            )}
        </>
    );
}

function ThumbList(props) {
    return (
        <motion.li
            initial="hidden"
            whileInView={props.animation === false ? 'initial' : 'visible'}
            viewport={{ once: true }}
            className={`thumb-list ${props.class ? props.class : ''}`}>
            <Link to={`/work/${props.project}`}>
                <motion.div
                    key="animation-on-state"
                    custom={props.custom}
                    variants={{
                        initial: {
                            opacity: 1,
                            y: '0%',
                            transition: {
                                duration: 0
                            }
                        },
                        hidden: {
                            opacity: 0,
                            y: '100%',
                            transition: {
                                duration: 0
                            }
                        },
                        visible: (custom = 0) => ({
                            opacity: 1,
                            y: '0%',
                            transition: {
                                duration: 0.8,
                                delay: custom * 0.6
                            }
                        })
                    }}>
                    <div>
                        <div className="img-wrap">
                            <img src={process.env.PUBLIC_URL + props.img} alt="" />
                        </div>

                        <div className="desc-wrap">
                            <h3 className="work-name">{props.name}</h3>
                            <h4 className="work-name-en">{props.nameEn}</h4>
                        </div>
                    </div>
                </motion.div>
            </Link>
        </motion.li>
    );
}
