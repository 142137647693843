import {
    useScroll,
    useInView,
    animate,
    useTransform,
    motion,
    useMotionValueEvent
} from 'framer-motion';
import { useEffect, useRef, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ContactContext } from '../Mobile';
export default function ContactSectionFinish() {
    const contactContext = useContext(ContactContext);
    const viewRef = useRef();
    const textRef = useRef();
    const imgRef = useRef();
    const bgClipRef = useRef();
    const isInViewImg = useInView(imgRef);
    const [newClip, setClip] = useState(30);
    const isInViewText = useInView(textRef, { once: true }, { amout: 1 });
    const [action, setAction] = useState(false);

    const { scrollYProgress } = useScroll({
        target: viewRef, 
        offset: ["start start", "end end"]
    });
    const sectionRefScroll = useScroll({
        target: viewRef,
        offset: ['50vh', '100vh']
    });


    const clip = useTransform(scrollYProgress, [0.77, 0.99], [25.8974358974, 14/390*100]); //전체 기준 시작점을 느리게 설정
    const bgClip = useTransform(sectionRefScroll.scrollYProgress, [0, 1], ["10%", "100%"]);

    useMotionValueEvent(scrollYProgress, 'change', (latest) => {
        if (isInViewImg) {
            setClip(clip.current);
        } else {
            setClip(30);
        }
    });

    useEffect(() => {
        if (isInViewText) {
            setAction(true);
        }
    }, [isInViewText]);

    useMotionValueEvent(bgClip, 'change', (v) => {
        if (v == '100%') {
            viewRef.current.style.clipPath = 'none';
        } else {
            viewRef.current.style.clipPath =
                'circle(' + bgClip.current + ' at 50% 120vh)';
        }
    });

    return (
        <section className="ContactSectionFinish" ref={viewRef}>
            <div className={`finish-container`} ref={bgClipRef}>
                <div className="finish-text-box" ref={textRef}>
                    <strong>
                        complete
                        <br />
                        the
                        <br />
                        new experiece
                    </strong>
                    <div className="tag instagram">
                        <a
                            href="https://www.instagram.com/appknot.official/"
                            target="_blank"
                            className={`${action && 'action'}`}>
                            instagram
                        </a>
                    </div>
                    <div className="tag contact" onClick={() => {contactContext.actions.setPopupOn(true)}}>
                        <span className={`${action && 'action'}`}>contact</span>
                    </div>
                    <div className="tag youtube">
                        <a
                            target="_blank"
                            href="https://www.youtube.com/user/appknot1"
                            className={`${action && 'action'}`}>
                            youtube
                        </a>
                    </div>
                </div>
                <motion.div
                    className="finish-member-img"
                    style={{
                        clipPath: 'inset(0%' + newClip + '%)'
                    }}
                    transition={{
                        ease: 'easeInOut'
                    }}>
                    <img
                        src={process.env.PUBLIC_URL + '/image/mobile/img_contact_member.png'}
                        ref={imgRef}></img>
                </motion.div>
                <div className="info-box">
                    <address>서울특별시 성동구 아차산로 126, 더리브세종타워 613호 앱노트</address>
                    <div className="tel">
                        <a href="tel:02-702-5527">T (+82) 2 702 5527</a> / (+82) 2 702 5528
                    </div>
                </div>
            </div>
        </section>
    );
}
