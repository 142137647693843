import {cubicBezier, motion} from "framer-motion";
export default function SolutionSectionFinish() {
    return (
        <div className="SolutionSectionFinish">
            <Solution name="handly" img={process.env.PUBLIC_URL + '/image/img_solution_handly.webp'} href="https://handly.team/product/" target="_blank">
                <RollingMsg className="rolling-msg-01" msg="스케줄 관리의 새로운 방법" />
                <RollingMsg className="rolling-msg-02" msg="go to website" />
            </Solution>

            <Solution right={true} name="popee" img={process.env.PUBLIC_URL + '/image/img_solution_popee.webp'} href={process.env.PUBLIC_URL + "/pdf/popee.pdf"} download>
                <RollingMsg className="rolling-msg-01" msg="성공적인 페이퍼리스 팝업스토어 솔루션" />
                <RollingMsg className="rolling-msg-02" msg="view proposal" />
            </Solution>

            <Solution name="mentor" img={process.env.PUBLIC_URL + '/image/img_solution_mentor.webp'} href={process.env.PUBLIC_URL + "/site/mentor.html"} target="_blank">
                <RollingMsg className="rolling-msg-01" msg="쉽고 빠른 콘텐츠 라이브러리 솔루션" />
                <RollingMsg className="rolling-msg-02" msg="view proposal" />
            </Solution>
        </div>
    )
} 

function Solution({right, name, children, img, ...props}) {
    const transition = {
        duration: 0.75,
        ease: cubicBezier(0, 0.55, 0.45, 1)
    }
    const textVariantsLeft = {
        rest: {
            x: right ? "5.5rem" : "-5.5rem",
            transition: {
                duration: 0.4
            }
        },
        hover: {
            x: "0rem",
            transition: transition
        }
    }
    const imgVariants = {
        rest: {
            x: right ? "-100%" : "100%",
            transition: {
                duration: 0.4
            }
        },
        hover: {
            x: "0%",
            transition: transition
        }
    }

    return(
    <motion.div className={"solution " + name} initial="rest" whileHover="hover" animate="rest">
        {
            children
        }

        <a {...props}>
            {
            !right &&
            <div className="img">
                <motion.img variants={imgVariants} src={img} />
            </div>
            }
            <motion.span variants={textVariantsLeft}>
                {name}
            </motion.span>
            {
            right &&
            <div className="img">
                <motion.img variants={imgVariants} src={img} />
            </div>
            }
        </a>
    </motion.div>
    )
}

function RollingMsg({className, msg}) {
    return (
        <motion.div className={`rolling-msg ${className ? className : ""}`} variants={{
            rest: {
                height: 0,
                transition: {
                    duration: 0.3,
                    type: "tween",
                    ease: "easeIn"
                }
            },
            hover: {
                height: 33,
                transition: {
                    duration: 0.3,
                    ease: "easeOut"
                }
            }
        }}>
            <div className="animation-container">
                <div className="msg-container">
                    <span>
                        {msg}
                    </span>
                </div>
                <div className="msg-container">
                    <span>
                        {msg}
                    </span>
                </div>
            </div>
        </motion.div>
    )
}
