import Typography from '../Typography';
import SolutionIntroduceIntro from './SolutionIntroduceIntro';
import SolutionInfoIntro from './SolutionInfoIntro';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    motion,
    useInView,
    useMotionValueEvent,
    useScroll,
    useSpring,
    useTransform
} from 'framer-motion';
import 'pathseg';
import Matter, { Common, Svg, Vertices } from 'matter-js';
import { debounce } from 'lodash';
import { GnbContext } from '../Pc';
export default function SolutionSectionPopee() {
    const unionVariants = {
        hidden: {
            y: '100%',
            transition: {
                duration: 0.3,
                delay: 0
            }
        },
        visible: {
            y: '0%',
            transition: {
                duration: 0.8,
                delay: 0.2,
                ease: 'easeInOut'
            }
        }
    };
    const textArr = ['Paperless'.split(''), 'Pop-up Solution'.split('')];
    const scrollRef = useRef();
    const sectionRef = useRef();
    const canvasRef = useRef();
    const section03Ref = useRef();

    const matterRef01 = useRef();
    const matterRef02 = useRef();
    const matterRef03 = useRef();

    const [scrollRange, setScrollRange] = useState(0);
    const [viewportW, setViewportW] = useState(0);
    const [isScroll, setIsScroll] = useState(false);
    const { scrollYProgress, scrollY } = useScroll({
        target: sectionRef
    });
    const isInViewMatter = useInView(section03Ref, { once: false });
    const gnbContext = useContext(GnbContext);
    const sectionInView = useInView(sectionRef);

    useMotionValueEvent(scrollY, 'change', (val) => {
        if (sectionInView) {
            if (scrollYProgress.current > 0) {
                gnbContext.actions.setGnbColor('white');
            } else {
                gnbContext.actions.setGnbColor('black');
            }
        }
    });

    const transform = useTransform(scrollYProgress, [0.1, 0.9], [0, -scrollRange + viewportW + 80]);
    const spring = useSpring(transform, {
        damping: 15,
        mass: 0.27,
        stiffness: 70
    });
    useEffect(() => {
        
        
        const onResize = () => {
            scrollRef && setScrollRange(scrollRef.current.scrollWidth);
            setViewportW(window.innerWidth);
        }

        onResize();
        
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);

    useEffect(() => {
        let Engine = Matter.Engine;
        let Render = Matter.Render;
        let World = Matter.World;
        let Bodies = Matter.Bodies;
        let Mouse = Matter.Mouse;
        let MouseConstraint = Matter.MouseConstraint;
        let Composite = Matter.Composite;
        if (isInViewMatter) {
            let engine = Engine.create();
            engine.gravity.scale = 0.0005;

            let render = Render.create({
                engine: engine,
                canvas: canvasRef.current,
                options: {
                    background: '#fff',
                    width: window.innerWidth,
                    height: window.innerHeight,
                    wireframes: false
                }
            });
            Common.setDecomp(require('poly-decomp'));

            const staticBottom = Bodies.rectangle(
                window.innerWidth / 2,
                window.innerHeight - 5,
                window.innerWidth - 10,
                5,
                {
                    isStatic: true,
                    restitution: 0,
                    friction: 0.5,
                    render: {
                        fillStyle: 'none'
                    }
                }
            );
            const staticRight = Bodies.rectangle(
                getWidth(1771),
                window.innerHeight / 2,
                5,
                window.innerHeight * 2,
                {
                    isStatic: true,
                    restitution: 0,
                    friction: 0.5,
                    render: {
                        fillStyle: 'none'
                    }
                }
            );
            const staticLeft = Bodies.rectangle(
                0,
                window.innerHeight / 2,
                5,
                window.innerHeight * 2,
                {
                    isStatic: true,
                    restitution: 0,
                    friction: 0.5,
                    render: {
                        fillStyle: 'none'
                    }
                }
            );

            function getWidth(width) {
                return window.innerWidth * (width / 1920);
            }
            function getHeight(height) {
                return window.innerHeight * (height / 1080);
            }

            const world = engine.world;
            const boxes = [
                {
                    w: 134,
                    h: 24,
                    elem: matterRef01.current,
                    body: Bodies.rectangle(getWidth(553), -100, getWidth(210), getHeight(149), {
                        chamfer: { radius: [60] },
                        restitution: 0,
                        friction: 0.5,
                        render: {
                            fillStyle: '#9E9EEB'
                        }
                    })
                },
                {
                    w: 160,
                    h: 24,
                    elem: matterRef02.current,
                    body: Bodies.rectangle(getWidth(839), -100, getWidth(272), getHeight(136), {
                        chamfer: { radius: [30] },
                        restitution: 0,
                        friction: 0.5,
                        render: {
                            fillStyle: '#A1C0F5'
                        }
                    })
                },
                {
                    w: 190,
                    h: 89,
                    elem: matterRef03.current,
                    body: Bodies.rectangle(getWidth(1094), -300, getWidth(250), getHeight(312), {
                        chamfer: { radius: [105] },
                        restitution: 0,
                        friction: 0.5,
                        render: {
                            fillStyle: '#FFD130'
                        }
                    })
                }
            ];

            // (S) svg
            const select = function (root, selector) {
                return Array.prototype.slice.call(root.querySelectorAll(selector));
            };

            const loadSvg = function (url) {
                return fetch(url)
                    .then(function (response) {
                        return response.text();
                    })
                    .then(function (raw) {
                        return new window.DOMParser().parseFromString(raw, 'image/svg+xml');
                    });
            };

            World.add(engine.world, [staticBottom, staticRight, staticLeft]);
            const elemArr = document.querySelectorAll('.rect-text');
            let itemIdx = 0;
            [
                process.env.PUBLIC_URL + '/image/img_solution_svg_01.svg',
                process.env.PUBLIC_URL + '/image/img_solution_svg_02.svg',
                process.env.PUBLIC_URL + '/image/img_solution_svg_03.svg',
                process.env.PUBLIC_URL + '/image/img_solution_svg_04.svg',
                process.env.PUBLIC_URL + '/image/img_solution_svg_05.svg'
            ].forEach(function (path, i, array) {
                loadSvg(path).then(function (root) {
                    itemIdx++;
                    const color = ['#E6393B', '#FF60AE', '#BCCF82', '#339F30', '#5275D3'];

                    const vertexSets = select(root, 'path').map(function (path) {
                        return Vertices.scale(Svg.pathToVertices(path, 30), 1, 1);
                    });

                    boxes.push({
                        w: 160,
                        h: 24,
                        elem: elemArr[i],
                        body: Bodies.fromVertices(
                            100 + i * 150,
                            i * 50,
                            vertexSets,
                            {
                                restitution: 0,
                                friction: 0.5,
                                render: {
                                    fillStyle: color[i],
                                    strokeStyle: color[i],
                                    lineWidth: 1
                                }
                            },
                            true
                        )
                    });

                    if (itemIdx == array.length) {
                        boxes.forEach((el, idx) => {
                            Composite.add(world, el.body);
                        });
                    }
                });
            });
            // (E) svg

            (function rerender() {
                boxes.forEach((el) => {
                    el.render = function () {
                        const { x, y } = this.body.position;
                        this.elem.style.top = `${y}px`;
                        this.elem.style.left = `${x}px`;
                        // this.elem.style.transform = `translate(${x}px,${y}px)`;
                    };
                    el.render();
                });
                Matter.Engine.update(engine);
                requestAnimationFrame(rerender);
            })();

            const mouse = Mouse.create(render.canvas);
            const mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                constraint: {
                    stiffness: 0.2,
                    restitution: 0,
                    friction: 0.5,
                    render: {
                        visible: false
                    }
                }
            });
            mouseConstraint.mouse.element.removeEventListener(
                'mousewheel',
                mouseConstraint.mouse.mousewheel
            );
            mouseConstraint.mouse.element.removeEventListener(
                'DOMMouseScroll',
                mouseConstraint.mouse.mousewheel
            );

            Composite.add(engine.world, mouseConstraint);
            render.mouse = mouse;

            Matter.Runner.run(engine);
            Render.run(render);
            window.addEventListener(
                'resize',
                debounce(() => {
                    canvasRef.width = window.innerWidth;
                    canvasRef.height = window.innerHeight;
                    render.bounds.max.x = window.innerWidth;
                    render.bounds.max.y = window.innerHeight;
                    render.options.width = window.innerWidth;
                    render.options.height = window.innerHeight;
                    render.canvas.width = window.innerWidth;
                    render.canvas.height = window.innerHeight;
                }, 250)
            );
        }
    }, [isInViewMatter]);

    let prevVal = 0;
    useMotionValueEvent(scrollYProgress, 'change', (latest) => {
        setIsScroll(true);

        setTimeout(() => {
            if (prevVal == latest) {
                setIsScroll(false);
            }
        }, 1200);
        prevVal = latest;
    });
    return (
        <section className="SolutionSectionPopee" ref={sectionRef} style={{ height: scrollRange }}>
            <div className="container">
                <motion.div className="scroll-wrap" ref={scrollRef} style={{ x: spring }}>
                    {/* section01 */}
                    <section className="scroll-section section01">
                        <div className="sticky">
                            <div className="bg">
                                <img
                                    src={
                                        process.env.PUBLIC_URL + '/image/img_bg_solution_popee.webp'
                                    }
                                />
                            </div>
                        </div>
                        <SolutionIntroduceIntro
                            num="02"
                            msg="성공적인 페이퍼리스 팝업스토어 솔루션"
                            name="POPEE"
                        />
                    </section>

                    {/* section02 */}
                    <motion.section
                        className="scroll-section section02"
                        viewport={{ once: false }}
                        whileInView="visible"
                        initial="hidden"
                        animate="rest">
                        <motion.div
                            className="bg-img"
                            variants={{
                                hidden: {
                                    width: '0vw',
                                    transition: {
                                        duration: 1,
                                        delay: 0
                                    }
                                },
                                visible: {
                                    width: '100vw',
                                    transition: {
                                        duration: 3,
                                        delay: 0.2,
                                        ease: 'easeInOut'
                                    }
                                }
                            }}>
                            <img
                                src={process.env.PUBLIC_URL + '/image/img_solution_popee_line.webp'}
                            />
                        </motion.div>
                        <div className="small-msg text-box">
                            <div className="text-box-top flex-column">
                                <Typography txt="POPEE는 성공적인 팝업스토어를 위한" />
                                <Typography txt="디지털 콘텐츠 제작 을 제안합니다." />
                            </div>
                            <div className="text-box-bottom flex-column">
                                <Typography txt="팝업 스토어 운영 시, 퀄리티 있는 디지털 콘텐츠는 고객들로부터 하여금" />
                                <Typography txt="브랜드의 긍정적인 이미지를 재고하며, 정보 수집을 위한 필수 요소가 됩니다." />
                            </div>
                        </div>
                        <div className="popee-info-box">
                            <ul>
                                <motion.li variants={unionVariants}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/image/img_solution_union_01.webp'
                                        }
                                        alt="참여형 인터렉션"
                                    />
                                </motion.li>
                                <motion.li variants={unionVariants}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/image/img_solution_union_02.webp'
                                        }
                                        alt="정보 전달 및 수집"
                                    />
                                </motion.li>
                            </ul>
                        </div>
                        <motion.div
                            className="big-title"
                            variants={{
                                hidden: {
                                    opacity: 0,
                                    transition: {
                                        duration: 1,
                                        delay: 0
                                    }
                                },
                                visible: {
                                    opacity: 1,
                                    transition: {
                                        duration: 1,
                                        delay: 0.5,
                                        ease: 'easeIn'
                                    }
                                }
                            }}>
                            <img
                                src={process.env.PUBLIC_URL + '/image/img_solution_big_text.webp'}
                                alt=""
                            />
                        </motion.div>
                        <div className={`painter-bg${isScroll ? ' scroll' : ''}`}>
                            <img
                                src={process.env.PUBLIC_URL + '/image/img_solution_bg_paper.webp'}
                            />
                            <img
                                src={process.env.PUBLIC_URL + '/image/img_solution_bg_paper.webp'}
                            />
                        </div>
                    </motion.section>

                    <section
                        className={`scroll-section section03${isScroll ? ' scroll' : ''}`}
                        ref={section03Ref}>
                        <canvas id="canvas" ref={canvasRef} />
                        <div className="rect-text">
                            <span>이미지 시퀀스</span>
                        </div>
                        <div className="rect-text">
                            <span>비디오</span>
                        </div>
                        <div className="rect-text">
                            <span>트위터 연동</span>
                        </div>
                        <div className="rect-text">
                            <span>팝업 윈도우</span>
                        </div>
                        <div className="rect-text">
                            <span>설문조사</span>
                        </div>
                        <div className="rect-text" ref={matterRef01}>
                            <span>고품질 오디오</span>
                        </div>
                        <div className="rect-text" ref={matterRef02}>
                            <span>AR/VR 3D 콘텐츠</span>
                        </div>
                        <div className="rect-text" ref={matterRef03}>
                            <span>스크롤 콘텐츠</span>
                            <span>슬라이드 쇼</span>
                        </div>

                        <div className="text-container">
                            <strong>
                                POPEE는 고객의 눈을 사로잡는
                                <br />
                                다양한 효과를 빠르게 제공합니다.
                            </strong>
                            <p>
                                POPEE가 제공하는 인터렉티브 디지털 브로셔는 기존 종이 브로셔
                                <br />및 PDF 브로셔에서 보여주지 못하는 애니메이션 효과 및
                                <br />
                                멀티미디어 기능을 통해 다양한 정보를 사용자에게 제공합니다.
                            </p>
                        </div>
                        <div className={`painter-bg${isScroll ? ' scroll' : ''}`}>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/image/img_solution_bg_paper_right.webp'
                                }
                            />
                            {/* <img src={process.env.PUBLIC_URL + '/image/img_solution_bg_paper_right.webp'} /> */}
                        </div>
                    </section>

                    <motion.section
                        className="scroll-section section04"
                        viewport={{ once: false }}
                        whileInView="visible"
                        initial="hidden"
                        animate="rest">
                        <div className="contents">
                            <SolutionInfoIntro
                                text={textArr}
                                img={process.env.PUBLIC_URL + '/image/img_solution_popee.webp'}
                            />
                            <div className="solution-introduction">
                                <div className="simple-text">
                                    방문자의 눈길을 사로잡는 애니메이션 효과를
                                    <br />
                                    만날 수 있는 팝업스토어를 위한
                                    <br />
                                    디지털 퍼블리싱 솔루션 POPEE입니다.
                                </div>

                                <div className="detail-text">
                                    <p>
                                        팝업스토어를 위한 디지털 퍼블리싱 솔루션 POPEE는 이미지,
                                        동영상, 소리 등의 기존의 효과뿐 아니라
                                        <br />
                                        AR, VR 기술을 접목하여 팝업스토어에 방문한 고객의 눈길을
                                        잡을 수 있는 태블릿용 솔루션입니다.
                                        <br />
                                        쉽고 간편한 콘텐츠 제작은 물론 방문자가 어떤 페이지에서
                                        얼마만큼의 시간 동안 머물렀는지 파악할 수 있는
                                        <br />
                                        통계까지 제공합니다. 단기간에 임팩트 있는 인상을 남겨야 하는
                                        팝업스토어. 태블릿 대여까지
                                        <br />
                                        선택 사항에 따라 파일 공유 및 추출이 차단할 수 있으며
                                        암호화된 형태로
                                        <br />
                                        합리적인 솔루션 비용 안에서 모두 제공하는 POPEE를
                                        만나보세요.
                                    </p>

                                    <a download="appknot_popee.pdf" href={process.env.PUBLIC_URL + "/pdf/popee.pdf"} className="btn-link" >
                                        POPEE 보러가기
                                    </a>
                                </div>
                            </div>
                            <motion.div
                                className="section04-bg"
                                variants={{
                                    hidden: {
                                        opacity: 0,
                                        transition: {
                                            duration: 1,
                                            delay: 0
                                        }
                                    },
                                    visible: {
                                        opacity: 1,
                                        transition: {
                                            duration: 1,
                                            delay: 0.5,
                                            ease: 'easeIn'
                                        }
                                    }
                                }}>
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/image/img_solution_popee_section04_bg.webp'
                                    }></img>
                            </motion.div>
                        </div>
                    </motion.section>
                </motion.div>
            </div>
        </section>
    );
}
