import { workDetailPath } from '../../pages/WorkDetail';
import Fadeup from '../Fadeup';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';

export default function Wego() {
    const wdp = workDetailPath();
    const wp = wdp + 'wego/';

    return (
        <div className="work-detail-wrap wego">
            <div className="project-cover">
                <div className={`cover-img-wrap`}>
                    <img src={wp + 'img_intro.png'} />
                </div>

                <div className="cover-text-wrap">
                    <div className="container">
                        <h2 className="project-type">WE GO</h2>
                        <h4 className="project-name ko">Tango&go</h4>
                        <ul className="project-team">
                            <li className="team-tag">design</li>
                            <li className="team-tag">Project Management</li>
                        </ul>
                        <Fadeup className="project-desc">
                            <p>
                                Tango&go는 누구나 손쉽게 택배, 퀵서비스 등 물류배송 시장에 참여할 수
                                있는 오픈 운송 O2O 플랫폼입니다.
                                <br />
                                <br />
                                스타트업의 특성에 맞게 필수적인 기능만을 MVP(Minimum Viable Product)
                                형식으로 빠르게 구현하는 것을 목표로 두었습니다. 펀딩과 시범사업이
                                목적이었던 프로젝트였기 때문에 빠르게 파일럿을 통해 적용시킬 기능과
                                정식 론칭 후에 필요한 기능을 구분 짓는 것이 중요하다고 생각했습니다.
                                필수적인 기능을 빠르게 구현하면서 타 서비스와 구분되는 Tango&go의
                                특징을 살리기 위해 많은 고민을 했습니다.
                                <br />
                                <br />
                                배송 주문을 넣는 고객 서비스에서는 ‘빠른 배송 기사 매칭‘을 강조하며
                                언제, 어디서나 ‘요청’ 버튼 하나로 물류 작업을 시작할 수 있도록
                                구성하였고, 배송 업무를 하는 라이더 서비스에서는 송 수단에 맞춰
                                빠르고 편리한 작업을 할 수 있도록 상태 값, 아이콘 등 선택요소의
                                직관성을 가장 많이 고려하여 물류창고-허브-고객 사이를 오갈 때 손쉽게
                                노선을 설정하고 정정할 수 있도록 기능을 구축했습니다.
                            </p>
                        </Fadeup>
                    </div>
                </div>
            </div>

            <div className="project-contents">
                <section className="section-01">
                    <div className="img-container">
                        <div className="img-wrap">
                            <Fadeup className="img-01">
                                <img src={wp + 'img_01_01.png'} />
                            </Fadeup>
                            <Fadeup className="img-02">
                                <img src={wp + 'img_01_02.png'} />
                            </Fadeup>
                            <Fadeup className="img-03">
                                <img src={wp + 'img_01_03.png'} />
                            </Fadeup>
                        </div>
                        <div className="img-wrap">
                            <Fadeup className="img-04">
                                <img src={wp + 'img_01_04.png'} />
                            </Fadeup>
                            <Fadeup className="img-05">
                                <img src={wp + 'img_01_05.png'} />
                            </Fadeup>
                            <Fadeup className="img-06">
                                <img src={wp + 'img_01_06.png'} />
                            </Fadeup>
                        </div>
                    </div>

                    <Fadeup className="img-07">
                        <img src={wp + 'img_01_07.png'} />
                    </Fadeup>

                    <div className="img-noti-wrap">
                        <Fadeup className="img-08">
                            <img src={wp + 'img_01_08.png'} />
                        </Fadeup>
                        <Fadeup className="img-09">
                            <img src={wp + 'img_01_09.png'} />
                        </Fadeup>
                        <Fadeup className="img-10">
                            <img src={wp + 'img_01_10.png'} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-02">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_02_01.png'} />
                    </Fadeup>

                    <div className="img-wrap">
                        <Fadeup className="img-02">
                            <img src={wp + 'img_02_02.png'} />
                        </Fadeup>
                        <Fadeup className="img-03">
                            <img src={wp + 'img_02_03.png'} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-03">
                    <div className="img-wrap">
                        <Fadeup className="img-01">
                            <img src={wp + 'img_03_01.png'} />
                        </Fadeup>
                        <Fadeup className="img-02">
                            <img src={wp + 'img_03_02.png'} />
                        </Fadeup>
                        <Fadeup className="img-03">
                            <img src={wp + 'img_03_03.png'} />
                        </Fadeup>
                        <Fadeup className="img-04">
                            <img src={wp + 'img_03_04.png'} />
                        </Fadeup>
                    </div>

                    <Fadeup className="img-05">
                        <img src={wp + 'img_03_05.png'} />
                    </Fadeup>
                </section>
            </div>
        </div>
    );
}
