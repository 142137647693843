import { useEffect, useState } from "react";

export default function useViewport() {
    const [viewportW, setViewportW] = useState(0);
    const [viewportH, setViewportH] = useState(0);

    useEffect(() => {
        const onResize = () => {
            setViewportW(window.innerWidth);
            setViewportH(window.innerHeight);
        };

        onResize();

        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);
    return {
        viewportW: viewportW,
        viewportH: viewportH
    }
}