export default class Util {
    static maxFileSize = 5 * 1024 * 1024; // 5MB
    static allowedFile = ['pdf', 'zip', 'doxc', 'doc'];

    constructor () {

    }

    static autoHypen(number) {
        if(number.length < 1) return '';
        return number.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    }

    static validateEmail(email) {
        const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
        return emailRegEx.test(email) ? true : false;
    }

    static validatePhone(phone) {
        const phoneRegEx = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/;
        return phoneRegEx.test(phone) ? true : false;
    }

    static validateFile(files, maxSize = this.maxFileSize, allowedFile = this.allowedFile) {
        const checkSize = files[0].size <= maxSize ? true : false;
        const fileName = files[0].name;
        const extension = fileName.split('.').pop();
        const checkExt = allowedFile.includes(extension);
        
        let msg = '';
        let isValidate = false;
        
        if(!checkSize && !checkExt){
            isValidate = false;
        }else if(!checkSize){
            isValidate = false;
            msg = '5MB 미만의 파일만 첨부가능합니다.'
        }
        else{
            isValidate = true;
        }

        return [isValidate, msg];
    }
    
}