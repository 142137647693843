import { motion, useAnimationFrame } from 'framer-motion';
import { useRef } from 'react';
export default function SolutionInfoIntro(props) {
    const blockRef = useRef();

    useAnimationFrame((time, delta) => {
        const y = (1 + Math.sin(time / 1000)) * -25;
        const rotate = Math.sin(time / 10000) * 16;
        if (blockRef.current) {
            blockRef.current.style.transform = `translateY(${y}px) rotate(${rotate}deg)`
        }
    })

    const getTitleJsx = () => {
        const result = [];
        props.text.forEach((e, i) => {
            e.forEach((el, idx) => {
                result.push(
                    <motion.div key={"introText" + i + "_" + idx}>
                        <motion.span
                            variants={{
                                hidden: { 
                                    opacity: 0,
                                    y: "20%",
                                    transition: {
                                        duration: 0,
                                        delay: 0
                                    }
                                },
                                visible: { 
                                    opacity: 1,
                                    y: "0%",
                                    transition: {
                                        duration: 0.4, 
                                        delay: idx * 0.02,
                                    },
                                }
                            }}
                            >
                            {el == " " ? <>&nbsp;</> : el}
                        </motion.span>
                    </motion.div>
                )
            })

            result.push(<br key={"br" + i}/>)
        })

        return result;
    }
    return (
    <div className="SolutionInfoIntro">
        <motion.div className="info-wrap" viewport={{ once: true, amount: "all" }} whileInView="visible" initial="hidden" animate="rest">
            <div className="title">
                {getTitleJsx()}
            </div>
            <div className="img" ref={blockRef}>
                <motion.img
                    variants={{
                        hidden: { 
                            opacity: 0,
                            y: "100%",
                            transition: {
                                duration: 0.4,
                                delay: 0
                            }
                        },
                        visible: { 
                            opacity: 1,
                            y: "0%",
                            transition: {
                                duration: 0.7, 
                                delay: 0.7,
                            },
                        }
                    }} src={props.img} />
            </div>
        </motion.div>
    </div>
    )
}