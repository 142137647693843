import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import ContactPopup from './ContactPopup';

export default function Layout() {
    return (
        <div className="Layout">
            <Outlet />
            <Footer />
            <ContactPopup />
        </div>
    );
}
