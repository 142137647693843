import { Link } from "react-router-dom";

export default function WorkList(props) {
    const appEl = document.querySelector(".App");
    const newDiv = document.createElement("div");
    const newSpan = document.createElement("span");
    newDiv.appendChild(newSpan);
    newDiv.classList.add("work-tag");

    function handleMouseOut(e) {
        e.currentTarget.removeEventListener('mousemove', () => {});
        const workTag = document.querySelector(".work-tag");
        if (workTag !== null){
            workTag.classList.remove("active");
            workTag.remove();
        }
    }

    return (
        <li className="WorkList" data-tag={props.dataTag}
        onMouseMove={(e) => {
            const workTag = document.querySelector(".work-tag");
                
            if (workTag === null) {
                newSpan.innerHTML = e.currentTarget.getAttribute("data-tag");
                appEl.append(newDiv);
            } else {
                const mouseY = e.clientY;
                const mouseX = e.clientX;
                
                workTag.classList.add("active");
                workTag.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0) rotate(-11deg)`;
            }
        }}
        onMouseOut={handleMouseOut}
        onMouseLeave={handleMouseOut}
        onClick={handleMouseOut}
        >
            <Link to={"/work/" + props.project}>
                <div className="thumb-nail">
                    <img src={ props.img } />

                    <div className="text-wrap">
                        <div className="title">
                            {props.title}
                        </div>
                        <div className="sub-title">
                            {props.subTitle}
                        </div>
                    </div>
                </div>
            </Link>
        </li>
    )
}