import {motion, useAnimation, useMotionValueEvent, useScroll, useTransform} from "framer-motion";
import { useRef } from "react";
export default function AboutSectionCard() {
    
    const cardVideoRef = useRef();

    const cardVideoRefScroll = useScroll({
        target: cardVideoRef,
        offset: ["start start", "end end"]
    });
    const cardRefScale = useTransform(cardVideoRefScroll.scrollYProgress, [0, 0.5], [0.8421, 1]);
    const cardRefRotate = useTransform(cardVideoRefScroll.scrollYProgress, [0, 0.5], [5, 0]);
    const cardRefRadius = useTransform(cardVideoRefScroll.scrollYProgress, [0, 0.5], ["0.9375rem", "0rem"]);
    const cardRefOp = useTransform(cardVideoRefScroll.scrollYProgress, [0.5, 1], [1, 0.5]);

    const cardTextControls = useAnimation();
    
    useMotionValueEvent(cardRefOp, "change", (v) => {
        if (v.toFixed(1) == 0.5) {
            cardTextControls.start("show");
        }
    })
    return (
    <motion.div className="AboutSectionCard" ref={cardVideoRef} style={{rotate: cardRefRotate, scale: cardRefScale}}>
        <motion.div className="card-wrap" style={{borderRadius: cardRefRadius}}>
            <div className="text-container">
                짠, 어제는 최신기술이었던 것이 오늘은 구닥다리가 되는
                <br/>그런 세상이죠. 우리는 마치 하나처럼, 세상의 발전을 쫒아가며,
                <br/>사용자에게 선사할 최고의 기술을 찾고 있답니다<img src={process.env.PUBLIC_URL + "/image/icn_eyes.webp"}/>
            </div>
            <motion.section className="card-video" style={{opacity: cardRefOp}}>
                <video src={process.env.PUBLIC_URL + "/image/vid_about_card.mp4"} autoPlay playsInline muted loop>
                    <source src={process.env.PUBLIC_URL + "/image/vid_about_card.mp4"} type="video/mp4" />
                </video>
            </motion.section>
        </motion.div>
    </motion.div>
    )
}