import { Link, useNavigate } from "react-router-dom";
import { GnbContext, TransitionContext } from "./Mobile";
import { useContext } from "react";

export default function LinkWithTransition(props) {
    const transitionContext = useContext(TransitionContext);

    const navigate = useNavigate();
    const gnbContext = useContext(GnbContext);
    function delayAndGo(e) {
        e.preventDefault();
        const to = e.currentTarget.getAttribute("href");
        transitionContext.actions.setTransitionComplt(false);

        setTimeout(() => navigate(to), 300);
        setTimeout(() => gnbContext.actions.setOpenMenu(false), 250);
    }
    return (
        <Link to={props.to} onClick={delayAndGo} className={props.className ? props.className : ""}>
            {props.children}
        </Link>
    )
}