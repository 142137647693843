import {useEffect, useRef, useState} from 'react';
import { useMotionValueEvent, useScroll } from 'framer-motion';

export default function MainAwardMark(){
    const lineRef = useRef();
    const markRef = useRef();
    const {scrollY} = useScroll();
    const [markShow, setMarkShow] = useState(true);
    const [lineShow, setLineShow] = useState(false);

    function showMark(){
        setLineShow(false);
        setMarkShow(true);
    }

    useMotionValueEvent(scrollY, 'change',(latest) => {
        if(latest > 120){
            setMarkShow(false);
            setLineShow(true);
        }
    })
    return(
        <div className="MainAwardMark">
            <div className={`award-mark ${markShow ? 'show' : 'hidden'}`} ref={markRef}>
                <img src={process.env.PUBLIC_URL + '/image/img_awardmark.gif'} />
            </div>
            <div className={`award-line ${lineShow ? 'show' : 'hidden'}`} onClick={showMark} ref={lineRef}>
                <p>&nbsp;award&nbsp;award&nbsp;award&nbsp;award&nbsp;award&nbsp;award</p>
                <p>&nbsp;award&nbsp;award&nbsp;award&nbsp;award&nbsp;award&nbsp;award</p>
            </div>
        </div>
    )
}