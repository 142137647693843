import { Route, Routes } from 'react-router-dom';
import Gnb from './Gnb';
import MainPage from '../pages/MainPage';
import AboutPage from '../pages/AboutPage';
import SolutionPage from '../pages/SolutionPage';
import ContactPage from '../pages/ContactPage';
import WorkPage from '../pages/WorkPage';
import { createContext, useState } from 'react';
import ScrollToTop from './ScrollToTop';
import Loading from './Loading';
import Transition from './Transition';

import '../styles/layout.scss';
import '../styles/workDetail.scss';
import GaTracker from '../../GaTracker';
import Privacy from './Privacy';
import NotFound from '../pages/NotFound';
import Layout from './Layout';

export const GnbContext = createContext();
export const TransitionContext = createContext();
export const ContactContext = createContext();
export const PrivacyContext = createContext();
export default function Pc() {
    GaTracker(); // GA
    
    const [gnbColor, setGnbColor] = useState('black');
    const [transitionComplt, setTransitionComplt] = useState(false);
    const [gnbLogo, setGnbLogo] = useState(false);
    const [loading, setLoading] = useState(true);
    const [popupOn, setPopupOn] = useState(false);
    const [privacyOn, setPrivacyOn] = useState(false);
    const [upZindex, setUpZindex] = useState(false);
    const [contactBtnRef, setContactBtnRef] = useState();

    const gnbValue = {
        state: {
            color: gnbColor,
            logo: gnbLogo
        },
        actions: {
            setGnbColor,
            setGnbLogo
        }
    };
    const transitionValue = {
        state: {
            complete: transitionComplt
        },
        actions: {
            setTransitionComplt
        }
    };
    const contactValue = {
        state: {
            popupState: popupOn,
            contactBtnRef: contactBtnRef
        },
        actions: {
            setPopupOn,
            setContactBtnRef
        }
    };

    const privacyValue = {
        state:{
            privacyState: privacyOn,
            zIndexState : upZindex
        },
        actions:{
            setPrivacyOn,
            setUpZindex
        }
    }

    return (
        <TransitionContext.Provider value={transitionValue}>
            <GnbContext.Provider value={gnbValue}>
                <ContactContext.Provider value={contactValue}>
                    <PrivacyContext.Provider value={privacyValue}>
                    <div className="Pc">
                        <Loading setLoading={setLoading} />
                        <Transition />
                        {!loading && (
                            <>
                                <Routes>
                                    <Route path="/" element={<Layout/>}>
                                        <Route path="/" element={<MainPage />}></Route>
                                        <Route path={'/about'} element={<AboutPage />}></Route>
                                        <Route path={'/solution'} element={<SolutionPage />}></Route>
                                        <Route path={'/contact'} element={<ContactPage />}></Route>
                                        <Route path={'/work'} element={<WorkPage />}></Route>
                                        <Route path={'/work/:project'} element={<WorkPage />}></Route>
                                    </Route>
                                    <Route path="*" element={<NotFound/>}></Route>
                                </Routes>
                                <Gnb logo={gnbLogo} setLogo={setGnbColor} white={gnbColor} />
                                <ScrollToTop />
                                <Privacy/>
                            </>
                        )}
                    </div>
                    </PrivacyContext.Provider>
                </ContactContext.Provider>
            </GnbContext.Provider>
        </TransitionContext.Provider>
    );
}
