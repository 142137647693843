import { useRef } from "react";
import { animate, motion, stagger } from "framer-motion";

export default function RandomTypo(props) {
    const viewRef = useRef();
    const randomTextArr = props.text.split('');
    const randomMin = 0.2;
    const randomMax = 0.5;

    return (
        <div className="RandomTypo" ref={viewRef}>
            {randomTextArr.map((el, idx) => {
                const random = Math.random() * (randomMax - randomMin + 1) + randomMin;

                return (
                    <motion.span
                        key={idx}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={{
                            hidden: { opacity: 0 },
                            visible: { opacity: 1 }
                        }}
                        transition={{
                            duration: 0.1,
                            delay: random,
                            ease: 'easeInOut'
                        }}
                        onAnimationComplete={() => {
                            if (idx == randomTextArr.length - 1) {
                                setTimeout(() => {
                                    props.onComplete && props.onComplete();
                                }, 400);
                            }
                        }}>
                        {el}
                    </motion.span>
                );
            })}
        </div>
    )
}