import { workDetailPath } from '../../pages/WorkDetail';
import Fadeup from '../Fadeup';

export default function Lexus() {
    const wdp = workDetailPath();
    const wp = wdp + 'lexus/';

    return (
        <div className="work-detail-wrap lexus">
            <div className="project-cover">
                <div className={`cover-img-wrap`}>
                    <img src={wp + 'img_intro.png'} />
                </div>

                <div className="cover-text-wrap">
                    <div className="container">
                        <h2 className="project-type">LEXUS Korea</h2>
                        <h4 className="project-name">LEXUS LOUNGE</h4>
                        <ul className="project-team">
                            <li className="team-tag">design</li>
                            <li className="team-tag">Project Management</li>
                            <li className="team-tag">development</li>
                        </ul>
                        <Fadeup className="project-desc">
                            <p>
                                렉서스 라운지는 고객 선호도 및 차량 관리 주기에 기반하여 사용자에게
                                차별화된 차량 관리 경험과 프라이빗한 멤버십 혜택을 제공하는 멤버십
                                서비스입니다.
                                <br />
                                <br />
                                렉서스 라운지의 '스마트 차량 관리'는 차량 정비내역, 전자 문서 등의
                                차량 관리 히스토리를 손쉽게 확인할 수 있으며, 전화로만 제공하던
                                서비스 센터 예약을 앱에서 직접 진행할 수 있도록 제공했고 보다 쉽게
                                서비스를 받을 수 있도록 하였습니다. 또한, 중장년층이 선호하는
                                브랜드인 만큼 법인차량, 가족 차량으로 사용하는 경우가 많습니다. 이런
                                특징을 파악하여 더욱 활발한 서비스를 위해 차량의 실소유자 외에도
                                차량을 사용하는 '공동 사용자'를 등록하여 멤버십 혜택을 함께 누리고
                                공동으로 차량 관리를 할 수 있도록 구성하였습니다.
                                <br />
                                <br />
                                이처럼 고객 맞춤형 편의 시스템을 통해 고객의 차량 생애 주기를
                                파악하고 정기 점검 등의 차량 관리를 도와줄 푸시 메시지를 전송하거나,
                                관심사를 파악하여 개인화 콘텐츠 등을 제공합니다. 장기적으로 타깃
                                마케팅을 통해 고객에게 렉서스 브랜드에 대한 신뢰도를 상승시킬 수
                                있을 것입니다.
                            </p>
                        </Fadeup>
                    </div>
                </div>
            </div>

            <div className="project-contents">
                <section className="section-01">
                    <Fadeup className="vid-01">
                        <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/gjth-YkVWBE"
                            title="[렉서스] Lexus Lounge App #Introduction"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                    </Fadeup>
                    <div className="img-wrap">
                        <Fadeup className="vid-02">
                            <video
                                src={wp + 'vid_01_01.mp4'}
                                playsInline
                                muted
                                autoPlay
                                loop></video>
                        </Fadeup>
                        <Fadeup className="img-01">
                            <img src={wp + 'img_01_01.png'} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-02">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_02_01.png'} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + 'img_02_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + 'img_02_03.png'} />
                    </Fadeup>
                </section>

                <section className="section-03">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_03_01.png'} />
                    </Fadeup>
                    <div className="img-wrap">
                        <Fadeup className="img-02">
                            <img src={wp + 'img_03_02.png'} />
                        </Fadeup>
                        <Fadeup className="img-03">
                            <img src={wp + 'img_03_03.png'} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-04">
                    <div className="marquee-container">
                        <div className="marquee-wrap">
                            <ul>
                                <li>
                                    <img src={wp + 'img_04_slide_01.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_04_slide_02.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_04_slide_03.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_04_slide_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_04_slide_05.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_04_slide_06.png'} />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={wp + 'img_04_slide_01.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_04_slide_02.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_04_slide_03.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_04_slide_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_04_slide_05.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_04_slide_06.png'} />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <Fadeup className="img-01">
                        <img src={wp + "img_04_01.png"} />
                    </Fadeup>
                </section>

                <section className="section-05">
                    <Fadeup className="img-01">
                        <img src={wp + "img_05_01.png"} />
                    </Fadeup>
                    <div className="img-container">
                        <Fadeup className="img-02">
                            <img src={wp + "img_05_02.png"} />
                        </Fadeup>
                        <div className="img-wrap">
                            <Fadeup className="img-03">
                                <img src={wp + "img_05_03.png"} />
                            </Fadeup>
                            <Fadeup className="img-04">
                                <img src={wp + "img_05_04.png"} />
                            </Fadeup>
                        </div>
                    </div>
                </section>

                <section className="section-06">
                    <Fadeup className="img-01">
                        <img src={wp + "img_06_01.png"} />
                    </Fadeup>
                    <Fadeup className="img-bg-01">
                        <img src={wp + "img_06_bg_01.png"} />
                    </Fadeup>
                    <Fadeup className="img-bg-02">
                        <img src={wp + "img_06_bg_02.png"} />
                    </Fadeup>
                    <Fadeup className="img-bg-03">
                        <img src={wp + "img_06_bg_03.png"} />
                    </Fadeup>
                </section>

                <section className="section-07">
                    <div className="img-wrap">
                        <Fadeup className="img-01">
                            <img src={wp + "img_07_01.png"} />
                        </Fadeup>
                        <Fadeup className="img-02">
                            <img src={wp + "img_07_02.png"} />
                        </Fadeup>
                    </div>
                    <Fadeup className="img-03">
                        <img src={wp + "img_07_03.png"} />
                    </Fadeup>
                </section>

                <section className="section-08">
                    <Fadeup className="img-01">
                        <img src={wp + "img_08_01.png"} />
                    </Fadeup>

                    <Fadeup className="img-02">
                        <img src={wp + "img_08_02.png"} />
                    </Fadeup>
                </section>
            </div>
        </div>
    );
}
