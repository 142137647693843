export default function BtnList(props) {
    return (
        <li className="BtnList list-btn-box">
            <input
                type="radio"
                name={props.name}
                className="list-btn-input"
                id={props.id}
                value={props.value}
            ></input>
            <label className="list-btn-label" htmlFor={props.id}>
                {props.text}
            </label>
        </li>
    );
}
