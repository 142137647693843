import { useContext } from 'react';
import LinkTag from './LinkTag';
import LinkWithTransition from './LinkWithTransition';
import {PrivacyContext} from './Mobile';

export default function Footer() {
    const privacyContext = useContext(PrivacyContext);
    return (
        <footer className="Footer">
            <div className="footer-text-box right">
                <div className="footer-msg">
                    Look around
                    <br />
                    our social media
                </div>

                <div className="tag-wrap">
                    <LinkTag
                        class="instagram"
                        name="INSTAGRAM"
                        page="https://www.instagram.com/appknot.official/"
                    />
                    <LinkTag
                        class="youtube"
                        name="YOUTUBE"
                        page="https://www.youtube.com/user/appknot1"
                    />
                </div>
            </div>

            <div className="footer-text-box row">
                <div className="address-box">
                    <address>
                        서울시 성동구 아차산로 126,
                        <br />
                        더리브세종타워 613호 appknot
                    </address>
                    <ul className="num-info">
                        <li>T.02-702-5527/</li>
                        <li>F.02-702-5528</li>
                    </ul>
                </div>

                <ul className="menu-list">
                    <li>
                        <LinkWithTransition to="/">
                            home
                        </LinkWithTransition>
                    </li>
                    <li>
                        <LinkWithTransition to="/solution">
                            solution
                        </LinkWithTransition>
                    </li>
                    <li>
                        <LinkWithTransition to="/about">
                            about
                        </LinkWithTransition>
                    </li>
                    <li>
                        <LinkWithTransition to="/work">
                            work
                        </LinkWithTransition>
                    </li>
                    <li>
                        <LinkWithTransition to="/contact">
                            contact
                        </LinkWithTransition>
                    </li>
                    <li className="rnd">
                        <a href="/site/rnd.html" target='_blank'>
                            R&D
                        </a>
                    </li>
                    <li>
                        <a href="https://knotters.kr/">
                            knotters
                        </a>
                    </li>
                </ul>
            </div>

            <div className="footer-text-box">
                <div className="tag-wrap">
                    <LinkTag class="handly" name="HANDLY" page="https://handly.team/product/" />
                    <LinkTag class="mentor" name="MENTOR" page="/site/mentor.html" />
                </div>

                <div className="footer-msg">
                    we have
                    <br />
                    solutions
                </div>
                
                <ul className="rights">
                    <li className='privacy-btn'
                    onClick={() => privacyContext.actions.setPrivacyOn(true)}>
                    Privacy Policy</li>
                    <li>© 2023 appknot all rights reserved.</li>
                </ul>
            </div>
        </footer>
    );
}
