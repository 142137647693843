import { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { useHorizontalScroll } from '../useSideScroll';
import TeamTag from '../TeamTag';
import { throttle } from 'lodash';
import { GnbContext } from '../Pc';
import Typography from '../Typography';
import { motion, useAnimationFrame, useMotionValue, useMotionValueEvent, useScroll, useSpring, useTransform, useVelocity, wrap } from 'framer-motion';

export default function MainSectionRecruit(props) {
    const bgChangeRef = useRef();
    const scrollRef = useHorizontalScroll();
    const slideWrapRef = useRef();
    const slideBoxRef = useRef();

    // state
    const [mousePosition, setMousePosition] = useState({ left: 0, top: 0 });
    const [active, setAcitve] = useState(false);
    const [tag, setTag] = useState(false);
    const [teamName, setTeamName] = useState('');
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [startPos, setStartPos] = useState(0);
    const [endPos, setEndPos] = useState(0);
    const [bgChange, setBgChange] = useState();
    const [translateZ, setTranslateZ] = useState(0);

    //드래그시 각도 변경
    const handleMouseMove = throttle((e) => {
        e.stopPropagation();
        setEndPos(e.clientX);
        setTag(false);
    }, 0);

    const handleMouseDown = (e) => {
        setIsMouseDown(true);
        setStartPos(e.clientX);
    };

    const gnbContext = useContext(GnbContext);


    const motionVal = useMotionValue(0)
    const slideScroll = useSpring(motionVal, {
        bounce: 0,
        damping: 25,
        velocity: 2
    });

    //스크롤시 각도 변경
    let allowWheel = true;
    // let progress = 0;
    const handleScroll = throttle((e) => {
        const recruitSection = document.querySelector('.MainSectionRecruit');

        // gnb > 로고, copyright 색상 변경
        if (recruitSection.getBoundingClientRect().top <= 0) {
            gnbContext.actions.setGnbColor('black');
        } else {
            gnbContext.actions.setGnbColor('white');
        }

        // contact popup에서 스크롤 시 슬라이드 반응x
        if (document.querySelector('.popup-main')) {
            if (document.querySelector('.popup-main').contains(e.target)) {
                allowWheel = false;
            }
        }

        if (allowWheel) {
            if (e.deltaY < 0) {
                motionVal.set(motionVal.get() - Math.abs(e.deltaY) * 0.1);
            } else if (e.deltaY > 0) {
                motionVal.set(motionVal.get() + Math.abs(e.deltaY) * 0.1);
            }
        }
    }, 0);

    useEffect(() => {
        function setSlide() {
            // slide gap
            const recruitSlide = document.querySelectorAll('.recruit-slide');
            const z = Math.round(scrollRef.current.offsetHeight / 2 / Math.tan(Math.PI / recruitSlide.length));
            setTranslateZ(z);
            recruitSlide.forEach((el, idx) => {
                el.style.transform = 'rotateY(' + (360/recruitSlide.length) * idx + 'deg) translateZ('+z+'px)';
            });
        }
        setSlide();

        window.addEventListener("resize",setSlide);

        // mousedown event
        slideBoxRef.current.addEventListener('mousedown', handleMouseDown);

        let observe = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    setBgChange(true);
                } else {
                    setBgChange(false);
                }
        },{ threshold: 0 });
        observe.observe(slideBoxRef.current);

        const sectionObserver = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                window.addEventListener('wheel', handleScroll);
            } else {
                window.removeEventListener('wheel', handleScroll);
            }
        },{ threshold: 0 });
        sectionObserver.observe(bgChangeRef.current);

        return () => {
            if (observe) observe.disconnect();
            if (sectionObserver) sectionObserver.disconnect();
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener('resize', setSlide);
        };
    }, []);

    // endPos 바뀔 때
    useEffect(() => {
        let timer;
        let mousePos = startPos - endPos;
        clearTimeout(timer);
        motionVal.set(motionVal.get() + mousePos * 0.05);

        timer = setTimeout(() => {
            const event = new MouseEvent("mouseup", {
                view: window,
                bubbles: true,
                cancelable: true,
            });
            window.dispatchEvent(event);
        }, 200)
    }, [endPos]);

    // mouseDown 바뀔 때
    useEffect(() => {
        const handleMouseUp = (e) => {
            setIsMouseDown(false);
            slideBoxRef.current.removeEventListener('mousemove', handleMouseMove);
        }
        if (isMouseDown) {
            // 드래그 위치 계산
            slideBoxRef.current.addEventListener('mousemove', handleMouseMove);

            // 드래그 끝
            window.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        }
    }, [isMouseDown]);

    return (
        <motion.section
            onMouseMove={(e) => {
                setAcitve(true);
                if (bgChange) {
                    const bounds = bgChangeRef.current.getBoundingClientRect();
                    const posX = e.clientX - bounds.left;
                    const posY = e.clientY - bounds.top;
                    setMousePosition({ left: posX, top: posY });
                }
            }}
            onMouseLeave={() => {
                setAcitve(false);
            }}
            className={`MainSectionRecruit ${bgChange == true && 'on'}`}
            ref={bgChangeRef}>
            <div
                className={`line horizontal ${
                    active == true && bgChange == true ? 'on' : ''
                }`}
                style={{
                    transform: 'translateY(' + mousePosition.top + 'px)'
                }}></div>
            <div
                className={`line vertical  ${
                    active == true && bgChange == true ? 'on' : ''
                }`}
                style={{
                    transform: 'translateX(' + mousePosition.left + 'px)'
                }}></div>
            <div className="recruit-top">
                <div className="recruit-msg">
                    <div className="row">
                        <Typography txt="Embrace Your Inner Geek"></Typography>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="emoji-wrap">
                            <motion.img
                                variants={{
                                    hidden: {
                                        opacity: 0,
                                        rotate: '16deg',
                                        y: '150%'
                                    },
                                    visible: (custom = 0) => ({
                                        opacity: 1,
                                        rotate: '0deg',
                                        y: '0%',
                                        transition: {
                                            duration: 0.8,
                                            delay: 0.05
                                        }
                                    })
                                }}
                                className="emoji-alien"
                                src={process.env.PUBLIC_URL + '/image/icn_alien.webp'}
                            />
                        </motion.div>
                    </div>
                    <Typography txt="  Where Passion Fuels Greatnes!"></Typography>
                    <Typography txt="we want new member."></Typography>
                    <Typography txt="Be my company."></Typography>
                    <span className="recruit-tag">
                        너 내 동료가 돼라
                        <img
                            className="emoji-point"
                            src={
                                process.env.PUBLIC_URL +
                                '/image/icn_pointing_viewer.webp'
                            }
                        />
                    </span>
                </div>

                <div className="recruit-job">
                    <span>WE WANT</span>
                    <span>
                        Strategy Planner
                        <br />
                        Product Manager
                        <br />
                        ui/ux designer
                        <br />
                        ios developer
                        <br />
                        android developer
                        <br />
                        web developer
                    </span>
                </div>
            </div>
            <div className="recruit-slide-box" ref={slideBoxRef}>
                <div className="recruit-slide-container" ref={scrollRef} style={{perspective: translateZ * 2 + "px"}}>
                    <motion.ul
                        className="recruit-slide-wrap"
                        ref={slideWrapRef}
                        style={{
                            z: translateZ + "px",
                            rotateY: slideScroll,
                        }}
                        >
                        {
                            [0, 1].map(() => {
                                return (
                                    ["Strategy Planner", "Product Manager", "ui/ux designer", "ios developer", "android developer", "web developer"].map((item, idx) => {
                                        return (
                                            <li className="recruit-slide" key={item}
                                            >
                                                <div className="slide-content" data-idx={idx}
                                                onMouseOver={(e) => {
                                                    setTag(true);
                                                    let team = e.currentTarget.dataset.idx;
                                                    setTeamName(team);
                                                }}
                                                onMouseLeave={() => {
                                                    setTag(false);
                                                }}>
                                                    <div className="slide-img">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                '/image/img_recruit_slide_0'+(idx+1)+'.webp'
                                                            }
                                                        />
                                                    </div>
                                                    <span className="slide-text">
                                                        {item}
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    })
                                )
                            })
                        }
                    </motion.ul>
                </div>

                <TeamTag tag={tag} mousePosition={mousePosition} teamIdx={teamName} />
            </div>
        </motion.section>
    );
}
