import SolutionSectionDestination from '../components/Solution/SolutionSectionDestination';
import SolutionSectionFinish from '../components/Solution/SolutionSectionFinish';
import SolutionSectionIntro from '../components/Solution/SolutionSectionIntro';
import SolutionSectioEntry from '../components/Solution/SolutionSectionEntry';
import SolutionSectionPopee from '../components/Solution/SolutionSectionPopee';
import SolutionSectionMentor from '../components/Solution/SolutionSectionMentor';
import SolutionSectionHandly from '../components/Solution/SolutionSectionHandly';

export default function SolutionPage() {
    return (
        <div className="SolutionPage">
            <SolutionSectioEntry />
            <SolutionSectionIntro />
            <SolutionSectionHandly/>
            <SolutionSectionPopee />
            <SolutionSectionMentor />
            <SolutionSectionDestination />
            <SolutionSectionFinish />
        </div>
    );
}
