import { workDetailPath } from '../../pages/WorkDetail';
import Fadeup from '../Fadeup';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';

export default function Picks() {
    const wdp = workDetailPath();
    const wp = wdp + 'picks/';

    return (
        <div className="work-detail-wrap picks">
            <div className="project-cover dark">
                <div className={`cover-img-wrap`}>
                    <img src={wp + 'img_intro.png'} />
                </div>

                <div className="cover-text-wrap">
                    <div className="container">
                        <h2 className="project-type">Weathernews</h2>
                        <h4 className="project-name">Picks</h4>
                        <ul className="project-team">
                            <li className="team-tag">design</li>
                            <li className="team-tag">Project Management</li>
                            <li className="team-tag">development</li>
                        </ul>
                        <Fadeup className="project-desc">
                            <p>
                                픽스는 라이프 콘텐츠를 큐레이션 하여 제공해 주는 서비스입니다.
                                <br />
                                <br />
                                삼성 갤럭시 스마트폰에 탑재된 날씨 위젯 내에서 모바일 웹을 통해 생활
                                정보 콘텐츠를 공급하고 있으며 글로벌 버전으로 리뉴얼 되었습니다.
                                <br />
                                <br />
                                리뉴얼 과정에서 콘텐츠 제공 방식과 서비스 안정성 향상에 큰 변화가
                                있었습니다. 앱노트의 CMS(Content Management System) 솔루션 경험과
                                노하우를 기반으로 지난 2년간 누적되었던 콘텐츠들을 오늘의 픽, 해시
                                태그, 추천 콘텐츠 등 다양한 방식으로 노출 방법에 변화를 주었습니다.
                                <br />
                                <br />
                                또한 2019년부터 운영된 초기 버전의 인프라를 상회함에 따라 배치
                                서버를 도입하였습니다. 접속량이 증가함에 따라 안정성과 속도 개선을
                                위해 심야 시간이나 접속률이 낮은 시간에 콘텐츠를 수집/분류하는
                                작업을 진행하였고 서비스의 안정성과 속도가 개선되었습니다.
                                <br />
                                <br />
                                기존 국내 서비스로 기획되었던 픽스는 국내 법령에 맞춰 구현이
                                되었으나, 글로벌 서비스로 리뉴얼 됨에 따라 EU의 GDPR 및 미국 내
                                프라이버시 법령에 따른 서비스의 변화가 예상됩니다. 글로벌 서비스
                                특성에 맞는 유연한 대처로 국/내외 동일한 수준의 서비스를 제공하고자
                                합니다.
                            </p>
                        </Fadeup>
                    </div>
                </div>
            </div>

            <div className="project-contents">
                <section className="section-01">
                    <Fadeup className="img-01" endClass={true}>
                        <div className="img-wrap">
                            <img src={wp + 'img_01_01.png'} />
                        </div>
                    </Fadeup>

                    <Fadeup className="vid-01">
                        <div className="vid-wrap">
                            <video
                                src={wp + 'vid_01_01.mp4'}
                                playsInline
                                muted
                                autoPlay
                                loop></video>
                        </div>

                        <div className="vid-title">도심 속 여행 떠나기</div>
                    </Fadeup>

                    <Fadeup className="img-02">
                        <img src={wp + 'img_01_02.png'} />
                    </Fadeup>

                    <div className="img-wrap">
                        <Fadeup className="img-03">
                            <img src={wp + 'img_01_03.png'} />
                        </Fadeup>
                        <Fadeup className="img-04">
                            <img src={wp + 'img_01_04.png'} />
                        </Fadeup>
                        <Fadeup className="img-05">
                            <img src={wp + 'img_01_05.png'} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-02">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_02_01.png'} />
                    </Fadeup>
                    <div className="img-container">
                        <div className="img-wrap">
                            <Fadeup>
                                <img src={wp + 'img_02_02.png'} />
                            </Fadeup>
                            <Fadeup>
                                <img src={wp + 'img_02_03.png'} />
                            </Fadeup>
                            <Fadeup>
                                <img src={wp + 'img_02_04.png'} />
                            </Fadeup>
                        </div>
                        <Fadeup>
                            <img src={wp + 'img_02_05.png'} />
                        </Fadeup>
                    </div>

                    <div className="img-wrap-01">
                        <Fadeup className="img-06">
                            <img src={wp + 'img_02_06.png'} />
                        </Fadeup>
                        <Fadeup className="vid-01">
                            <video
                                src={wp + 'vid_02_01.mp4'}
                                playsInline
                                muted
                                autoPlay
                                loop></video>
                        </Fadeup>
                        <Fadeup className="img-07">
                            <img src={wp + 'img_02_07.png'} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-03">
                    <div className="title">#오피스</div>

                    <Swiper
                        loop={true}
                        autoplay={{ delay: 2000, disableOnInteraction: false }}
                        modules={[Autoplay]}
                        slidesPerView={1.9}
                        centeredSlides={true}
                        spaceBetween={14}>
                        <SwiperSlide>
                            <img src={wp + 'img_03_slide_01.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_03_slide_02.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_03_slide_03.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_03_slide_04.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_03_slide_05.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_03_slide_06.png'} />
                        </SwiperSlide>
                    </Swiper>
                </section>

                <section className="section-04">
                    <Fadeup className="img-01">
                        <img src={wp + "img_04_01.png"} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + "img_04_02.png"} />
                    </Fadeup>
                </section>
            </div>
        </div>
    );
}
