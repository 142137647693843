import SolutionInfoIntro from "./SolutionInfoIntro";
import SolutionIntroduceIntro from './SolutionIntroduceIntro';

export default function SolutionSectionHandly() {
    const textArr = [
        "Effective".split(""), 
        "Schedule".split(""), 
        "Management".split("")
    ];
    return (
        <div className="SolutionSectionHandly">
            <div className="container">
                <div className="sticky">
                    <div className="bg">
                        <img src={process.env.PUBLIC_URL + '/image/img_bg_solution_handly.webp'} />
                    </div>
                </div>
                <SolutionIntroduceIntro
                    num="01"
                    msg="스케줄 관리의 새로운 방법, 핸들리"
                    name="Handly">
                    <div className="introduce">
                        <div className="desktop-img">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/image/img_solution_handly_desktop.webp'
                                }
                                alt=""
                            />
                        </div>

                        <div className="text">
                            <strong>
                                우리는 오늘도 복잡한 일정 속에서
                                <br />
                                회의실 예약을 놓치거나
                                <br />
                                끝나지 않는 시간을 무작정 기다립니다.
                            </strong>

                            <p>
                                붐비는 시간대를 피해 약속을 잡고, 세련된 방법으로 일정을 공유하고,
                                <br />
                                답답했던 회의실 사용법을 바꿔 계획적인 하루를 보내길 꿈꿉니다.
                                <br />
                                하지만 별도의 예약 시스템이 없어 회의실 선정 문제로 트러블이 자주
                                <br />
                                발생하며, 필요하지만 항상 부족한 회의실을 찾아다니며 일합니다.
                                <br />
                                회의는 길어도 예약은 짧아야 하니까.
                            </p>
                        </div>
                    </div>
                </SolutionIntroduceIntro>
            </div>
            <div className="contents">
                <SolutionInfoIntro
                    text={textArr}
                    img={process.env.PUBLIC_URL + '/image/img_solution_handly.webp'}
                />
                <div className="solution-introduction">
                    <div className="simple-text">
                        Handly는 필요할 때, 필요한 만큼.
                        <br />
                        원하는 기간 동안, 원하는 회의실 수만큼
                        <br />
                        이용이 가능합니다.
                    </div>

                    <div className="detail-text">
                        <p>
                            <span>Handly</span>는 필요할 때, 필요한 만큼.
                            원하는 기간 동안, 원하는 회의실 수만큼 이용이 가능합니다.
                            <br />
                            PC, 모바일 앱, 태블릿까지 다양한 디바이스로 언제 어디서나 빠르고
                            편하게 예약 및 일정 관리가 가능합니다.
                            <br />
                            또한 일정에 맞는 회의실과 시간을 찾아 주고 실시간으로 회의실 현황
                            을 확인할 수도 있습니다.
                            <br />
                            별도의 설치 없이 규모에 상관없이 사용한 만큼만 합리적인 방식으로
                            지금까지 없던 새로운 서비스를 경험해 보세요.
                        </p>

                        <a href="" className="btn-link">
                            Handly 보러가기
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}