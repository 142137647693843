import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { motion } from 'framer-motion';

export default function Ekolon() {
    const variants = {
        visible: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 1.2,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, y: '50%' }
    };
    return (
        <div className="Ekolong">
            <div className="work-detail-wrap work-detail-container" data-hash="ekolon">
                <div className="project-cover">
                    <div className="cover-img-wrap"></div>
                    <div className="cover-text-wrap">
                        <div className="container">
                            <h2 className="project-type">Kolon Industries</h2>
                            <h4 className="project-name">e-KOLON Membership</h4>
                            <ul className="project-team">
                                <li className="team-tag">design</li>
                                <li className="team-tag">Project Management</li>
                                <li className="team-tag">development</li>
                            </ul>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="project-desc">
                                <motion.p variants={variants}>
                                    eKOLON Membership은 코오롱의 20여 개 브랜드별 멤버십과
                                    온/오프라인 채널을통합시킨 멤버십 서비스입니다. 과거 운영 중인
                                    포인트, 쿠폰, 혜택 등은 매장을 방문해야만조회가 가능하였습니다.
                                    따라서 온라인 채널 대비 오프라인 채널의 회원가입을 유치하고
                                    고객접점 채널로써 모바일 앱 서비스를 도입하게 되었습니다.
                                    <br />
                                    <br />
                                    사용에 불편함이 없도록 멤버십 바코드를 단일로 제공하고 오프라인
                                    쿠폰뿐만 아니라 온라인몰에서 사용할 수 있는 쿠폰도 통합으로
                                    안내하여, 고객이 해당 서비스 내에서 멤버십과관련된 사항들을
                                    편리하게 확인할 수 있도록 구성하였습니다.
                                    <br />
                                    <br />
                                    또한 복잡했던 포스기에서의 가입 절차 대신 앱을 통한 가입을
                                    유도하고 있으며, 마케팅채널로 함께 활용할 수 있게 되었습니다.
                                    고객은 매장을 방문해야만 알 수 있던 정보를 앱을통해 온/오프라인
                                    구분 없이 혜택을 받고 이벤트에 참여할 수 있게 되었습니다.
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>
                <div className="work-detail-contents">
                    <section className="work-detail-information">
                        <div className="work-detail-information-container work-detail-information-container-01">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-00 fade-up"
                                data-delay="11">
                                <motion.img
                                    variants={{
                                        visible: {
                                            opacity: 1,
                                            y: '0%',
                                            transition: {
                                                opacity: {
                                                    duration: 1.8
                                                },
                                                y: {
                                                    duration: 1.2
                                                },
                                                duration: 1.2,
                                                delay: 1.5,
                                                ease: 'easeInOut'
                                            }
                                        },
                                        hidden: { opacity: 0, y: '50%' }
                                    }}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section01_00.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section01_01.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section01_02.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section01_03.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-04 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section01_04.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-05 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section01_05.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-06 fade-up"
                                data-delay="9">
                                <motion.img
                                    variants={{
                                        visible: {
                                            opacity: 1,
                                            y: '0%',
                                            transition: {
                                                opacity: {
                                                    duration: 1.8
                                                },
                                                y: {
                                                    duration: 1.2
                                                },
                                                duration: 1.2,
                                                delay: 0.9,
                                                ease: 'easeInOut'
                                            }
                                        },
                                        hidden: { opacity: 0, y: '50%' }
                                    }}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section01_06.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-07 fade-up"
                                data-delay="8">
                                <motion.img
                                    variants={{
                                        visible: {
                                            opacity: 1,
                                            y: '0%',
                                            transition: {
                                                opacity: {
                                                    duration: 1.8
                                                },
                                                y: {
                                                    duration: 1.2
                                                },
                                                duration: 1.2,
                                                delay: 0.8,
                                                ease: 'easeInOut'
                                            }
                                        },
                                        hidden: { opacity: 0, y: '50%' }
                                    }}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section01_07.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-08 fade-up"
                                data-delay="7">
                                <motion.img
                                    variants={{
                                        visible: {
                                            opacity: 1,
                                            y: '0%',
                                            transition: {
                                                opacity: {
                                                    duration: 1.8
                                                },
                                                y: {
                                                    duration: 1.2
                                                },
                                                duration: 1.2,
                                                delay: 0.7,
                                                ease: 'easeInOut'
                                            }
                                        },
                                        hidden: { opacity: 0, y: '50%' }
                                    }}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section01_08.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-09 fade-up"
                                data-delay="3">
                                <motion.img
                                    variants={{
                                        visible: {
                                            opacity: 1,
                                            y: '0%',
                                            transition: {
                                                opacity: {
                                                    duration: 1.8
                                                },
                                                y: {
                                                    duration: 1.2
                                                },
                                                duration: 1.2,
                                                delay: 0.3,
                                                ease: 'easeInOut'
                                            }
                                        },
                                        hidden: { opacity: 0, y: '50%' }
                                    }}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section01_09.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-10 fade-up"
                                data-delay="5">
                                <motion.img
                                    variants={{
                                        visible: {
                                            opacity: 1,
                                            y: '0%',
                                            transition: {
                                                opacity: {
                                                    duration: 1.8
                                                },
                                                y: {
                                                    duration: 1.2
                                                },
                                                duration: 1.2,
                                                delay: 1,
                                                ease: 'easeInOut'
                                            }
                                        },
                                        hidden: { opacity: 0, y: '50%' }
                                    }}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section01_10.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-02">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section02_01.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section02_02.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_section02_03.webp'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-04 reactive-rectangle fade-up">
                                <motion.ul variants={variants}>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_ekolon_section02_04.webp'
                                            }
                                            alt="이코오롱 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_ekolon_section02_05.webp'
                                            }
                                            alt="이코오롱 이미지"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_ekolon_section02_06.webp'
                                            }
                                            alt="이코오롱 이미지"
                                        />
                                    </li>
                                </motion.ul>
                            </motion.div>

                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-05 fade-up">
                                <motion.div variants={variants} className="swiper-container">
                                    <Swiper
                                        loop={true}
                                        autoplay={{ delay: 1, disableOnInteraction: false }}
                                        speed={3000}
                                        modules={[Autoplay]}
                                        slidesPerView={'auto'}
                                        freeMode={true}
                                        centeredSlides={false}>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_club01.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_club02.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_club03.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_club04.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_club05.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </SwiperSlide>
                                    </Swiper>
                                </motion.div>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-06 fade-up">
                                <motion.div
                                    variants={variants}
                                    className="swiper-container"
                                    dir="rtl">
                                    <Swiper
                                        loop={true}
                                        autoplay={{ delay: 1, disableOnInteraction: false }}
                                        speed={3000}
                                        modules={[Autoplay]}
                                        slidesPerView={'auto'}
                                        freeMode={true}
                                        centeredSlides={false}>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_club06.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_club07.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_club08.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_club09.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_club10.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </SwiperSlide>
                                    </Swiper>
                                </motion.div>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-03">
                            <div className="work-detail-information-img-container">
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="work-detail-information-img work-detail-information-img-01 fade-up"
                                    data-delay="5">
                                    <motion.img
                                        variants={variants}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ekolon_section03_01.webp'
                                        }
                                        alt="이코오롱 이미지"
                                    />
                                </motion.div>
                                <div className="work-detail-information-img work-detail-information-img-02">
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_ekolon_section03_02.webp'
                                            }
                                            alt="이코오롱 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="8">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_ekolon_section03_03.webp'
                                            }
                                            alt="이코오롱 이미지"
                                        />
                                    </motion.li>
                                </div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="work-detail-information-img work-detail-information-img-03 fade-up"
                                    data-delay="7">
                                    <motion.img
                                        variants={variants}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_ekolon_section03_04.webp'
                                        }
                                        alt="이코오롱 이미지"
                                    />
                                </motion.div>
                                <div className="work-detail-information-img work-detail-information-img-04">
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="3">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_ekolon_section03_05.webp'
                                            }
                                            alt="이코오롱 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="7">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_ekolon_section03_06.webp'
                                            }
                                            alt="이코오롱 이미지"
                                        />
                                    </motion.li>
                                </div>
                            </div>
                            <div className="work-detail-information-img-container reactive-rectangle for-mobile">
                                <div className="work-detail-information-img work-detail-information-img-01">
                                    <ul>
                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up">
                                            <motion.img
                                                variants={variants}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_section03_02.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </motion.li>
                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up">
                                            <motion.img
                                                variants={variants}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_section03_01.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </motion.li>
                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up">
                                            <motion.img
                                                variants={variants}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_section03_03.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </motion.li>
                                    </ul>
                                </div>
                                <div className="work-detail-information-img work-detail-information-img-02">
                                    <ul>
                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_section03_05.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </motion.li>
                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up">
                                            <motion.img
                                                variants={variants}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_section03_04.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </motion.li>
                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up">
                                            <motion.img
                                                variants={variants}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_ekolon_section03_06.webp'
                                                }
                                                alt="이코오롱 이미지"
                                            />
                                        </motion.li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-04 fade-up">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up"
                                data-delay="3">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_ekolon_logo.svg'
                                    }
                                    alt="이코오롱 이미지"
                                />
                            </motion.div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
